import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    Paper,
    CircularProgress,
    Grid,
    Button,
    Skeleton,
    styled
} from '@mui/material';
import {
    green
} from '@mui/material/colors';
import {
    Home as HomeIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import AccountEdit from '../../components/Forms/AccountEdit';
import { useGlobalDispatch } from '../../shared/global-context';
import { getDateDisplay } from '../../utils/date-transform';
import SkeletonAccountEdit from '../../components/Forms/SkeletonAccountEdit';
import AccountDeleteDialog from '../../components/Dialogs/AccountDeleteDialog';
import Auth from '../../services/auth/Auth';
import {
    ACCOUNT_BY_ID_QUERY, AccountByIdData, AccountByIdVars,
    UPDATE_ACCOUNT_MUTATION, UpdateAccountData, UpdateAccountVars,
    UNSUBSCRIBE_MUTATION, UnsubscribeData, UnsubscribeVars,
    DELETE_ACCOUNT_MUTATION, DeleteAccountData, DeleteAccountVars
} from './requests/Account';
import BreadcrumbsApp from '../../components/Breadcrumbs/BreadcrumbsApp';

interface AccountProps {
    readonly auth: Auth;
}

const Account: any = (props: AccountProps) => {
    const {
        auth
    } = props;
    const globalDispatch = useGlobalDispatch();
    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const links = [
        {
            label: <HomeIconCustom/>,
            onClick: () => navigate('/')
        }
    ];

    const [
        updateAccount,
        {loading: mutationLoading}
    ] = useMutation<UpdateAccountData, UpdateAccountVars>(
        UPDATE_ACCOUNT_MUTATION,
        {
            update(cache, { data }) {
                if (data?.updateAccount) {
                    cache.writeQuery<AccountByIdData, AccountByIdVars>({
                        query: ACCOUNT_BY_ID_QUERY,
                        data: { accountById: data.updateAccount }
                    });
                }
            },
            onCompleted(data) {
                snackbar.enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    const [
        unsubscribe,
        {loading: mutationUnsubscribeLoading}
    ] = useMutation<UnsubscribeData, UnsubscribeVars>(
        UNSUBSCRIBE_MUTATION,
        {
            update(cache, { data }) {
                if (data?.unsubscribe) {
                    cache.writeQuery<AccountByIdData, AccountByIdVars>({
                        query: ACCOUNT_BY_ID_QUERY,
                        data: {
                            accountById: data.unsubscribe
                        }
                    });
                }
            },
            onCompleted(data) {
                snackbar.enqueueSnackbar('Desabonnement réussie', {
                    variant: 'success'
                });
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    const [
        deleteAccount,
        {loading: deleteAccountLoading}
    ] = useMutation<DeleteAccountData, DeleteAccountVars>(
        DELETE_ACCOUNT_MUTATION,
        {
            update(cache, { data }) {

            },
            onCompleted(data) {
                auth.logout()
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    const {loading: queryLoading, error: queryError, data: queryData} = useQuery<AccountByIdData, AccountByIdVars>(ACCOUNT_BY_ID_QUERY);

    const [queryMessage, setQueryMessage] = useState<any | undefined>(undefined);
    const [untilMessage, setUntilMessage] = useState('Aucun abonnement');
    const [upcomingPeriodMessage, setUpcomingPeriodMessage] = useState('Abonnement non reconduit');
    const [upcomingTotalMessage, setUpcomingTotalMessage] = useState('Pas de prochaine facture');
    const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);

    useEffect(() => {
        if (queryLoading) {
            setQueryMessage(undefined);
        } else if (queryError) {
            console.log(`Error!: ${queryError}`);
            setQueryMessage(undefined);
        } else if (!queryData?.accountById) {
            setQueryMessage('Le compte n\'a pas pu être récupéré');
        } else {
            setQueryMessage(undefined);
        }
    }, [queryData, queryLoading, queryError]);

    useEffect(() => {
        if (queryData && queryData.accountById && queryData.accountById.subscriptionData && queryData.accountById.subscriptionData.isActive) {
            setUntilMessage(`${queryData.accountById.subscriptionData.label} du ${
                getDateDisplay(queryData.accountById.subscriptionData.currentPeriodStart)
            } au ${
                getDateDisplay(queryData.accountById.subscriptionData.currentPeriodEnd)
            }`);
            if (queryData.accountById.subscriptionData.upcomingPeriodStart && queryData.accountById.subscriptionData.upcomingPeriodEnd) {
                setUpcomingPeriodMessage(`Prochain abonnement du ${
                    getDateDisplay(queryData.accountById.subscriptionData.upcomingPeriodStart)
                } au ${
                    getDateDisplay(queryData.accountById.subscriptionData.upcomingPeriodEnd)
                }`);
            }
            else {
                setUpcomingPeriodMessage('Abonnement non reconduit');
            }
            if ((queryData.accountById.subscriptionData.upcomingTotal)) {
                setUpcomingTotalMessage(`Prochaine facture: ${
                    ((queryData.accountById.subscriptionData.upcomingTotal as number) / 100).toFixed(2)
                } €`);
            } else {
                setUpcomingTotalMessage('Pas de prochaine facture');
            }
        } else {
            setUntilMessage('Aucun abonnement');
            setUpcomingPeriodMessage('Abonnement non reconduit');
            setUpcomingTotalMessage('Pas de prochaine facture');
        }
    }, [queryData]);

    return (
        <AccountArea>
            <BreadcrumbsApp
                links={links}
                lastElem="Compte"/>
            {queryMessage && (<div>
                {queryMessage}
            </div>)}
            <GridCustom container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <PaperCustom elevation={1}>
                        {!queryMessage && !queryLoading && queryData && (<AccountEdit
                            account={queryData.accountById}
                            updateAccount={updateAccount}
                            updateLoading={mutationLoading}/>)}
                        {queryLoading && <SkeletonAccountEdit/>}
                    </PaperCustom>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <PaperCustom elevation={1}>
                        {!queryMessage && !queryLoading && (<SubscribeArea container spacing={3}>
                            <Grid item xs={12}>
                                {untilMessage}
                            </Grid>
                            <Grid item xs={12}>
                                <div>{upcomingPeriodMessage}</div>
                                <div>{upcomingTotalMessage}</div>
                            </Grid>
                        </SubscribeArea>)}
                        {queryLoading && (<SubscribeArea container spacing={3}>
                            <Grid item xs={12}>
                                <Skeleton height={40} width="100%" />
                            </Grid>
                            <Grid item xs={12}>
                                <div><Skeleton height={40} width="100%" /></div>
                                <div><Skeleton height={40} width="100%" /></div>
                            </Grid>
                        </SubscribeArea>)}
                        {!queryMessage && !queryLoading && (<Grid direction='row' justifyContent='flex-end' container spacing={3}>
                            {false && queryData?.accountById?.subscriptionData?.isActive && <Grid item xs={6}>
                                <Wrapper>
                                    <ButtonCustom
                                        variant='outlined'
                                        fullWidth
                                        onClick={() => {
                                            unsubscribe();
                                        }}>
                                        Se désabonner
                                    </ButtonCustom>
                                    {mutationUnsubscribeLoading && <CircularProgressCustom size={24}/>}
                                </Wrapper>
                            </Grid>}
                            {false && !queryData?.accountById?.subscriptionData?.isActive && <Grid item xs={6}>
                                <Wrapper>
                                    <ButtonCustom
                                        variant='outlined'
                                        color='primary'
                                        fullWidth
                                        onClick={() => {
                                            globalDispatch({
                                                type: 'updateModalSubscribe',
                                                isOpen: true
                                            });
                                        }}>
                                        S'abonner
                                    </ButtonCustom>
                                </Wrapper>
                            </Grid>}
                        </Grid>)}
                        {queryLoading && (<Grid direction='row' justifyContent='flex-end' container spacing={3}>
                            <Grid item xs={6}>
                                <Wrapper>
                                    <Skeleton variant="rectangular" width="100%" height={60} />
                                </Wrapper>
                            </Grid>
                        </Grid>)}
                    </PaperCustom>
                    <PaperCustom withMargin elevation={1}>
                        <Grid direction='row' justifyContent='flex-end' container spacing={3}>
                            <Grid item xs={6}>
                                <ButtonUnique
                                    variant='outlined'
                                    color='primary'
                                    fullWidth
                                    onClick={() => {
                                        setDeleteAccountOpen(true);
                                    }}>
                                    Supprimer mon compte
                                </ButtonUnique>
                            </Grid>
                        </Grid>
                    </PaperCustom>
                </Grid>
            </GridCustom>
            <AccountDeleteDialog
                isOpen={deleteAccountOpen}
                deleteLoading={deleteAccountLoading}
                deleteAccount={deleteAccount}
                onCloseHandle={() => {
                    setDeleteAccountOpen(false);
                }}/>
        </AccountArea>
    );
};

const HomeIconCustom = styled(HomeIcon)(
    ({theme}) => ({
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }),
);

const AccountArea = styled('div')(
    ({theme}) => ({
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        maxHeight: 'calc(100vh - 88px)',
        overflowY: 'auto',
        overflowX: 'hidden',
    }),
);

const GridCustom = styled(Grid)(
    ({theme}) => ({
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'column-reverse',
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column-reverse',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column-reverse',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
        [theme.breakpoints.up('xl')]: {
            flexDirection: 'row',
        },
    }),
);

const PaperCustom = styled(Paper)<{withMargin?: boolean}>(
    ({theme, withMargin}) => ({
        padding: theme.spacing(1),
        marginTop: withMargin ? theme.spacing(2) : undefined,
    }),
);

const SubscribeArea = styled(Grid)(
    ({theme}) => ({
        marginTop: theme.spacing(1),
        textAlign: 'right',
    }),
);

const Wrapper = styled('div')(
    () => ({
        position: 'relative',
    }),
);

const ButtonUnique = styled(Button)(
    ({theme}) => ({
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minHeight: theme.spacing(7),
    }),
);

const ButtonCustom = styled(Button)(
    ({theme}) => ({
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minHeight: theme.spacing(7),
    }),
);

const CircularProgressCustom = styled(CircularProgress)(
    () => ({
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }),
);

export default Account;