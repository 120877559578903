import * as dotenv from 'dotenv';

dotenv.config();

export const Config = {
    apiUri: process.env["REACT_APP_API_URI"] || 'http://localhost:8080',
    termsUri: process.env["REACT_APP_TERMS_URI"] || 'http://localhost:8000/tcu',
    stripePublicKey: process.env["REACT_APP_STRIPE_PUBLIC_KEY"] || 'pk_test_2K6wYnowD0RSGmH9frMwjtgX00POkq0mj7',
    auth0Domain: process.env["REACT_APP_AUTH0_DOMAIN"] || 'collectoogo-dev.eu.auth0.com',
    auth0ClientId: process.env["REACT_APP_AUTH0_CLIENT_ID"] || 'ntMo3dcWEtUSk3kHDA1bOIyRwrkSAWp2',
    auth0CallbackUrl: process.env["REACT_APP_AUTH0_CALLBACK_URL"] || 'http://localhost:3000/callback',
    auth0RedirectLogout: process.env["REACT_APP_AUTH0_REDIRECT_LOGOUT"] || 'http://localhost:8000/',
    auth0RoleUrl: process.env["REACT_APP_AUTH0_ROLE_URL"] || 'http://localhost:3000/app_role',
    auth0Audience: process.env["REACT_APP_AUTH0_AUDIENCE"] || 'https://collectoogo-dev.eu.auth0.com/api/v2/',
};