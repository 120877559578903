import { gql } from '@apollo/client';

export interface AccountByIdVars {
    id?: string;
}

export interface Address {
    street: string;
    zipCode: string;
    city: string;
    country: string;
}

export interface AccountByIdData {
    accountById: {
        id: string;
        firstName?: string;
        lastName?: string;
        dateBirth?: string;
        address?: Address;
    }
}

export const ACCOUNT_BY_ID_QUERY = gql`
    query AccountById($id: ID) {
        accountById(id: $id) {
            firstName
            lastName
            dateBirth
            address {
                street
                zipCode
                city
                country
            }
        }
    }
`;

export interface CreateAccountVars {
    auth0Id: string;
}

export interface CreateAccountData {
    createAccount: {
        id: string;
        firstName?: string;
        lastName?: string;
        dateBirth?: string;
        address?: Address;
    }
}

export const CREATE_ACCOUNT_MUTATION = gql`
    mutation CreateAccountMutation($auth0Id: String!) {
        createAccount(auth0Id: $auth0Id) {
            firstName
            lastName
            dateBirth
            address {
                street
                zipCode
                city
                country
            }
        }
    }
`;