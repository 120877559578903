import React from 'react';
import {
    Grid,
    Skeleton,
    styled
} from  '@mui/material';

interface SkeletonAccountEditProps {

}

const SkeletonAccountEdit: any = (props: SkeletonAccountEditProps) => {
    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Skeleton height={80} width="100%" />
                </Grid>
                <Grid item xs={6}>
                    <Skeleton height={80} width="100%" />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Skeleton height={80} width="100%" />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Skeleton height={120} width="100%" />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Skeleton height={80} width="100%" />
                </Grid>
                <Grid item xs={6}>
                    <Skeleton height={80} width="100%" />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" width="100%" height={70} />
                </Grid>
            </Grid>
        </Container>
    );
}

const Container = styled('div')(
    () => ({
        display: 'flex',
        flexWrap: 'wrap',
    }),
);

export default SkeletonAccountEdit;