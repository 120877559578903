import React, { useState } from 'react';
import {
    Route,
    BrowserRouter,
    Routes,
    Location,
    Outlet
} from 'react-router-dom';
import { graphql, MutationFunction } from '@apollo/react-hoc';
import { Helmet } from 'react-helmet';
import {
    styled,
    Toolbar
} from '@mui/material';

import App from './App';
import Home from './containers/home/Home';
import Callback from './containers/callback/Callback';
import Auth from './services/auth/Auth';
import Account from './containers/account/Account';
import Collections from './containers/collection/Collections';
import Items from './containers/collection/Items';
import Add from './containers/collection/Add';
import Edit from './containers/collection/Edit';
import Login from './containers/auth/Login';
import Exchanges from './containers/exchange/Exchanges';
import {
    ACCOUNT_BY_ID_QUERY, AccountByIdData, AccountByIdVars,
    CREATE_ACCOUNT_MUTATION, CreateAccountData, CreateAccountVars
} from './requests/routes';
import Aux from './hoc/Auxi';

const auth: Auth = new Auth();

interface RoutesProps {
    location: {
        hash: string
    };
    // @ts-ignore
    mutate: MutationFunction
}

const saveAccount = (mutate: MutationFunction<CreateAccountData, CreateAccountVars>, auth0Id: string) => {
    mutate({
        variables: {
            auth0Id
        },
        update(cache, { data }) {
            if (data?.createAccount) {
                cache.writeQuery<AccountByIdData, AccountByIdVars>({
                    query: ACCOUNT_BY_ID_QUERY,
                    data: { accountById: data.createAccount }
                });
            }
        },
    });
};

const handleAuthentication = (location: Location, mutate: MutationFunction, setIsAuth: (isAuth: boolean) => void) => {
    if (/access_token|id_token|error/.test(location.hash)) {
        auth.handleAuthentication(mutate, saveAccount, setIsAuth);
    }
};

const MakeMainRoutes = (props: RoutesProps) => {
    const {
        mutate,
    } = props;

    const [isAuth, setIsAuth] = useState(false);

    return (
        <BrowserRouter>
            <Helmet>
                <title>Collectoogo</title>
            </Helmet>
            <RouteArea isAuth={isAuth}>
                <Routes>
                    <Route path="/" element={<RouteContent authentication={auth} isAuth={isAuth} setIsAuth={setIsAuth}/>}>
                        <Route index element={<Home/>}/>
                        <Route path="/account" element={<Account auth={auth}/>}/>
                        <Route path="/collections" element={<Collections/>}/>
                        <Route path="/collections/:id" element={<Items/>}/>
                        <Route path="/collections/:id/add" element={<Add/>}/>
                        <Route path="/collections/:collectionId/:itemId" element={<Edit/>}/>
                        <Route path="/exchanges" element={<Exchanges/>}/>
                        <Route path="/signin" element={<Login auth={auth}/>}/>
                        <Route path="/signup" element={<Login auth={auth} isSignup/>}/>
                        <Route path="/callback" element={<Callback isAuth={isAuth} authentication={(location: Location) => {
                            handleAuthentication(location, mutate, setIsAuth);
                        }}/>}/>
                    </Route>
                </Routes>
            </RouteArea>
        </BrowserRouter>
    );
};

interface RouteContentProps {
    readonly isAuth: boolean;
    readonly authentication: Auth;
    setIsAuth: (isAuth: boolean) => void;
}

const RouteContent = (props: RouteContentProps) => {
    const {
        isAuth,
        authentication,
        setIsAuth
    } = props;

    return (
        <Aux>
            <App auth={authentication} isAuth={isAuth} setIsAuth={setIsAuth}/>
            <Content>
                {isAuth && (<Toolbar/>)}
                <Outlet/>
            </Content>
        </Aux>
    );
};

const RouteArea = styled('div')<{isAuth: boolean}>(
    ({isAuth}) => ({
        display: isAuth ? 'flex' : undefined,
        flexGrow: isAuth ? undefined : 1,
    }),
);

const Content = styled('main')(
    ({theme}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
    }),
);

// @ts-ignore
export default graphql(CREATE_ACCOUNT_MUTATION)(MakeMainRoutes);