import React from 'react';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    TextField,
    MenuItem,
    Typography,
    Button,
    styled
} from '@mui/material';

export enum TypeSubscribe {
    MONTHLY,
    YEARLY
}

export enum PriceSubscribe {
    MONTHLY = 4.99,
    YEARLY = 49.90
}

export enum DisplayType {
    MONTHLY = '/mois',
    YEARLY = '/an'
}

interface SelectValue {
    label: string;
    value: TypeSubscribe;
}

interface PricingProps {
    readonly type: TypeSubscribe;
    setType: (value: TypeSubscribe) => void;
    nextStep: () => void;
}

const Pricing: any = (props: PricingProps) => {
    const {
        type,
        setType,
        nextStep
    } = props;

    const selectValues: SelectValue[] = [
        {
            label: 'Mensuel',
            value: TypeSubscribe.MONTHLY
        },
        {
            label: 'Annuel',
            value: TypeSubscribe.YEARLY
        }
    ];

    const feature: string[] = [
        'Fonction 1',
        'Fonction 2'
    ];

    return (
        <CardCustom>
            <CardHeaderCustom
                title='Abonnement Premium'
                subheader='Choisissez votre abonnement'
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}/>
            <CardContent>
                <SelectArea>
                    <TextField
                        label='choix abonnement'
                        select
                        value={type}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setType(e.target.value as any)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        margin="normal"
                        variant="outlined">
                        {selectValues.map((elem) => (
                            <MenuItem key={elem.value} value={elem.value}>
                                {elem.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </SelectArea>
                <PricingArea>
                    <Typography component='h2' variant='h3' color='textPrimary'>
                        {(type === TypeSubscribe.MONTHLY) ? (PriceSubscribe.MONTHLY as number).toFixed(2) : (PriceSubscribe.YEARLY as number).toFixed(2)} €
                    </Typography>
                    <Typography variant='h6' color='textSecondary'>
                        {(type === TypeSubscribe.MONTHLY) ? DisplayType.MONTHLY : DisplayType.YEARLY}
                    </Typography>
                </PricingArea>
                <ul>
                    {feature.map((elem, index) => (
                        <Typography component='li' variant='subtitle1' align='center' key={index}>
                            {elem}
                        </Typography>
                    ))}
                </ul>
            </CardContent>
            <CardActions>
                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={nextStep}>
                    S'abonner
                </Button>
            </CardActions>
        </CardCustom>
    );
};

const CardCustom = styled(Card)(
    () => ({
        maxWidth: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        '& > ul': {
            margin: 0,
            padding: 0,
        },
        '& > li': {
            listStyle: 'none',
        },
    }),
);

const CardHeaderCustom = styled(CardHeader)(
    ({theme}) => ({
        backgroundColor: theme.palette.grey[200],
    }),
);

const SelectArea = styled('div')(
    () => ({
        textAlign: 'center',
    }),
);

const PricingArea = styled('div')(
    ({theme}) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    }),
);

export default Pricing;