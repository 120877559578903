import history from '../../utils/history';
import { Auth0DecodedHash, Auth0Error, WebAuth } from 'auth0-js';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import { MutationFunction } from '@apollo/react-hoc';

import { AUTH_CONFIG } from './Config';

export default class Auth {
    accessToken: string | null = null;
    idToken: string | null = null;
    expiresAt: number = 0;
    role: string | null = null;
    idAuth0User: string | null = null;
    auth0: WebAuth = new WebAuth({
        domain: AUTH_CONFIG.domain,
        clientID: AUTH_CONFIG.clientId,
        redirectUri: AUTH_CONFIG.callbackUrl,
        audience: AUTH_CONFIG.audience,
        responseType: 'token id_token',
        scope: 'openid profile email',
    });

    login(isSignup?: boolean): void {
        this.auth0.authorize(isSignup ? {
            screen_hint: 'signup'
        } : {});
    }

    handleAuthentication(mutate: MutationFunction, saveAccount: any, setIsAuth: (isAuth: boolean) => void): void {
        this.auth0.parseHash((error: Auth0Error | any, authResult: Auth0DecodedHash | any) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult, true);
                if (this.isAuthenticated()) {
                    setIsAuth(true);
                }
                // @ts-ignore
                saveAccount(mutate, jwt.decode(authResult.idToken).sub);
            } else if (error && !this.isAuthenticated()) {
                history.replace('/');
                console.log(error);
                //alert(`Error: ${error.error}. Check the console for further details.`);
            }
        });
    }

    getAccessToken(): string | null {
        return this.accessToken;
    }

    getIdToken(): string | null {
        return this.idToken;
    }

    setSession(authResult: Auth0DecodedHash, redirect?: boolean): void {
        const {accessToken, expiresIn, idToken} = authResult;
        let expiresAt: number = (expiresIn as number * 1000) + new Date().getTime();
        this.accessToken = accessToken as string;
        this.idToken = idToken as string;
        this.expiresAt = expiresAt;
        Cookies.set('accessToken', this.accessToken);
        Cookies.set('idToken', this.idToken);
        Cookies.set('expiresAt', this.expiresAt as any);
        localStorage.setItem('expiresAt', this.expiresAt as any);
        if (redirect) {
            //history.replace('/');
        }
    }

    renewSession(): void {
        this.auth0.checkSession({}, (error: Auth0Error | any, authResult: Auth0DecodedHash | any) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);
            } else if (error) {
                this.logout();
                console.log(error);
                //alert(`Could not get a new token (${error.error}: ${error.errorDescription}).`);
            }
        });
    }

    logout(force?: boolean): void {
        this.accessToken = null;
        this.idToken = null;
        this.expiresAt = 0;
        Cookies.remove('accessToken');
        Cookies.remove('idToken');
        Cookies.remove('expiresAt');
        localStorage.removeItem('expiresAt');
        if (force || this.idAuth0User) {
            this.auth0.logout({
                returnTo: AUTH_CONFIG.redirectLogout
            });
        }
    }

    isAuthenticated(): boolean {
        let expiresAt: number = this.expiresAt;
        if (expiresAt === 0) {
            this.expiresAt = (localStorage.getItem('expiresAt') || 0) as number;
            expiresAt = this.expiresAt;
        }
        const isAuth: boolean = new Date().getTime() < expiresAt;
        const token: string | undefined = Cookies.get('idToken');
        if (isAuth && token) {
            const decoded: any = jwt.decode(token);
            this.role = decoded[AUTH_CONFIG.roleUrl];
            this.idAuth0User = decoded.sub;
        }
        return isAuth;
    }
}