import React from 'react';
import {
    Slide,
    Dialog,
    DialogTitle,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    colors,
    styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
    Close as CloseIcon
} from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

import SupportRequest from '../Forms/SupportRequest';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface SupportDialogProps {
    readonly isOpen: boolean;
    readonly supportLoading: boolean;
    onCloseHandle: () => void;
    supportSend: (values: any) => void;
}

const SupportDialog: any = (props: SupportDialogProps) => {
    const {
        isOpen,
        onCloseHandle,
        supportLoading,
        supportSend
    } = props;

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseHandle}
            fullScreen={isMobile}
            TransitionComponent={isMobile ? Transition : undefined}
            aria-labelledby="form-dialog-support-title">
            {!isMobile && <DialogTitleCustom
                id="form-dialog-support-title">
                    Contacter le support technique
            </DialogTitleCustom>}
            {isMobile && <AppBarCustom>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onCloseHandle}
                        aria-label="Close"
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Title variant="h6">
                        Contacter le support technique
                    </Title>
                </Toolbar>
            </AppBarCustom>}
            <SupportArea>
                <SupportRequest
                    supportLoading={supportLoading}
                    supportSend={supportSend}
                    onCloseHandle={onCloseHandle}/>
            </SupportArea>
        </Dialog>
    );
}

const DialogTitleCustom = styled(DialogTitle)(
    () => ({
        backgroundColor: colors.grey[50],
    }),
);

const AppBarCustom = styled(AppBar)(
    () => ({
        position: 'relative',
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(2),
        flex: 1,
    }),
);

const SupportArea = styled('div')(
    () => ({
        backgroundColor: colors.grey[50],
        height: '100%'
    }),
);

export default SupportDialog;