import React, { useEffect } from 'react';
import {
    Backdrop,
    CircularProgress,
    styled
} from '@mui/material';
import { useLocation, Location, useNavigate} from 'react-router-dom';

interface CallbackProps {
    readonly isAuth: boolean;
    authentication: (location: Location) => void;
}

const Callback = (props: CallbackProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    /*eslint-disable */
    useEffect(() => {
        if (!props.isAuth) {
            props.authentication(location);
        } else {
            navigate('/');
        }
    }, [props]);
    /*eslint-enable */

    return (
        <BackdropCustom open={!props.isAuth}>
            <CircularProgress color="primary" />
        </BackdropCustom>
    );
};

const BackdropCustom = styled(Backdrop)(
    ({theme}) => ({
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }),
);

export default Callback;