import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
    Paper,
    Grid,
    styled
} from '@mui/material';
import {
    Home as HomeIcon
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';

import ItemUpsert from '../../components/Forms/ItemUpsert';
import AddField from '../../components/Collection/AddField';
import ItemNameUpsertDialog from '../../components/Dialogs/ItemNameUpsertDialog';
import SaveTemplate from '../../components/Collection/SaveTemplate';
import BreadcrumbsApp from '../../components/Breadcrumbs/BreadcrumbsApp';
import Aux from '../../hoc/Auxi';
import { useGlobalDispatch, useGlobalState } from '../../shared/global-context';
import SkeletonAddField from '../../components/Collection/SkeletonAddField';
import SkeletonSaveTemplate from '../../components/Collection/SkeletonSaveTemplate';
import SkeletonItemUpsert from '../../components/Forms/SkeletonItemUpsert';
import StatusSelector from '../../components/Collection/StatusSelector';
import {
    GET_COLLECTION_QUERY, GetCollectionData, GetCollectionVars, ItemsBySearch,
    GET_COLLECTIONS_QUERY, GetCollectionsData, GetCollectionsVars,
    GET_ITEM_BY_COLLECTION_QUERY, GetItemByCollectionData, GetItemByCollectionVars,
    UPDATE_ITEM_MUTATE, UpdateItemData, UpdateItemVars,
    UPSERT_TEMPLATE_COLLECTION_MUTATE, UpsertTemplateCollectionData, UpsertTemplateCollectionVars,
    ADD_IMAGE_ITEM_MUTATE, AddImageItemData, AddImageItemVars,
    NAME_EXIST_IN_COLLECTION_QUERY, NameExistInCollectionVars, NameExistInCollectionData,
    AddInput
} from './requests/Edit';
import { Status } from './requests/Items';

interface ItemLocal {
    id: string;
    name: string;
    status: Status;
    description?: string;
    tags: string[];
    addInput: AddInput[];
}

interface EditProps {
}

const Edit: any = (props: EditProps) => {
    const navigate = useNavigate();
    const params = useParams();
    const globalDispatch = useGlobalDispatch();
    const globalState = useGlobalState();
    const snackbar = useSnackbar();

    const [file, setFile] = useState<File | null>(null);

    const [itemLocal, setItemLocal] = useState<ItemLocal>({
        id: '',
        name: '',
        status: Status.Possessed,
        description: '',
        tags: [],
        addInput: []
    });

    const [typeSelected, setTypeSelected] = useState<number | undefined>(undefined),
        [fieldSelected, setFieldSelected] = useState(undefined),
        [valueSelected, setValueSelected] = useState(''),
        [dialogOpen, setDialogOpen] = useState({
            create: false,
            update: false
        });

    const [collectionName, setCollectionName] = useState(''),
        [itemName, setItemName] = useState('');

    const [isInitiate, setIsInitiate] = useState(false);

    const links = [
        {
            label: <HomeIconCustom/>,
            onClick: () => navigate('/')
        },
        {
            label: 'Collections',
            onClick: () => navigate('/collections')
        },
        {
            label: collectionName,
            onClick: () => navigate(`/collections/${params.collectionId as string}`)
        }
    ];

    /*eslint-disable */
    useEffect(() => {
        if (typeof typeSelected !== "undefined") {
            setDialogOpen({
                ...dialogOpen,
                create: true
            });
        }
    }, [typeSelected]);

    useEffect(() => {
        if (typeof fieldSelected !== "undefined") {
            setDialogOpen({
                ...dialogOpen,
                update: true
            });
            setValueSelected(itemLocal.addInput[fieldSelected as any].label);
        }
    }, [fieldSelected]);
    /*eslint-enable */

    const addFieldInItems: any = (name: string) => {
        if (!globalState.subscribeState.isActive) {
            globalDispatch({
                type: "updateModalSubscribe",
                isOpen: true
            });
        } else if (typeof typeSelected !== 'undefined') {
            setDialogOpen({
                ...dialogOpen,
                create: false
            });
            setItemLocal({
                ...itemLocal,
                addInput: itemLocal.addInput.concat({
                    label: name,
                    type: typeSelected,
                    value: (typeSelected === 3) ? 'N' : ''
                } as AddInput)
            });
        }
        setTypeSelected(undefined);
        setTimeout(() => {
            const element = document.getElementById('editArea');
            if (element) {
                element.scrollTop = element.scrollHeight - element.clientHeight;
            }
        }, 500);
    };

    const updateFieldInItems: any = (name: string) => {
        setDialogOpen({
            ...dialogOpen,
            update: false
        });
        setItemLocal({
            ...itemLocal,
            addInput: itemLocal.addInput.map((elem, index) => {
                if (index === fieldSelected) {
                    return {
                        ...elem,
                        label: name
                    }
                }
                return elem;
            })
        });
        setValueSelected('');
        setFieldSelected(undefined);
    };

    const deleteFieldInItems: any = (index: number) => {
        setItemLocal({
            ...itemLocal,
            addInput: itemLocal.addInput.filter((val, i) => {
                return index !== i;
            })
        });
    };

    const [
        upsertTemplateCollection,
        {loading: mutationUpsertTemplateCollectionLoading}
    ] = useMutation<UpsertTemplateCollectionData, UpsertTemplateCollectionVars>(
        UPSERT_TEMPLATE_COLLECTION_MUTATE,
        {
            update(cache, { data }) {
                const cacheCollection = cache.readQuery<GetCollectionData, GetCollectionVars>({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: params.collectionId as string
                    }
                });
                if (data?.upsertTemplateCollection && cacheCollection?.getCollection) {
                    cache.writeQuery<GetCollectionData, GetCollectionVars>({
                        query: GET_COLLECTION_QUERY,
                        variables: {
                            id: params.collectionId as string
                        },
                        data: {
                            getCollection: {
                                ...cacheCollection.getCollection,
                                template: data.upsertTemplateCollection.template
                            }
                        }
                    });
                }
            },
            onCompleted(data) {
                snackbar.enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    const [
        addImageItem
    ] = useMutation<AddImageItemData, AddImageItemVars>(
        ADD_IMAGE_ITEM_MUTATE,
        {
            update(cache, { data }) {
                const cacheCollection = cache.readQuery<GetCollectionData, GetCollectionVars>({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: params.collectionId as string
                    }
                });
                const cacheCollections = cache.readQuery<GetCollectionsData, GetCollectionsVars>({
                    query: GET_COLLECTIONS_QUERY
                });
                if (data?.addImageItem) {
                    let itemsBySearchResult: ItemsBySearch[] | undefined;
                    if (cacheCollection?.getCollection && cacheCollection.getCollection.itemsBySearch) {
                        itemsBySearchResult = cacheCollection.getCollection.itemsBySearch.map((item) => {
                            if (item.id === params.itemId as string) {
                                return {
                                    ...item,
                                    thumbnailUri: data.addImageItem.get
                                };
                            }
                            return item;
                        });
                        cache.writeQuery<GetCollectionData, GetCollectionVars>({
                            query: GET_COLLECTION_QUERY,
                            variables: {
                                id: params.collectionId as string
                            },
                            data: {
                                getCollection: {
                                    ...cacheCollection.getCollection,
                                    itemsBySearch: itemsBySearchResult
                                }
                            }
                        });
                    }
                    if (cacheCollections?.getCollections && itemsBySearchResult) {
                        const getCollectionsResult = cacheCollections.getCollections.map((collection) => {
                            if (collection.id === params.collectionId as string && itemsBySearchResult?.length) {
                                return {
                                    ...collection,
                                    lastThumbnailUri: itemsBySearchResult[0].thumbnailUri
                                }
                            }
                            return collection;
                        });
                        cache.writeQuery<GetCollectionsData, GetCollectionsVars>({
                            query: GET_COLLECTIONS_QUERY,
                            data: {
                                getCollections: getCollectionsResult
                            }
                        });
                    }
                }
            },
            async onCompleted(data) {
                if (file && data.addImageItem.put) {
                    const fileCompressed = await imageCompression(file, {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 1024
                    });
                    fetch(data.addImageItem.put, {
                        method: 'PUT',
                        headers: {
                            "Content-Type": file.type
                        },
                        body: fileCompressed
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    )

    const [
        updateItem,
        {loading: mutationUpdateItemLoading}
    ] = useMutation<UpdateItemData, UpdateItemVars>(
        UPDATE_ITEM_MUTATE,
        {
            update(cache, { data }) {
                const cacheCollection = cache.readQuery<GetCollectionData, GetCollectionVars>({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: params.collectionId as string
                    }
                });
                if (data?.updateItem && cacheCollection?.getCollection && cacheCollection.getCollection.itemsBySearch) {
                    const itemsByCollectionsAndAccountResult = cacheCollection.getCollection.itemsBySearch.map((item) => {
                        if (item.id === data.updateItem.id) {
                            return data.updateItem;
                        }
                        return item;
                    });
                    cache.writeQuery<GetCollectionData, GetCollectionVars>({
                        query: GET_COLLECTION_QUERY,
                        variables: {
                            id: params.collectionId as string
                        },
                        data: {
                            getCollection: {
                                ...cacheCollection.getCollection,
                                itemsBySearch: itemsByCollectionsAndAccountResult
                            }
                        }
                    });
                }
            },
            async onCompleted(data) {
                if (file) {
                    await addImageItem({
                        variables: {
                            collectionId: params.collectionId as string,
                            id: (itemLocal as any).id
                        }
                    });
                }
                snackbar.enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                })
            }
        }
    );

    const {data: queryGetCollectionData} = useQuery<GetCollectionData, GetCollectionVars>(
        GET_COLLECTION_QUERY,
        {
            variables: {
                id: params.collectionId as string
            }
        });

    /*eslint-disable */
    useEffect(() => {
        if (queryGetCollectionData && queryGetCollectionData.getCollection) {
            setCollectionName(queryGetCollectionData.getCollection.name);
        }
    }, [queryGetCollectionData]);
    /*eslint-enable */

    const {loading: queryLoading, error: queryError, data: queryData} = useQuery<GetItemByCollectionData, GetItemByCollectionVars>(
        GET_ITEM_BY_COLLECTION_QUERY,
        {
            variables: {collectionId: params.collectionId as string, id: params.itemId as string}
        });

    /*eslint-disable */
    useEffect(() => {
        if (queryData) {
            if (!isInitiate) {
                setItemLocal({
                    ...queryData.getItemByCollection,
                    tags: queryData.getItemByCollection.tags.map((elem) => {
                        return (elem.name || '');
                    })
                });
                setIsInitiate(true);
            }
            setItemName(queryData.getItemByCollection.name);
        }
    }, [queryData]);
    /*eslint-enable */

    const [queryMessage, setQueryMessage] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (queryLoading) {
            setQueryMessage(undefined);
        } else if (queryError) {
            console.log(`Error!: ${queryError}`);
            setQueryMessage(undefined);
        } else if (!queryData?.getItemByCollection) {
            setQueryMessage('L\'objet n\'a pas pu être récupéré');
        } else {
            setQueryMessage(undefined);
        }
    }, [queryData, queryLoading, queryError]);

    const [nameExist, setNameExist] = useState(false);

    const [
        nameExistInCollection
    ] = useLazyQuery<NameExistInCollectionData, NameExistInCollectionVars>(
        NAME_EXIST_IN_COLLECTION_QUERY,
        {
            async onCompleted(data) {
                setNameExist(data.nameExistInCollection);
            }
        }
    );

    /*eslint-disable */
    useEffect(() => {
        setNameExist(false);
        if (itemLocal.name !== itemName) {
            const timeout = setTimeout(() => {
                nameExistInCollection({
                    variables: {
                        id: params.collectionId as string,
                        name: itemLocal.name
                    }
                });
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [itemLocal.name]);
    /*eslint-enable */

    return (
        <Aux>
            <BreadcrumbsApp
                links={links}
                lastElem={itemName}/>
            {queryMessage && !queryLoading && (<Grid container spacing={2}>
                <Grid item xs={12}>
                    {queryMessage}
                </Grid>
            </Grid>)}
            <GridCustom id="editArea" container spacing={2}>
                <Grid item xs={12} md={4} lg={2}>
                    <GridSticky container spacing={2}>
                        <Grid item xs={12}>
                            {!queryMessage && !queryLoading && <AddField setTypeSelected={setTypeSelected}/>}
                            {queryLoading && <SkeletonAddField/>}
                        </Grid>
                        <Grid item xs={12}>
                            {!queryMessage && !queryLoading && <SaveTemplate
                                upsertTemplateCollection={() => {
                                    if (!globalState.subscribeState.isActive) {
                                        globalDispatch({
                                            type: "updateModalSubscribe",
                                            isOpen: true
                                        });
                                    } else {
                                        upsertTemplateCollection({
                                            variables: {
                                                id: params.collectionId as string,
                                                data: {
                                                    template: itemLocal.addInput.map((data: any) => {
                                                        return {
                                                            label: data.label,
                                                            type: data.type
                                                        }
                                                    })
                                                }
                                            }
                                        });
                                    }
                                }}
                                upsertLoading={mutationUpsertTemplateCollectionLoading}/>}
                            {queryLoading && <SkeletonSaveTemplate/>}
                        </Grid>
                        <Grid item xs={12}>
                            {!queryMessage && !queryLoading && <StatusSelector
                                status={itemLocal.status}
                                updateStatus={(value: Status) => {
                                    setItemLocal({
                                        ...itemLocal,
                                        status: value
                                    })
                                }}/>}
                            {queryLoading && <SkeletonAddField/>}
                        </Grid>
                    </GridSticky>
                </Grid>
                <Grid item xs={12} md={8} lg={10}>
                    <PaperRoot elevation={1}>
                        {!queryMessage && !queryLoading && <ItemUpsert
                            item={itemLocal}
                            nameExist={nameExist}
                            collectionId={params.collectionId as string}
                            setItem={(val: any) => {
                                setItemLocal(val);
                            }}
                            deleteFieldInItems={deleteFieldInItems}
                            setFieldSelected={setFieldSelected}
                            upsertItem={updateItem}
                            upsertLoading={mutationUpdateItemLoading}
                            cancelUpsert={() => navigate(`/collections/${params.collectionId as string}`)}
                            setFile={setFile}/>}
                        {queryLoading && <SkeletonItemUpsert />}
                    </PaperRoot>
                    <ItemNameUpsertDialog
                        isOpen={dialogOpen.create}
                        onCloseHandle={() => {
                            setDialogOpen({
                                ...dialogOpen,
                                create: false
                            });
                            setTypeSelected(undefined);
                        }}
                        upsertItemName={addFieldInItems}/>
                    <ItemNameUpsertDialog
                        isOpen={dialogOpen.update}
                        onCloseHandle={() => {
                            setDialogOpen({
                                ...dialogOpen,
                                update: false
                            });
                            setValueSelected('');
                            setFieldSelected(undefined);
                        }}
                        name={valueSelected}
                        upsertItemName={updateFieldInItems}/>
                </Grid>
            </GridCustom>
        </Aux>
    );
};

const HomeIconCustom = styled(HomeIcon)(
    ({theme}) => ({
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }),
);

const GridCustom = styled(Grid)(
    () => ({
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
    }),
);

const GridSticky = styled(Grid)(
    () => ({
        position: 'sticky',
        top: 0,
    }),
);

const PaperRoot = styled(Paper)(
    ({theme}) => ({
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.custom.cardItem.backgroundColor,
    }),
);

export default Edit;