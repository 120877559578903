import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Button,
    Typography,
    TextField,
    MenuItem,
    styled,
    useTheme
} from '@mui/material';
import {
    Lock
} from '@mui/icons-material';

import { useGlobalState } from '../../shared/global-context';

export enum InputType {
    Text,
    TextArea,
    Number,
    YesNo,
    Rating,
}

export const items: any[] = [
    {
        label: 'Texte',
        value: InputType.Text
    },
    {
        label: 'Zone de texte',
        value: InputType.TextArea
    },
    {
        label: 'Nombre',
        value: InputType.Number
    },
    {
        label: 'Oui/Non',
        value: InputType.YesNo
    },
    {
        label: 'Note sur 5',
        value: InputType.Rating
    }
];

interface AddFieldProps {
    setTypeSelected: (type: InputType) => void;
    isModal?: boolean;
}

const AddField: any = (props: AddFieldProps) => {
    const {
        setTypeSelected,
        isModal
    } = props;

    const [inputType, setInputType] = useState(InputType.Text);

    const globalState = useGlobalState();
    const theme = useTheme();

    return (
        <CardCustom>
            <CardContent>
                <Title
                    color="textSecondary"
                    gutterBottom>
                    Ajouter un champs personnalisé
                </Title>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextFieldCustom
                            select
                            label="Choisir le type"
                            fullWidth
                            value={inputType}
                            onChange={(event: any) => {
                                setInputType(event.target.value);
                            }}
                            SelectProps={{
                                MenuProps: {
                                    MenuListProps: {
                                        style: {
                                            backgroundColor: isModal ? 'white' : theme.custom.cardItem.backgroundColor,
                                        },
                                    }
                                }
                            }}
                            margin="normal"
                            variant="outlined">
                            {items.map((item: any) => (
                                <MenuItem  key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </TextFieldCustom>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonCustom
                            variant={globalState.subscribeState.isActive ? 'outlined' : 'contained'}
                            fullWidth
                            onClick={() => setTypeSelected(inputType)}
                            startIcon={globalState.subscribeState.isActive ? undefined : <Lock/>}>
                            Ajouter
                        </ButtonCustom>
                    </Grid>
                </Grid>
            </CardContent>
        </CardCustom>
    );
};

const CardCustom = styled(Card)<{isModal?: boolean}>(
    ({theme, isModal}) => ({
        margin: 'auto',
        [theme.breakpoints.up('xs')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: '300px',
        },
        backgroundColor: isModal ? 'white' : theme.custom.cardItem.backgroundColor,
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        fontSize: 14,
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    }),
);

const TextFieldCustom = styled(TextField)(
    () => ({

    }),
);

const ButtonCustom = styled(Button)(
    () => ({

    }),
);

export default AddField;