import React from 'react';
import {
    Card,
    CardContent,
    Skeleton,
    styled
} from '@mui/material';

interface SkeletonSaveTemplateProps {

}

const SkeletonSaveTemplate: any = (props: SkeletonSaveTemplateProps) => {
    return (
        <CardCustom>
            <CardContent>
                <Skeleton height={20} width="100%" />
                <Skeleton height={20} width="100%" style={{ marginBottom: 6 }} />
                <Wrapper>
                    <Skeleton variant="rectangular" width="100%" height={30} />
                </Wrapper>
            </CardContent>
        </CardCustom>
    );
};

const CardCustom = styled(Card)(
    ({theme}) => ({
        margin: 'auto',
        backgroundColor: theme.custom.cardItem.backgroundColor,
        [theme.breakpoints.up('xs')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: '300px',
        },
    }),
);

const Wrapper = styled('div')(
    () => ({
        position: 'relative',
    }),
);

export default SkeletonSaveTemplate;
