import { gql } from '@apollo/client';

export interface AccountByIdVars {
    id?: string;
}

export interface Address {
    street: string;
    zipCode: string;
    city: string;
    country: string;
}

export interface AccountByIdData {
    accountById: {
        id: string;
        firstName?: string;
        lastName?: string;
        dateBirth?: string;
        address?: Address;
    };
}

export const ACCOUNT_BY_ID_QUERY = gql`
    query AccountById($id: ID) {
        accountById(id: $id) {
            id
            firstName
            lastName
            dateBirth
            address {
                street
                zipCode
                city
                country
            }
        }
    }
`;

export interface UpdateAccountInput {
    firstName: string;
    lastName: string;
    dateBirth: string;
    address: Address;
}

export interface SubscribeVars {
    type: number;
    data: UpdateAccountInput;
}

export interface SubscriptionData {
    currentPeriodStart?: Date;
    currentPeriodEnd?: Date;
    label?: string;
    isActive?: boolean;
}

export interface Account {
    id: string;
    firstName?: string;
    lastName?: string;
    dateBirth?: string;
    address?: Address;
    subscriptionData?: SubscriptionData;
}

export interface SubscribeData {
    subscribe: {
        account?: Account;
        idSession?: string;
    };
}

export const SUBSCRIBE_MUTATION = gql`
    mutation Subscribe($type: Int!, $data: UpdateAccountInput!) {
        subscribe(type: $type, data: $data) {
            account {
                id
                firstName
                lastName
                dateBirth
                address {
                    street
                    zipCode
                    city
                    country
                }
                subscriptionData {
                    currentPeriodStart
                    currentPeriodEnd
                    label
                    isActive
                }
            }
            idSession
        }
    }
`;