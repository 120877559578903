import { gql } from '@apollo/client';

export interface AccountByIdVars {
    id?: string;
}

export interface SubscriptionData {
    currentPeriodStart?: Date;
    currentPeriodEnd?: Date;
    upcomingPeriodStart?: Date;
    upcomingPeriodEnd?: Date;
    upcomingTotal?: number;
    label?: string;
    isActive?: boolean;
}

export interface AccountByIdData {
    accountById: {
        id: string;
        firstName?: string;
        lastName?: string;
        totalCollections?: number;
        totalItems?: number;
        subscriptionData?: SubscriptionData;
    };
}

export const ACCOUNT_BY_ID_QUERY = gql`
    query AccountById($id: ID) {
        accountById(id: $id) {
            id
            firstName
            lastName
            totalCollections
            totalItems
            subscriptionData {
                currentPeriodStart
                currentPeriodEnd
                upcomingPeriodStart
                upcomingPeriodEnd
                upcomingTotal
                label
                isActive
            }
        }
    }
`;