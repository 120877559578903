import { gql } from '@apollo/client';

export interface InfoSearchInput {
    type: string;
    value: string;
    cursor?: string;
}

export interface SearchVars {
    infos?: InfoSearchInput[];
}

export interface AddInput {
    label?: string;
    value?: string;
}

export interface SearchData {
    search: {
        collectionId?: string;
        collectionCreatedAt?: string;
        collectionName?: string;
        collectionDescription?: string;
        itemId?: string;
        itemCreatedAt?: string;
        itemName?: string;
        itemDescription?: string;
        tags?: string[];
        addInput?: AddInput;
    }[];
}

export const SEARCH_QUERY = gql`
    query Search($infos: [InfoSearchInput!]) {
        search(infos: $infos) {
            collectionId
            collectionCreatedAt
            collectionName
            collectionDescription
            itemId
            itemCreatedAt
            itemName
            itemDescription
            tags
            addInput {
                label
                value
            }
        }
    }
`;