import React from 'react';
import {
    Grid,
    Paper,
    InputBase,
    Divider,
    IconButton,
    Select,
    MenuItem,
    ToggleButton,
    ToggleButtonGroup,
    styled,
    Typography,
    Card,
    CardActionArea,
    TablePagination
} from '@mui/material';
import {
    Clear,
    ViewList as ViewListIcon,
    ViewModule as ViewModuleIcon,
    Add as AddIcon,
} from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

interface FilterQueryProps {
    readonly filterSelectContent: any[];
    readonly filterSelectOrder: any[];
    readonly filterResult: any[];
    readonly typeDisplay: string;
    readonly totalResult: number;
    readonly currentPage: number;
    readonly currentRowsPerPage: number;
    readonly isCollection?: boolean;
    setFilterResult: (values: any) => void;
    setTypeDisplay: (value: string) => void;
    setCurrentPage: (value: number) => void;
    setCurrentRowsPerPage: (value: number) => void;
    resetFilter: () => void;
    resetOrder: () => void;
    addHandler: () => void;
    addMultipleHandler?: () => void;
}

const FilterQuery: any = (props: FilterQueryProps) => {
    const {
        filterSelectContent,
        filterSelectOrder,
        filterResult,
        setFilterResult,
        resetFilter,
        resetOrder,
        typeDisplay,
        setTypeDisplay,
        addHandler,
        totalResult,
        currentPage,
        currentRowsPerPage,
        setCurrentPage,
        setCurrentRowsPerPage,
        isCollection,
        addMultipleHandler
    } = props;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FilterArea variant="outlined">
                    <Grid container spacing={0}>
                        <GridCustom borderR item xs={3} sm={3} md={3} lg={3} xl={3}>
                            <VerticalCenter>
                                <FilterTitle gutterBottom component="div">
                                    Ajouter
                                </FilterTitle>
                                <CardCustom>
                                    <CardActionAreaCustom isCollection={isCollection} onClick={addHandler}>
                                        {isCollection && <AddIconCustom/>}
                                        {!isCollection && <AddTextCustom>1 par 1</AddTextCustom>}
                                    </CardActionAreaCustom>
                                </CardCustom>
                                {!isCollection && <CardCustom>
                                    <CardActionAreaCustom isCollection={isCollection} onClick={addMultipleHandler}>
                                        <AddTextCustom>Multiple</AddTextCustom>
                                    </CardActionAreaCustom>
                                </CardCustom>}
                                <Divider orientation="vertical" flexItem/>
                            </VerticalCenter>
                        </GridCustom>
                        <GridCustom item xs={9} sm={9} md={9} lg={9} xl={9}>
                            <Grid container spacing={0}>
                                <GridCustom borderB item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container spacing={0}>
                                        <GridCustom borderR item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <ElementArea>
                                                <FilterTitle gutterBottom component="div">
                                                    Filtre
                                                </FilterTitle>
                                                <RootLeft>
                                                    {!isMobile && <SelectCustom
                                                        value={filterResult[0].type}
                                                        displayEmpty
                                                        onChange={(e: any) => {
                                                            setCurrentPage(0);
                                                            setFilterResult(
                                                                filterResult.map((elemSearch: any, index: number) => {
                                                                    if (index === 0) {
                                                                        return {
                                                                            ...elemSearch,
                                                                            type: e.target.value
                                                                        };
                                                                    }
                                                                    return elemSearch;
                                                                })
                                                            );
                                                        }}
                                                        input={<InputBase name="choice"/>}>
                                                        <MenuItemCustom value="">
                                                            <em>Choix du filtre</em>
                                                        </MenuItemCustom>
                                                        {filterSelectContent.map((elemSelect: any, index: number) => (
                                                            <MenuItemCustom key={elemSelect.id} value={elemSelect.id}>{elemSelect.label}</MenuItemCustom>
                                                        ))}
                                                    </SelectCustom>}
                                                    <InputBaseCustom
                                                        value={filterResult[0].value}
                                                        onChange={(e: any) => {
                                                            setCurrentPage(0);
                                                            setFilterResult(
                                                                filterResult.map((elemSearch: any, index: number) => {
                                                                    if (index === 0) {
                                                                        return {
                                                                            ...elemSearch,
                                                                            value: e.target.value
                                                                        };
                                                                    }
                                                                    return elemSearch;
                                                                })
                                                            );
                                                        }}
                                                        placeholder="Nom de l'objet"
                                                        inputProps={{ 'aria-label': 'find item' }}/>
                                                    <IconButtonCustom
                                                        onClick={() => {
                                                            setCurrentPage(0);
                                                            resetFilter();
                                                        }}
                                                        color="primary"
                                                        aria-label="add search"
                                                        size="large">
                                                        <Clear/>
                                                    </IconButtonCustom>
                                                </RootLeft>
                                            </ElementArea>
                                        </GridCustom>
                                        <GridCustom item xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <ElementArea>
                                                <FilterTitle gutterBottom component="div">
                                                    Tri
                                                </FilterTitle>
                                                <RootRight>
                                                    <SelectCustom
                                                        value={filterResult[1].type || 'nameAsc'}
                                                        displayEmpty
                                                        onChange={(e: any) => {
                                                            setCurrentPage(0);
                                                            setFilterResult(
                                                                filterResult.map((elemSearch: any, index: number) => {
                                                                    if (index === 1) {
                                                                        return {
                                                                            ...elemSearch,
                                                                            type: e.target.value,
                                                                            value: (e.target.value as string).includes('Asc') ? 'asc' : 'desc',
                                                                        };
                                                                    }
                                                                    return elemSearch;
                                                                })
                                                            );
                                                        }}
                                                        input={<InputBase name="choice"/>}>
                                                        <MenuItem value="">
                                                            <em>Choix du tri</em>
                                                        </MenuItem>
                                                        {filterSelectOrder.map((elemSelect: any, index: number) => (
                                                            <MenuItem key={elemSelect.id} value={elemSelect.id}>{elemSelect.label}</MenuItem>
                                                        ))}
                                                    </SelectCustom>
                                                    <IconButtonCustom
                                                        onClick={() => {
                                                            setCurrentPage(0);
                                                            resetOrder();
                                                        }}
                                                        color="primary"
                                                        aria-label="add search"
                                                        size="large">
                                                        <Clear/>
                                                    </IconButtonCustom>
                                                </RootRight>
                                            </ElementArea>
                                        </GridCustom>
                                    </Grid>
                                </GridCustom>
                                <GridCustom item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container spacing={0}>
                                        <GridCustom borderR item xs={2} sm={2} md={2} lg={2} xl={2}>
                                            <ElementArea>
                                                <FilterTitle gutterBottom component="div">
                                                    Affichage
                                                </FilterTitle>
                                                <RootRight elevation={0}>
                                                    <ToggleButtonGroupCustom
                                                        size="small"
                                                        value={typeDisplay}
                                                        exclusive
                                                        onChange={(event, newTypeDisplay) => {
                                                            setTypeDisplay(newTypeDisplay);
                                                        }}
                                                        aria-label="type display">
                                                        <ToggleButton value="block" aria-label="display block">
                                                            <ViewModuleIcon />
                                                        </ToggleButton>
                                                        <ToggleButton value="line" aria-label="display line">
                                                            <ViewListIcon />
                                                        </ToggleButton>
                                                    </ToggleButtonGroupCustom>
                                                </RootRight>
                                            </ElementArea>
                                        </GridCustom>
                                        <GridCustom item xs={10} sm={10} md={10} lg={10} xl={10}>
                                            <ElementArea>
                                                <FilterTitle gutterBottom component="div">
                                                    Pagination
                                                </FilterTitle>
                                                <RootRight>
                                                    <TablePagination
                                                        component="div"
                                                        count={totalResult}
                                                        page={currentPage}
                                                        onPageChange={(event, page) => {
                                                            setCurrentPage(page);
                                                        }}
                                                        rowsPerPage={currentRowsPerPage}
                                                        onRowsPerPageChange={(event) => {
                                                            setCurrentRowsPerPage(parseInt(event.target.value, 10));
                                                            setCurrentPage(0);
                                                        }}
                                                        labelDisplayedRows={({from, to, count}) => {
                                                            return `${from}-${to} sur ${count !== -1 ? count : `plus que ${to}`}`
                                                        }}
                                                        labelRowsPerPage="par page:"
                                                        rowsPerPageOptions={[10, 15, 20, 30, 50]}
                                                        showFirstButton
                                                        showLastButton/>
                                                </RootRight>
                                            </ElementArea>
                                        </GridCustom>
                                    </Grid>
                                </GridCustom>
                            </Grid>
                        </GridCustom>
                    </Grid>
                </FilterArea>
            </Grid>
        </Grid>
    );
}

const RootLeft = styled(Paper)(
    () => ({
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        marginBottom: '20px',
    }),
);

const SelectCustom = styled(Select)(
    () => ({
        marginLeft: 8,
    }),
);

const InputBaseCustom = styled(InputBase)(
    () => ({
        marginLeft: 8,
        flex: 1,
    }),
);

const IconButtonCustom = styled(IconButton)(
    () => ({
        padding: 10,
    }),
);

const RootRight = styled(Paper)(
    () => ({
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        backgroundColor: '#fff',
        marginLeft: 'auto',
        marginRight: 'auto',
    }),
);

const ToggleButtonGroupCustom = styled(ToggleButtonGroup)(
    ({theme}) => ({
        grouped: {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
            border: 'none',
            '&:not(:first-child)': {
                borderRadius: theme.shape.borderRadius,
            },
            '&:first-child': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    }),
);

const FilterArea = styled(Paper)(
    ({theme}) => ({
        backgroundColor: theme.palette.background.default,
    }),
);

const FilterTitle: any = styled(Typography)(
    ({theme}) => ({
        textAlign: 'center',
        fontWeight: 500,
        marginTop: theme.spacing(1),
    }),
);

const CardActionAreaCustom = styled(CardActionArea)<{isCollection?: boolean}>(
    ({theme, isCollection}) => ({
        textAlign: 'center',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        backgroundColor: !isCollection ? theme.custom.cardItem.backgroundColor : undefined,
    }),
);

const GridCustom = styled(Grid)<{borderR?: boolean, borderB?: boolean}>(
    ({theme, borderR, borderB}) => ({
        padding: theme.spacing(1),
        borderRight: borderR ? '1px solid #EEE' : undefined,
        borderBottom: borderB ? '1px solid #EEE' : undefined,
        position: 'relative'
    }),
);

const AddTextCustom = styled('div')(
    ({theme}) => ({
        fontSize: 30,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }),
);

const AddIconCustom = styled(AddIcon)(
    ({theme}) => ({
        fontSize: 30,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }),
);

const CardCustom = styled(Card)(
    ({theme}) => ({
        maxWidth: '200px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(1),
    }),
);

const VerticalCenter = styled('div')(
    () => ({
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        "-ms-transform": 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
    }),
);

const ElementArea = styled('div')(
    () => ({
        //overflow: 'hidden',
    }),
);

const MenuItemCustom = styled(MenuItem)(
    ({theme}) => ({
        //backgroundColor: theme.config.color.without,
    }),
);

export default FilterQuery;