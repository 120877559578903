import { gql } from '@apollo/client';

import { Status } from './Items'

export interface InfoSearchInput {
    type: string;
    value: string;
    cursor?: string;
}

export interface GetCollectionVars {
    id: string;
    cursor?: string;
    infos?: InfoSearchInput[];
}

export interface Tags {
    name?: string;
}

export interface AddInput {
    label: string;
    type: number;
    value?: string;
}

export interface ItemsBySearch {
    id: string;
    createdAt: Date;
    name: string;
    status: Status;
    description?: string;
    thumbnailUri?: string;
    tags: Tags[];
    hasAddInput?: boolean;
    addInput: AddInput[];
}

export interface GetCollectionData {
    getCollection: {
        id: string;
        name: string;
        template: AddInput[];
        itemsBySearch?: ItemsBySearch[];
    };
}

export const GET_COLLECTION_QUERY = gql`
    query GetCollection($id: ID!, $cursor: String, $infos: [InfoSearchInput!]) {
        getCollection(id: $id) {
            id
            name
            template {
                label
                type
            },
            itemsBySearch(cursor: $cursor, infos: $infos) {
                id
                createdAt
                name
                status
                description
                thumbnailUri
                tags {
                    name
                }
                hasAddInput
                addInput {
                    label
                    type
                    value
                }
            }
        }
    }
`;

export interface GetCollectionsVars {
    cursor?: string;
    infos?: InfoSearchInput[];
}

export interface GetCollectionsData {
    getCollections: {
        id: string;
        createdAt: Date;
        name: string;
        description?: string;
        template: AddInput[];
        totalItems?: number;
        lastThumbnailUri?: string;
    }[];
}

export const GET_COLLECTIONS_QUERY = gql`
    query GetCollections($cursor: String, $infos: [InfoSearchInput!]) {
        getCollections(cursor: $cursor, infos: $infos) {
            id
            createdAt
            name
            description
            template {
                label
                type
            }
            totalItems
            lastThumbnailUri
        }
    }
`;

export interface GetItemByCollectionVars {
    id: string;
    collectionId: string;
}

export interface GetItemByCollectionData {
    getItemByCollection: {
        id: string;
        name: string;
        status: Status;
        description?: string;
        tags: Tags[];
        addInput: AddInput[];
        thumbnailUri?: string;
    };
}

export const GET_ITEM_BY_COLLECTION_QUERY = gql`
    query GetItemByCollection($collectionId: ID!, $id: ID!) {
        getItemByCollection(collectionId: $collectionId, id: $id) {
            id
            name
            status
            description
            tags {
                name
            }
            addInput {
                label
                type
                value
            }
            thumbnailUri
        }
    }
`;

export interface UpdateItemInput {
    name: string;
    status: Status;
    description?: string;
    tags?: Tags[];
    addInput?: AddInput[];
}

export interface UpdateItemVars {
    id: string;
    collectionId: string;
    data: UpdateItemInput;
}

export interface UpdateItemData {
    updateItem: {
        id: string;
        createdAt: Date;
        name: string;
        status: Status;
        description?: string;
        thumbnailUri?: string;
        tags: Tags[];
        hasAddInput?: boolean;
        addInput: AddInput[];
    };
}

export const UPDATE_ITEM_MUTATE = gql`
    mutation UpdateItem($collectionId: ID!, $id: ID!, $data: UpdateItemInput!) {
        updateItem(collectionId: $collectionId, id: $id, data: $data) {
            id
            createdAt
            name
            status
            description
            thumbnailUri
            tags {
                name
            }
            hasAddInput
            addInput {
                label
                type
                value
            }
        }
    }
`;

export interface AddInputInput {
    label: string;
    type: number;
    value?: string;
}

export interface UpsertTemplateCollectionInput {
    template?: AddInputInput[];
}

export interface UpsertTemplateCollectionVars {
    id: string;
    data: UpsertTemplateCollectionInput;
}

export interface UpsertTemplateCollectionData {
    upsertTemplateCollection: {
        id: string;
        template: AddInput[];
    };
}

export const UPSERT_TEMPLATE_COLLECTION_MUTATE = gql`
    mutation UpsertTemplateCollection($id: ID!, $data: UpsertTemplateCollectionInput!) {
        upsertTemplateCollection(id: $id, data: $data) {
            id
            template {
                label
                type
            }
        }
    }
`;

export interface AddImageItemVars {
    id: string;
    collectionId: string;
}

export interface AddImageItemData {
    addImageItem: {
        put?: string;
        get?: string;
    }
}

export const ADD_IMAGE_ITEM_MUTATE = gql`
    mutation AddImageItem($collectionId: ID!, $id: ID!) {
        addImageItem(collectionId: $collectionId, id: $id) {
            put
            get
        }
    }
`;

export interface NameExistInCollectionVars {
    id: string;
    name: string;
}

export interface NameExistInCollectionData {
    nameExistInCollection: boolean;
}

export const NAME_EXIST_IN_COLLECTION_QUERY = gql`
    query NameExistInCollection($id: ID!, $name: String!) {
        nameExistInCollection(id: $id, name: $name)
    }
`;