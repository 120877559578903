import React, { useEffect, useState } from 'react';
import {
    TextField,
    MenuItem,
    Grid,
    Button,
    CircularProgress,
    colors,
    styled
} from '@mui/material';

interface AccountEditProps {
    readonly account: any;
    readonly updateLoading: boolean;
    updateAccount: (values: any) => void;
}

const AccountEdit: any = (props: AccountEditProps) => {
    const {
        account,
        updateLoading,
        updateAccount
    } = props;

    const [elemForm, setElemForm] = useState({
        firstName: '',
        lastName: '',
        dateBirth: '',
        address: {
            street: '',
            zipCode: '',
            city: '',
            country: ''
        }
    });

    const [elemEmpty, setElemEmpty] = useState({
        firstName: false,
        lastName: false,
        dateBirth: false,
        address: {
            street: false,
            zipCode: false,
            city: false,
            country: false
        }
    });

    useEffect(() => {
        if (account) {
            setElemForm(account);
        }
    }, [account]);

    useEffect(() => {
        setElemEmpty({
            firstName: (elemForm.firstName ? elemForm.firstName.trim() === '' : true),
            lastName: (elemForm.lastName ? elemForm.lastName.trim() === '' : true),
            dateBirth: (elemForm.dateBirth ? elemForm.dateBirth.trim() === '' : true),
            address: {
                street: ((elemForm.address && elemForm.address.street) ? elemForm.address.street.trim() === '' : true),
                zipCode: ((elemForm.address && elemForm.address.zipCode) ? elemForm.address.zipCode.trim() === '' : true),
                city: ((elemForm.address && elemForm.address.city) ? elemForm.address.city.trim() === '' : true),
                country: ((elemForm.address && elemForm.address.country) ? elemForm.address.country.trim() === '' : true)
            }
        });
    }, [elemForm]);

    const saveHandle = () => {
        updateAccount({
            variables: {
                data: {
                    firstName: elemForm.firstName ? elemForm.firstName.trim() || null : null,
                    lastName: elemForm.lastName ? elemForm.lastName.trim() || null : null,
                    dateBirth: elemForm.dateBirth ? elemForm.dateBirth.trim() || null : null,
                    address: {
                        street: (elemForm.address && elemForm.address.street) ? elemForm.address.street.trim() || null : null,
                        zipCode: (elemForm.address && elemForm.address.zipCode) ? elemForm.address.zipCode.trim() || null : null,
                        city: (elemForm.address && elemForm.address.city) ? elemForm.address.city.trim() || null : null,
                        country: (elemForm.address && elemForm.address.country) ? elemForm.address.country.trim() || null : null
                    }
                }
            }
        })
    };

    return (
        <Container
            noValidate
            autoComplete="off">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextFieldCustom
                        label="Prénom"
                        autoFocus={true}
                        value={elemForm.firstName || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setElemForm({...elemForm, firstName: e.target.value})}
                        onKeyPress={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                saveHandle();
                            }
                        }}
                        helperText={elemEmpty.firstName && 'Le prénom est obligatoire'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        error={elemEmpty.firstName}
                        fullWidth
                        margin="normal"
                        variant="outlined"/>
                </Grid>
                <Grid item xs={6}>
                    <TextFieldCustom
                        label="Nom"
                        value={elemForm.lastName || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setElemForm({...elemForm, lastName: e.target.value})}
                        onKeyPress={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                saveHandle();
                            }
                        }}
                        helperText={elemEmpty.lastName && 'Le nom est obligatoire'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        error={elemEmpty.lastName}
                        fullWidth
                        margin="normal"
                        variant="outlined"/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextFieldCustom
                        label="Date de naissance"
                        type="date"
                        value={elemForm.dateBirth || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setElemForm({...elemForm, dateBirth: e.target.value})}
                        onKeyPress={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                saveHandle();
                            }
                        }}
                        helperText={elemEmpty.dateBirth && 'La date de naissance est obligatoire'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        error={elemEmpty.dateBirth}
                        fullWidth
                        margin="normal"
                        variant="outlined"/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextFieldCustom
                        label="Rue"
                        multiline
                        rows="4"
                        value={elemForm.address ? elemForm.address.street || '' : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setElemForm({
                                ...elemForm,
                                address: {
                                    ...elemForm.address,
                                    street: e.target.value,
                                }
                            })}
                        onKeyPress={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                saveHandle();
                            }
                        }}
                        helperText={elemEmpty.address.street && 'La rue est obligatoire'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        error={elemEmpty.address.street}
                        fullWidth
                        margin="normal"
                        variant="outlined"/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextFieldCustom
                        label="Code postal"
                        value={elemForm.address ? elemForm.address.zipCode || '' : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setElemForm({
                                ...elemForm,
                                address: {
                                    ...elemForm.address,
                                    zipCode: e.target.value
                                }
                            })}
                        onKeyPress={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                saveHandle();
                            }
                        }}
                        helperText={elemEmpty.address.zipCode && 'Le code postal est obligatoire'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        error={elemEmpty.address.zipCode}
                        fullWidth
                        margin="normal"
                        variant="outlined"/>
                </Grid>
                <Grid item xs={6}>
                    <TextFieldCustom
                        label="Ville"
                        value={elemForm.address ? elemForm.address.city || '' : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setElemForm({
                                ...elemForm,
                                address: {
                                    ...elemForm.address,
                                    city: e.target.value
                                }
                            })}
                        onKeyPress={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                saveHandle();
                            }
                        }}
                        helperText={elemEmpty.address.city && 'La ville est obligatoire'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        error={elemEmpty.address.city}
                        fullWidth
                        margin="normal"
                        variant="outlined"/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextFieldCustom
                        label="Pays"
                        select
                        value={elemForm.address ? elemForm.address.country || '' : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setElemForm({
                                ...elemForm,
                                address: {
                                    ...elemForm.address,
                                    country: e.target.value
                                }
                            })}
                        SelectProps={{
                            style: {

                            },
                        }}
                        helperText={elemEmpty.address.country ? 'Le pays est obligatoire' : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        error={elemEmpty.address.country}
                        fullWidth
                        margin="normal"
                        variant="outlined">
                        <MenuItem value="FR">
                            France
                        </MenuItem>
                    </TextFieldCustom>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Wrapper>
                        <ButtonCustom
                            variant="outlined"
                            color="primary"
                            fullWidth
                            disabled={!(
                                !elemEmpty.firstName &&
                                !elemEmpty.lastName &&
                                !elemEmpty.dateBirth &&
                                !elemEmpty.address.street &&
                                !elemEmpty.address.zipCode &&
                                !elemEmpty.address.city &&
                                !elemEmpty.address.country) || updateLoading}
                            onClick={saveHandle}>
                            Enregistrer
                        </ButtonCustom>
                        {updateLoading && <ButtonProgress size={24}/>}
                    </Wrapper>
                </Grid>
            </Grid>
        </Container>
    );
};

const Container = styled('form')(
    () => ({
        display: 'flex',
        flexWrap: 'wrap',
    }),
);

const TextFieldCustom = styled(TextField)(
    () => ({

    }),
);

const Wrapper = styled('div')(
    () => ({
        position: 'relative',
    }),
);

const ButtonCustom = styled(Button)(
    () => ({

    }),
);

const ButtonProgress = styled(CircularProgress)(
    () => ({
        color: colors.green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }),
);

export default AccountEdit;