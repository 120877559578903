import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    alpha,
    InputBase,
    CssBaseline,
    styled
} from '@mui/material';
import {
    Menu as MenuIcon,
    Person,
    Home,
    Style,
    PowerSettingsNew,
    Search as SearchIcon,
    ContactSupport as ContactSupportIcon,
    //BusinessCenter as BusinessCenterIcon
} from '@mui/icons-material';

import MenuLeft from './MenuLeft';
import Aux from '../../hoc/Auxi';
import { useGlobalDispatch, useGlobalState } from '../../shared/global-context';

const drawerWidth = 240;

interface MenuProps {
    readonly isAuth: boolean;
    logoutHandle: (props: any) => {};
}

const Menu: any = (props: MenuProps) => {
    const {
        isAuth,
        logoutHandle
    } = props;

    const globalDispatch = useGlobalDispatch();
    const globalState = useGlobalState();

    const [menuLeftOpen, setMenuLeftOpen] = useState(false);

    const menuLeftToggleHandle = () => {
        setMenuLeftOpen(!menuLeftOpen);
    };

    const MENU_CONTENT: any[] = [
        {
            label: 'Tableau de bords',
            linkTo: '/',
            onClick: () => {
                if (menuLeftOpen) {
                    menuLeftToggleHandle();
                }
            },
            icon: (<Home/>),
        },
        {
            label: 'Collections',
            linkTo: '/collections',
            onClick: () => {
                if (menuLeftOpen) {
                    menuLeftToggleHandle();
                }
            },
            icon: (<Style/>),
        },
        /*{
            label: 'Echanges',
            linkTo: '/exchanges',
            onClick: () => {
                if (menuLeftOpen) {
                    menuLeftToggleHandle();
                }
            },
            icon: (<BusinessCenterIcon/>)
        },*/
        {
            label: 'Recherche',
            onClick: () => {
                globalDispatch({
                    type: 'updateModalSearch',
                    isOpen: true,
                    search: ''
                });
                if (menuLeftOpen) {
                    menuLeftToggleHandle();
                }
            },
            icon: (<SearchIcon/>),
        },
        {
            label: 'Compte',
            linkTo: '/account',
            onClick: () => {
                if (menuLeftOpen) {
                    menuLeftToggleHandle();
                }
            },
            icon: (<Person/>),
        },
        {
            label: 'Support',
            onClick: () => {
                globalDispatch({
                    type: 'updateModalSupport',
                    isOpen: true
                });
                if (menuLeftOpen) {
                    menuLeftToggleHandle();
                }
            },
            icon: (<ContactSupportIcon/>),
        },
        {
            label: 'Déconnexion',
            onClick: () => {
                logoutHandle(props);
                if (menuLeftOpen) {
                    menuLeftToggleHandle();
                }
            },
            icon: (<PowerSettingsNew/>),
        },
    ];

    return (
        <Aux>
            <CssBaseline/>
            <AppBarCustom
                isAuth={isAuth}
                position={isAuth ? "fixed" : "static"}>
                <Toolbar>
                    {isAuth ? (
                        <MenuButton
                            color="inherit"
                            aria-label="Menu"
                            onClick={menuLeftToggleHandle}
                            size="large">
                            <MenuIcon/>
                        </MenuButton>
                    ) : null}
                    <img width={40} src="/image/logo-collectoogo-white.svg" alt="logo white"/>
                    <Grow
                        variant="h6"
                        color="inherit"
                        noWrap>
                        Collectoogo
                    </Grow>
                    {isAuth && (
                        <SearchArea>
                            <SearchIconWrapper>
                                <SearchIcon/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Recherche…"
                                inputProps={{ 'aria-label': 'search' }}
                                value={globalState.modalSearch.search}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    globalDispatch({
                                        type: 'updateModalSearch',
                                        isOpen: e.target.value.length >= 3,
                                        search: e.target.value
                                    });
                                }}
                            />
                        </SearchArea>
                    )}
                </Toolbar>
            </AppBarCustom>
            {isAuth && (
                <Aux>
                    <MenuLeft
                        menuLeftOpen={menuLeftOpen}
                        menuLeftToggleHandle={menuLeftToggleHandle}
                        menuContent={MENU_CONTENT}/>
                </Aux>
            )}
        </Aux>
    );
};

const AppBarCustom = styled(AppBar)<{isAuth: boolean}>(
    ({theme, isAuth}) => ({
        marginLeft: isAuth ? drawerWidth : undefined,
        [theme.breakpoints.up('sm')]: {
            width: isAuth ? `calc(100% - ${drawerWidth}px)` : undefined,
        },
    }),
);

const MenuButton = styled(IconButton)(
    ({theme}) => ({
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    }),
);

const Grow = styled(Typography)(
    () => ({
        flexGrow: 1,
        marginTop: '10px',
        marginLeft: '5px',
    }),
);

const SearchArea = styled('div')(
    ({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    }),
);

const SearchIconWrapper = styled('div')(
    ({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
);

const StyledInputBase = styled(InputBase)(
    ({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '12ch',
                '&:focus': {
                    width: '20ch',
                },
            },
        },
    }),
);

export default Menu;