import React, {useEffect, useState} from 'react';
import {useApolloClient, useMutation, useQuery} from '@apollo/client';
import {useSnackbar} from 'notistack';
import {
    Add as AddIcon,
    Home as HomeIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    ListAlt as ListAltIcon,
    ViewModule as ViewModuleIcon
} from '@mui/icons-material';
import {Alert, AppBar, Fab, Link, Pagination, Stack, styled, Tab} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {useNavigate, useParams} from 'react-router-dom';
import Masonry from 'react-masonry-component';
import {isMobile} from 'react-device-detect';

import ListItem from '../../components/Collection/ListItem';
import ItemDeleteDialog from '../../components/Dialogs/ItemDeleteDialog';
import CollectionTemplateDialog from '../../components/Dialogs/CollectionTemplateDialog';
import BreadcrumbsApp from '../../components/Breadcrumbs/BreadcrumbsApp';
import {getDateFromNow} from '../../utils/date-transform';
import SkeletonListItem from '../../components/Collection/SkeletonListItem';
import {
    CREATE_MULTIPLE_ITEM_MUTATE,
    CreateMultipleItemData,
    CreateMultipleItemVars,
    DELETE_ITEM_MUTATE,
    DeleteItemData,
    DeleteItemVars,
    DUPLICATE_ITEM_MUTATE,
    DuplicateItemData,
    DuplicateItemVars,
    GET_COLLECTION_QUERY,
    GET_COLLECTIONS_QUERY,
    GetCollectionData,
    GetCollectionsData,
    GetCollectionsVars,
    GetCollectionVars,
    InfoSearchInput,
    Status,
    UPSERT_TEMPLATE_COLLECTION_MUTATE,
    UpsertTemplateCollectionData,
    UpsertTemplateCollectionVars
} from './requests/Items';
import Template from '../../components/Collection/Template';
import FilterQuery from '../../components/Forms/FilterQuery';
import Aux from '../../hoc/Auxi';
import ListLineItem from '../../components/Collection/ListLineItem';
import BackTop from '../../components/Backtop/BackTop';
import ItemAddMultipleDialog from '../../components/Dialogs/ItemAddMultipleDialog';

interface ItemsProps {

}

const Items: any = (props: ItemsProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const apolloClient = useApolloClient();

    const filterSelectContent = [
        {
            label: 'Nom commence par',
            id: 'nameStart'
        },
        {
            label: 'Nom contient',
            id: 'nameContain'
        },
        {
            label: 'Nom se termine par',
            id: 'nameEnd'
        }
    ];

    const filterSelectOrder = [
        {
            label: 'A...Z',
            id: 'nameAsc'
        },
        {
            label: 'Z...A',
            id: 'nameDesc'
        },
        {
            label: 'Plus récents',
            id: 'createdDateAsc'
        },
        {
            label: 'Plus Anciens',
            id: 'createdDateDesc'
        }
    ];

    const [filterResult, setFilterResult] = useState<InfoSearchInput[]>([
        {
            type: '',
            value: ''
        },
        {
            type: '',
            value: ''
        }
    ]);

    const [typeDisplay, setTypeDisplay] = useState('block');

    const [dialogOpen, setDialogOpen] = useState({
        delete: false,
        template: false,
        createMultiple: false
    });

    const [selectedItem, setSelectedItem] = useState({
        id: '',
        name: '',
        description: ''
    });

    const [collectionName, setCollectionName] = useState('');
    const [tabValue, setTabValue] = useState('1');
    const [templateLocal, setTemplateLocal] = useState([] as any[]);

    const [scrollTarget, setScrollTarget] = useState<Node | undefined>(undefined);

    const links = [
        {
            label: <HomeIconCustom/>,
            onClick: () => navigate('/')
        },
        {
            label: 'Collections',
            onClick: () => navigate('/collections')
        }
    ];

    const [listLineItemExpanded, setListLineItemExpanded] = useState<string | false>(false);

    const [currentPage, setCurrentPage] = useState(0);
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);

    const handleListLineItemExpanded: any = (value: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setListLineItemExpanded(isExpanded ? value : false);
    };

    const [
        duplicateItem
    ] = useMutation<DuplicateItemData, DuplicateItemVars>(
        DUPLICATE_ITEM_MUTATE,
        {
            update(cache, { data }) {
                const cacheCollection = cache.readQuery<GetCollectionData, GetCollectionVars>({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: params.id as string
                    }
                });
                const cacheCollections = apolloClient.readQuery<GetCollectionsData, GetCollectionsVars>({
                    query: GET_COLLECTIONS_QUERY
                });
                if (data?.duplicateItem) {
                    if (cacheCollection?.getCollection && cacheCollection.getCollection.itemsBySearch) {
                        cache.writeQuery<GetCollectionData, GetCollectionVars>({
                            query: GET_COLLECTION_QUERY,
                            variables: {
                                id: params.id as string
                            },
                            data: {
                                getCollection: {
                                    ...cacheCollection.getCollection,
                                    itemsBySearch: [...([data.duplicateItem]), ...cacheCollection.getCollection.itemsBySearch]
                                }
                            }
                        });
                    }
                    if (cacheCollections?.getCollections && cacheCollections.getCollections.length) {
                        cache.writeQuery<GetCollectionsData, GetCollectionsVars>({
                            query: GET_COLLECTIONS_QUERY,
                            data: { getCollections:
                                (cacheCollections.getCollections).map((elem) => {
                                    if (elem.id === params.id as string) {
                                        return {
                                            ...elem,
                                            totalItems: (elem.totalItems || 0) + 1
                                        }
                                    }
                                    return elem;
                                })
                            }
                        });
                    }
                }
            },
            onCompleted(data) {
                enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
            },
            onError(error) {
                enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    const [
        upsertTemplateCollection,
        {loading: mutationUpsertTemplateCollectionLoading}
    ] = useMutation<UpsertTemplateCollectionData, UpsertTemplateCollectionVars>(
        UPSERT_TEMPLATE_COLLECTION_MUTATE,
        {
            update(cache, { data }) {
                const cacheCollection = cache.readQuery<GetCollectionData, GetCollectionVars>({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: params.id as string
                    }
                });
                if (data?.upsertTemplateCollection && cacheCollection?.getCollection) {
                    cache.writeQuery<GetCollectionData, GetCollectionVars>({
                        query: GET_COLLECTION_QUERY,
                        variables: {
                            id: params.id as string
                        },
                        data: {
                            getCollection: {
                                ...cacheCollection.getCollection,
                                template: data.upsertTemplateCollection.template
                            }
                        }
                    });
                }
            },
            onCompleted(data) {
                enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
            },
            onError(error) {
                enqueueSnackbar(error.message, {
                    variant: 'error'
                })
            }
        }
    );

    const [
        deleteItem,
        {loading: mutationDeleteItemLoading}
    ] = useMutation<DeleteItemData, DeleteItemVars>(
        DELETE_ITEM_MUTATE,
        {
            update(cache, { data }) {
                const cacheCollection = cache.readQuery<GetCollectionData, GetCollectionVars>({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: params.id as string
                    }
                });
                const cacheCollections = apolloClient.readQuery<GetCollectionsData, GetCollectionsVars>({
                    query: GET_COLLECTIONS_QUERY
                });
                if (data?.deleteItem) {
                    if (cacheCollection?.getCollection && cacheCollection.getCollection.itemsBySearch) {
                        cache.writeQuery<GetCollectionData, GetCollectionVars>({
                            query: GET_COLLECTION_QUERY,
                            variables: {
                                id: params.id as string
                            },
                            data: {
                                getCollection: {
                                    ...cacheCollection.getCollection,
                                    itemsBySearch: cacheCollection.getCollection.itemsBySearch.filter((item) => item.id !== data.deleteItem.id)
                                }
                            }
                        });
                    }
                    if (cacheCollections?.getCollections) {
                        cache.writeQuery<GetCollectionsData, GetCollectionsVars>({
                            query: GET_COLLECTIONS_QUERY,
                            data: { getCollections:
                                (cacheCollections.getCollections).map((elem) => {
                                    if (elem.id === params.id as string) {
                                        return {
                                            ...elem,
                                            totalItems: ((elem.totalItems || 0) > 0) ? (elem.totalItems || 0) - 1 : 0
                                        }
                                    }
                                    return elem;
                                })
                            }
                        });
                    }
                }
            },
            onCompleted(data) {
                enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
                setDialogOpen({
                    ...dialogOpen,
                    delete: false
                });
            },
            onError(error) {
                enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    const {loading: queryLoading, error: queryError, fetchMore: queryFetchMore, data: queryData} = useQuery<GetCollectionData, GetCollectionVars>(
        GET_COLLECTION_QUERY,
        {
            variables: {
                id: params.id as string
            }
        }
    );

    const [
        createMultiple,
        {loading: mutationCreateMultipleLoading}
    ] = useMutation<CreateMultipleItemData, CreateMultipleItemVars>(
        CREATE_MULTIPLE_ITEM_MUTATE,
        {
            update(cache, { data }) {
                queryFetchMore({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: params.id as string,
                        page: currentPage,
                        rowsPerPage: currentRowsPerPage
                    },
                    updateQuery: (_: any, { fetchMoreResult }: any) => {
                        return fetchMoreResult;
                    }
                });
            },
            onCompleted(data) {
                enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
                setDialogOpen({
                    ...dialogOpen,
                    createMultiple: false
                });
            },
            onError(error) {
                enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    /*eslint-disable */
    useEffect(() => {
        if ((filterResult[0].type).trim() || (filterResult[0].value).trim() || (filterResult[1].type).trim()) {
            const timeout = setTimeout(() => {
                queryFetchMore({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: params.id as string,
                        infos: filterResult,
                        page: currentPage,
                        rowsPerPage: currentRowsPerPage
                    },
                    updateQuery: (_: any, { fetchMoreResult }: any) => {
                        return fetchMoreResult;
                    }
                });
            }, 1000);
            return () => clearTimeout(timeout);
        } else {
            queryFetchMore({
                query: GET_COLLECTION_QUERY,
                variables: {
                    id: params.id as string,
                    page: currentPage,
                    rowsPerPage: currentRowsPerPage
                },
                updateQuery: (_: any, { fetchMoreResult }: any) => {
                    return fetchMoreResult;
                }
            });
        }
    }, [filterResult, currentPage, currentRowsPerPage])

    useEffect(() => {
        if (queryData && queryData.getCollection) {
            setCollectionName(queryData.getCollection.name);
        }
    }, [queryData]);

    const [queryMessage, setQueryMessage] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (queryLoading) {
            setQueryMessage(undefined);
        } else if (queryError) {
            console.log(`Error!: ${queryError}`);
            setQueryMessage(undefined);
        } else if (queryData && !queryData.getCollection) {
            setQueryMessage('Les données n\'ont pas pus être récupérés');
        } else if (queryData && queryData.getCollection && !queryData.getCollection.itemsBySearch) {
            setQueryMessage('Les objets n\'ont pas pus être récupérés');
        } else if (queryData && queryData.getCollection && queryData.getCollection.itemsBySearch && queryData.getCollection.itemsBySearch.length === 0) {
            setQueryMessage(
                <div>
                    Il n'y a pas d'objet,
                    <LinkCustom
                        href='#'
                        onClick={(e: React.SyntheticEvent) => {
                            e.preventDefault();
                            navigate(`/collections/${params.id as string}/add`)
                        }}
                        color="inherit">
                        ajoutez un objet dès maintenant
                    </LinkCustom>
                </div>
            );
        } else {
            setQueryMessage(undefined);
        }
    }, [queryData, queryLoading, queryError]);

    useEffect(() => {
        if (queryData && queryData.getCollection && queryData.getCollection.template && queryData.getCollection.template.length) {
            setTemplateLocal(queryData.getCollection.template);
        }
    }, [queryData]);

    const deleteFieldInItems: any = (index: number) => {
        setTemplateLocal(templateLocal.filter((val: string, i: number) => {
                return index !== i;
            })
        );
    };
    /*eslint-enable */

    const tabAreAdd = (
        <TabAppBar elevation={0} position="relative">
            <TabList
                indicatorColor="secondary"
                textColor="secondary"
                onChange={(evt: React.ChangeEvent<{}>, value: string) => {
                    if (value === 'add') {
                        navigate(`/collections/${params.id as string}/add`);
                    } else {
                        setTabValue(value);
                    }
                }}
                aria-label="Sous menu objets">
                <TabCustom
                    label="Ajouter"
                    value="add"
                    icon={<AddIcon/>}/>
                <TabCustom
                    label="objets"
                    value="1"
                    icon={<ViewModuleIcon/>}/>
                <TabCustom
                    label="Modèle"
                    value="2"
                    icon={<ListAltIcon/>}/>
            </TabList>
        </TabAppBar>
    );

    const tabArea = (
        <TabAppBar elevation={0} position="relative">
            <TabList
                indicatorColor="secondary"
                textColor="secondary"
                onChange={(evt: React.ChangeEvent<{}>, value: string) => {
                    if (value === 'add') {
                        navigate(`/collections/${params.id as string}/add`);
                    } else {
                        setTabValue(value);
                    }
                }}
                aria-label="Sous menu objets">
                <TabCustom
                    label="objets"
                    value="1"
                    icon={<ViewModuleIcon/>}/>
                <TabCustom
                    label="Modèle"
                    value="2"
                    icon={<ListAltIcon/>}/>
            </TabList>
        </TabAppBar>
    );

    return (
        <ItemsArea
            id="back-to-top-anchor"
            ref={node => {
                if (node) {
                    setScrollTarget(node);
                }
            }}>
            <BreadcrumbsApp
                links={links}
                lastElem={collectionName}/>
            <FilterQuery
                filterSelectContent={filterSelectContent}
                filterSelectOrder={filterSelectOrder}
                filterResult={filterResult}
                setFilterResult={setFilterResult}
                setTypeDisplay={setTypeDisplay}
                typeDisplay={typeDisplay}
                addHandler={() => navigate(`/collections/${params.id as string}/add`)}
                totalResult={queryData?.getCollection?.totalItems || 0}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentRowsPerPage={currentRowsPerPage}
                setCurrentRowsPerPage={setCurrentRowsPerPage}
                addMultipleHandler={() => {
                    setDialogOpen({
                        ...dialogOpen,
                        createMultiple: true
                    });
                }}
                resetFilter={() => {
                    setFilterResult([
                        {
                            type: '',
                            value: ''
                        },
                        {
                            ...filterResult[1]
                        }
                    ]);
                }}
                resetOrder={() => {
                    setFilterResult([
                        {
                            ...filterResult[0]
                        },
                        {
                            type: '',
                            value: ''
                        }
                    ]);
                }}/>
            <TabArea>
                <TabContext value={tabValue}>
                    {(typeDisplay === 'line' || isMobile) ? tabAreAdd : tabArea}
                    <TabPanel value="1">
                        {queryMessage && (<Message variant='filled' severity='info'>
                            {queryMessage}
                        </Message>)}
                        {(typeDisplay === 'block' && !isMobile) && <Masonry
                            enableResizableChildren={true}>
                            {!queryMessage && queryData && queryData.getCollection && queryData.getCollection.itemsBySearch && queryData.getCollection.itemsBySearch.map((item) => (
                                <Aux key={item.id}>
                                    <ListItem
                                        name={item.name || ''}
                                        status={item.status || Status.Possessed}
                                        description={item.description || ''}
                                        tags={item.tags || undefined}
                                        hasAddInput={item.hasAddInput}
                                        addInput={item.addInput || []}
                                        image={item.thumbnailUri || ''}
                                        createdDate={() => getDateFromNow(item.createdAt)}
                                        editContent={() => navigate(`/collections/${params.id as string}/${item.id}`)}
                                        openDeleteDialog={() => {
                                            setDialogOpen({
                                                ...dialogOpen,
                                                delete: true
                                            });
                                            setSelectedItem({
                                                id: item.id,
                                                name: item.name,
                                                description: item.description || ''
                                            });
                                        }}
                                        duplicateContent={() => duplicateItem({
                                            variables: {
                                                collectionId: params.id as string,
                                                id: item.id
                                            }
                                        })}/>
                                </Aux>
                            ))}
                            {queryLoading && (
                                <SkeletonListItem/>
                            )}
                        </Masonry>}
                        {(typeDisplay === 'line' || isMobile) && <LineList>
                            {!queryMessage && queryData && queryData.getCollection && queryData.getCollection.itemsBySearch && queryData.getCollection.itemsBySearch.map((item: any) => (
                                <Aux key={item.id}>
                                    <ListLineItem
                                        id={item.id}
                                        name={item.name}
                                        description={item.description || ''}
                                        tags={item.tags || undefined}
                                        hasAddInput={item.hasAddInput}
                                        addInput={item.addInput || []}
                                        image={item.thumbnailUri || ''}
                                        listLineItemExpanded={listLineItemExpanded}
                                        handleListLineItemExpanded={handleListLineItemExpanded}
                                        createdDate={() => getDateFromNow(item.createdAt)}
                                        editContent={() => navigate(`/collections/${params.id as string}/${item.id}`)}
                                        openDeleteDialog={() => {
                                            setDialogOpen({
                                                ...dialogOpen,
                                                delete: true
                                            });
                                            setSelectedItem({
                                                id: item.id,
                                                name: item.name,
                                                description: item.description
                                            });
                                        }}
                                        duplicateContent={() => duplicateItem({
                                            variables: {
                                                collectionId: params.id as string,
                                                id: item.id
                                            }
                                        })}/>
                                </Aux>
                            ))}
                        </LineList>}
                        <PaginationArea>
                            <Stack>
                                <Pagination
                                    variant="outlined"
                                    color="primary"
                                    onChange={(event, page) => {
                                        setCurrentPage((page - 1));
                                    }}
                                    shape="rounded"
                                    page={currentPage + 1}
                                    count={Math.ceil(((queryData?.getCollection?.totalItems || 0) / currentRowsPerPage))}/>
                            </Stack>
                        </PaginationArea>
                    </TabPanel>
                    <TabPanel value="2">
                        <Template
                            templateLocal={templateLocal}
                            setTemplateLocal={setTemplateLocal}
                            deleteFieldInItems={deleteFieldInItems}
                            upsertTemplateCollection={() => {
                                upsertTemplateCollection({
                                    variables: {
                                        id: params.id as string,
                                        data: {
                                            template: templateLocal.map((data: any) => {
                                                return {
                                                    label: data.label,
                                                    type: data.type
                                                }
                                            })
                                        }
                                    }
                                })
                            }}
                            upsertLoading={mutationUpsertTemplateCollectionLoading}/>
                    </TabPanel>
                </TabContext>
            </TabArea>
            <CollectionTemplateDialog
                open={dialogOpen.template}
                onCloseHandle={() => {
                    setDialogOpen({
                        ...dialogOpen,
                        template: false
                    });
                }}
                template={queryData ? queryData.getCollection.template : []}
                collectionId={params.id as string}
                upsertTemplateCollection={upsertTemplateCollection}
                upsertLoading={mutationUpsertTemplateCollectionLoading}/>
            <ItemDeleteDialog
                isOpen={dialogOpen.delete}
                onCloseHandle={() => setDialogOpen({
                    ...dialogOpen,
                    delete: false
                })}
                deleteItem={deleteItem}
                deleteLoading={mutationDeleteItemLoading}
                item={selectedItem}
                collectionId={params.id as string}/>
            <ItemAddMultipleDialog
                isOpen={dialogOpen.createMultiple}
                onCloseHandle={() => setDialogOpen({
                    ...dialogOpen,
                    createMultiple: false
                })}
                addMultipleItem={createMultiple}
                addMultipleLoading={mutationCreateMultipleLoading}
                collectionId={params.id as string}/>
            {scrollTarget && <BackTop element={scrollTarget}>
                <Fab color="secondary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </BackTop>}
        </ItemsArea>
    );
};

const HomeIconCustom = styled(HomeIcon)(
    ({theme}) => ({
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }),
);

const LinkCustom = styled(Link)(
    ({theme}) => ({
        marginLeft: theme.spacing(0.5),
    }),
);

const TabAppBar = styled(AppBar)(
    ({theme}) => ({
        backgroundColor: 'white',
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }),
);

const TabCustom = styled(Tab)(
    ({theme}) => ({
        borderRight: '1px solid white',
        '&:hover': {
            color: theme.custom.iconItem.color,
        },
    }),
);

const ItemsArea = styled('div')(
    () => ({
        height: 'calc(100vh - 128px)',
        overflowY: 'auto',
        overflowX: 'hidden',
    }),
);

const TabArea = styled('div')(
    () => ({
        '& > div': {
            padding: '0',
        },
    }),
);

const Message = styled(Alert)(
    ({theme}) => ({
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }),
);

const LineList = styled('div')(
    () => ({

    }),
);

const PaginationArea = styled('div')(
    ({theme}) => ({
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(2),
        width: 'fit-content',
    }),
);

export default Items;