import React from 'react';
import {
    Dialog,
    DialogTitle,
    Slide,
    Toolbar,
    IconButton,
    Typography,
    AppBar,
    colors,
    styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
    Close as CloseIcon
} from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

import CollectionUpsert from '../Forms/CollectionUpsert';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface CollectionUpsertDialogProps {
    readonly isOpen: boolean;
    readonly upsertLoading: boolean;
    readonly collection?: any;
    onCloseHandle: () => void;
    upsertCollection: (values: any) => void;
}

const CollectionUpsertDialog: any = (props: CollectionUpsertDialogProps) => {
    const {
        isOpen,
        onCloseHandle,
        upsertCollection,
        upsertLoading,
        collection
    } = props;

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseHandle}
            fullScreen={isMobile}
            TransitionComponent={isMobile ? Transition : undefined}
            aria-labelledby="form-dialog-collection-upsert-title">
            {!isMobile && <DialogTitleCustom
                id="form-dialog-collection-upsert-title">
                {collection
                    ? 'Modification d\'une collection'
                    : 'Création d\'une collection'}
            </DialogTitleCustom>}
            {isMobile && <AppBarCustom>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onCloseHandle}
                        aria-label="Close"
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Title variant="h6">
                        {collection
                            ? 'Modification d\'une collection'
                            : 'Création d\'une collection'}
                    </Title>
                </Toolbar>
            </AppBarCustom>}
            <UpsertArea>
                <CollectionUpsert
                    onCloseHandle={onCloseHandle}
                    upsertCollection={upsertCollection}
                    upsertLoading={upsertLoading}
                    collection={collection}/>
            </UpsertArea>
        </Dialog>
    );
};

const DialogTitleCustom = styled(DialogTitle)(
    () => ({
        backgroundColor: colors.grey[50],
    }),
);

const AppBarCustom = styled(AppBar)(
    () => ({
        position: 'relative',
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(2),
        flex: 1,
    }),
);

const UpsertArea = styled('div')(
    () => ({
        backgroundColor: colors.grey[50],
        height: '100%'
    }),
);

export default CollectionUpsertDialog;