import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Typography,
    IconButton,
    Divider,
    Button,
    Grid,
    Avatar,
    Chip,
    styled
} from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    Edit,
    Delete,
    MoreVert as MoreVertIcon,
    LocalOffer,
    FileCopy
} from '@mui/icons-material';

import MenuList from '../Menu/MenuList';
import Image from './Image';
import Aux from '../../hoc/Auxi';

interface ListLineItemProps {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly image: string;
    readonly tags?: any[] | undefined;
    readonly hasAddInput: boolean;
    readonly addInput: any[];
    readonly listLineItemExpanded: string | false;
    handleListLineItemExpanded: (value: string) => any;
    createdDate: () => string;
    openDeleteDialog?: () => void;
    editContent: () => void;
    duplicateContent?: () => void;
}

const ListLineItem: any = (props: ListLineItemProps) => {
    const {
        id,
        name,
        description,
        image,
        tags,
        addInput,
        listLineItemExpanded,
        handleListLineItemExpanded,
        createdDate,
        openDeleteDialog,
        editContent,
        duplicateContent
    } = props;

    const [anchorEl, setAnchorEl] = useState(null),
        [open, setOpen] = useState(false);

    const [imageSrc, setImageSrc] = useState<React.ReactNode | undefined>(undefined);

    const menuListId: string = 'menu-item-list';

    const menuContent: any[] = [
        {
            label: 'Modifier',
            onClick: editContent,
            icon: (<Edit/>),
        },
        {
            label: 'Dupliquer',
            onClick: duplicateContent,
            icon: (<FileCopy/>),
        },
        {
            label: 'Supprimer',
            onClick: openDeleteDialog,
            icon: (<Delete/>),
        }
    ];

    useEffect(() => {
        setImageSrc(
            <Image name={name} image={image} isItem/>
        );
    }, [image, name])

    useEffect(() => {
        setOpen(Boolean(anchorEl));
    }, [anchorEl]);

    const menu: any = (
        <Aux>
            <IconButton
                aria-label="More"
                aria-controls={menuListId}
                aria-haspopup="true"
                onClick={event => {
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget as any)
                }}
                size="large">
                <MoreVertIcon/>
            </IconButton>
        </Aux>
    );

    return (
        <AccordionCustom
            expanded={listLineItemExpanded === `item-${id}`}
            onChange={handleListLineItemExpanded(`item-${id}`)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`item-${id}`}
                id={`item-${id}`}>
                <AvatarCustom
                    variant='rounded'
                    onClick={event => {
                        event.stopPropagation();
                    }}>
                    <LocalOffer/>
                </AvatarCustom>
                {(openDeleteDialog && duplicateContent) ? menu : null}
                <Name>{name}</Name>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <ImageItem onClick={editContent}>
                            {imageSrc}
                        </ImageItem>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={9} xl={9}>
                        <Typography
                            variant="caption"
                            color="textSecondary">
                            {createdDate()}
                        </Typography>
                        {description && <ElemArea>
                            <ElemLabel
                                color="secondary"
                                display="inline">
                                Description :
                            </ElemLabel>
                            <ElemInfo
                                color="textSecondary"
                                display="inline">
                                {description}
                            </ElemInfo>
                        </ElemArea>}
                        {(tags && tags.length) ? (<ElemArea>
                            <ElemLabel
                                color="secondary"
                                display="inline">
                                Tags :
                            </ElemLabel>
                            <TagArea
                                display="inline"
                                component="div">
                                {tags.map((tag: any, index: number) => (
                                    <TagInfo
                                        key={index}
                                        variant={'outlined'}
                                        label={tag.name || 'ko'}
                                        size="small"/>
                                ))}
                            </TagArea>
                            {addInput && addInput.map((inputElem: any, index: number) => (
                                <ElemArea key={index}>
                                    <ElemLabel
                                        color="secondary"
                                        display="inline">
                                        {inputElem.label} :
                                    </ElemLabel>
                                    <ElemInfo
                                        color="textSecondary"
                                        display="inline">
                                        {inputElem.value}
                                    </ElemInfo>
                                </ElemArea>
                            ))}
                        </ElemArea>) : null}
                    </Grid>
                </Grid>
            </AccordionDetails>
            <Divider/>
            <AccordionActions>
                <ButtonCustom
                    color="primary"
                    variant="outlined"
                    onClick={editContent}>
                    Voir
                </ButtonCustom>
            </AccordionActions>
            { open && openDeleteDialog && duplicateContent && <MenuList
                idMenu={menuListId}
                anchorEl={anchorEl}
                open={open}
                onClose={() => {setAnchorEl(null)}}
                menuContent={menuContent}
                isItem/>}
        </AccordionCustom>
    );
}

const AccordionCustom = styled(Accordion)(
    ({theme}) => ({
        backgroundColor: theme.custom.cardItem.backgroundColor
    }),
);

const AvatarCustom = styled(Avatar)(
    ({theme}) => ({
        color: theme.custom.avatarItem.color,
        backgroundColor: theme.custom.avatarItem.backgroundColor,
    }),
);

const Name = styled(Typography)(
    ({theme}) => ({
        marginTop: theme.spacing(1.5),
    }),
);

const ImageItem = styled('div')(
    () => ({
        cursor: 'pointer',
    }),
);

const ElemArea = styled('div')(
    ({theme}) => ({
        marginTop: theme.spacing(1),
    }),
);

const ElemLabel = styled(Typography)(
    () => ({
        fontWeight: 'bold',
    }),
);

const ElemInfo = styled(Typography)(
    ({theme}) => ({
        paddingLeft: theme.spacing(1),
    }),
);

const TagArea: any = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(1),
    }),
);

const TagInfo = styled(Chip)(
    ({theme}) => ({
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    }),
);

const ButtonCustom = styled(Button)(
    () => ({
        marginRight: 'auto',
    }),
);

export default ListLineItem;