import React, { useEffect, useState } from 'react';
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    styled
} from '@mui/material';

import { TypeSubscribe, PriceSubscribe } from './Pricing';

enum DisplayLongType {
    MONTHLY = 'Mensuel',
    YEARLY = 'Annuel'
}

interface SummaryProps {
    readonly type: TypeSubscribe;
}

const Summary: any = (props: SummaryProps) => {
    const {
        type
    } = props;
    const [total, setTotal] = useState(0.00);

    useEffect(() => {
        if (type === TypeSubscribe.MONTHLY) {
            setTotal(PriceSubscribe.MONTHLY);
        } else {
            setTotal(PriceSubscribe.YEARLY);
        }
    }, [type]);

    return (
        <div>
            <Typography variant='h6' gutterBottom>
                Récapitulatif
            </Typography>
            <List disablePadding>
                <ListItemCustom>
                    <ListItemText primary='Abonnement' secondary={(type === TypeSubscribe.MONTHLY) ? DisplayLongType.MONTHLY : DisplayLongType.YEARLY}/>
                    <Typography variant='body2'>
                        {total.toFixed(2)} €
                    </Typography>
                </ListItemCustom>
                <ListItemCustom>
                    <ListItemText primary="Total" />
                    <Total variant="subtitle1">
                        {total.toFixed(2)} €
                    </Total>
                </ListItemCustom>
            </List>
        </div>
    );
};

const ListItemCustom = styled(ListItem)(
    ({theme}) => ({
        padding: theme.spacing(1, 0),
    }),
);

const Total = styled(Typography)(
    () => ({
        fontWeight: 700,
    }),
);

export default Summary;