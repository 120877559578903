import { gql } from '@apollo/client';

export interface AccountByIdVars {
    id?: string;
}

export interface SubscriptionData {
    currentPeriodStart?: Date;
    currentPeriodEnd?: Date;
    upcomingPeriodStart?: Date;
    upcomingPeriodEnd?: Date;
    upcomingTotal?: number;
    label?: string;
    isActive?: boolean;
}

export interface Address {
    street: string;
    zipCode: string;
    city: string;
    country: string;
}

export interface AccountByIdData {
    accountById: {
        id: string;
        firstName?: string;
        lastName?: string;
        dateBirth?: string;
        address?: Address;
        subscriptionData?: SubscriptionData;
    };
}

export const ACCOUNT_BY_ID_QUERY = gql`
    query AccountById($id: ID) {
        accountById(id: $id) {
            id
            firstName
            lastName
            dateBirth
            address {
                street
                zipCode
                city
                country
            }
            subscriptionData {
                currentPeriodStart
                currentPeriodEnd
                upcomingPeriodStart
                upcomingPeriodEnd
                upcomingTotal
                label
                isActive
            }
        }
    }
`;

export interface UpdateAccountInput {
    firstName: string;
    lastName: string;
    dateBirth: string;
    address: Address;
}

export interface UpdateAccountVars {
    id?: string;
    data: UpdateAccountInput;
}

export interface UpdateAccountData {
    updateAccount: {
        id: string;
        firstName?: string;
        lastName?: string;
        dateBirth?: string;
        address?: Address;
        subscriptionData?: SubscriptionData;
    };
}

export const UPDATE_ACCOUNT_MUTATION = gql`
    mutation UpdateAccount($id: ID, $data: UpdateAccountInput!) {
        updateAccount(id: $id, data: $data) {
            id
            firstName
            lastName
            dateBirth
            address {
                street
                zipCode
                city
                country
            }
            subscriptionData {
                currentPeriodStart
                currentPeriodEnd
                upcomingPeriodStart
                upcomingPeriodEnd
                upcomingTotal
                label
                isActive
            }
        }
    }
`;

export interface UnsubscribeVars {

}

export interface UnsubscribeData {
    unsubscribe: {
        id: string;
        firstName?: string;
        lastName?: string;
        dateBirth?: string;
        address?: Address;
        subscriptionData?: SubscriptionData;
    };
}

export const UNSUBSCRIBE_MUTATION = gql`
    mutation Unsubscribe {
        unsubscribe {
            firstName
            lastName
            dateBirth
            address {
                street
                zipCode
                city
                country
            }
            subscriptionData {
                currentPeriodStart
                currentPeriodEnd
                upcomingPeriodStart
                upcomingPeriodEnd
                upcomingTotal
                label
                isActive
            }
        }
    }
`;

export interface DeleteAccountVars {

}

export interface DeleteAccountData {
    deleteAccount: {
        id: string;
    };
}

export const DELETE_ACCOUNT_MUTATION = gql`
    mutation DeleteAccount {
        deleteAccount {
            id
        }
    }
`;