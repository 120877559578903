import React from 'react';
import {
    Grid,
    Paper,
    InputBase,
    Divider,
    IconButton,
    Select,
    MenuItem,
    styled
} from '@mui/material';
import {
    Clear
} from '@mui/icons-material';

interface SearchQueryProps {
    readonly searchSelect: any[];
    readonly searchInfo: any[];
    readonly isFocus?: boolean;
    setSearchInfo: (values: any) => void;
    resetSearch: () => void;
}

const SearchQuery: any = (props: SearchQueryProps) => {
    const {
        searchSelect,
        setSearchInfo,
        searchInfo,
        resetSearch,
        isFocus
    } = props;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PaperRoot>
                    <SelectCustom
                        value={searchInfo[0].type}
                        onChange={(e: any) => {
                            setSearchInfo(
                                searchInfo.map((elemSearch: any, index: number) => {
                                    if (index === 0) {
                                        return {
                                            ...elemSearch,
                                            type: e.target.value
                                        };
                                    }
                                    return elemSearch;
                                })
                            );
                        }}
                        input={<InputBase name="choice"/>}>
                        {searchSelect.map((elemSelect: any, index: number) => (
                            <MenuItem key={elemSelect.id} value={elemSelect.id}>{elemSelect.label}</MenuItem>
                        ))}
                    </SelectCustom>
                    <DividerCustom/>
                    <InputBaseCustom
                        value={searchInfo[0].value}
                        autoFocus={isFocus}
                        onChange={(e: any) => {
                            setSearchInfo(
                                searchInfo.map((elemSearch: any, index: number) => {
                                    if (index === 0) {
                                        return {
                                            ...elemSearch,
                                            value: e.target.value
                                        };
                                    }
                                    return elemSearch;
                                })
                            );
                        }}
                        placeholder="Tapez votre recherche"
                        inputProps={{ 'aria-label': 'find item' }}/>
                    <DividerCustom/>
                    <IconButtonCustom
                        onClick={resetSearch}
                        color="primary"
                        aria-label="add search"
                        size="large">
                        <Clear/>
                    </IconButtonCustom>
                </PaperRoot>
            </Grid>
        </Grid>
    );
};

const PaperRoot = styled(Paper)(
    () => ({
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#fff',
    }),
);

const SelectCustom = styled(Select)(
    () => ({
        marginLeft: 8,
    }),
);

const DividerCustom = styled(Divider)(
    () => ({
        width: 1,
        height: 28,
        margin: 4,
    }),
);

const InputBaseCustom = styled(InputBase)(
    () => ({
        marginLeft: 8,
        flex: 1,
    }),
);

const IconButtonCustom = styled(IconButton)(
    () => ({
        padding: 10,
    }),
);

export default SearchQuery;