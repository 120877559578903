import { Config } from '../../utils/config';

export interface Auth0Config {
    domain: string;
    clientId: string;
    callbackUrl: string;
    redirectLogout: string;
    roleUrl: string;
    audience: string;
}

export const AUTH_CONFIG: Auth0Config = {
    domain: Config.auth0Domain,
    clientId: Config.auth0ClientId,
    callbackUrl: Config.auth0CallbackUrl,
    redirectLogout: Config.auth0RedirectLogout,
    roleUrl: Config.auth0RoleUrl,
    audience: Config.auth0Audience
};