import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Typography,
    IconButton,
    Divider,
    Button,
    Grid,
    Avatar,
    styled
} from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    Visibility,
    Edit,
    ListAlt as ListAltIcon,
    Delete,
    MoreVert as MoreVertIcon,
    LocalOffer,
    Style
} from '@mui/icons-material';

import MenuList from '../Menu/MenuList';
import Image from './Image';

interface ListLineProps {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly listLineExpanded: string | false;
    readonly image: string;
    readonly totalItems: string;
    handleListLineExpanded: (value: string) => any;
    createdDate: () => string;
    openUpdateDialog?: () => void;
    openDeleteDialog?: () => void;
    openTemplateDialog?: () => void;
    showContent: () => void;
}

const ListLine: any = (props: ListLineProps) => {
    const {
        id,
        name,
        description,
        listLineExpanded,
        image,
        totalItems,
        handleListLineExpanded,
        createdDate,
        openUpdateDialog,
        openDeleteDialog,
        openTemplateDialog,
        showContent
    } = props;

    const [anchorEl, setAnchorEl] = useState(null),
        [open, setOpen] = useState(false);

    const [imageSrc, setImageSrc] = useState<React.ReactNode | undefined>(undefined);

    useEffect(() => {
        setImageSrc(
            <Image name={name} image={image}/>
        );
    }, [image, name])

    const menuListId: string = 'menu-collection-list';

    const menuContent: any[] = [
        {
            label: 'Voir',
            onClick: showContent,
            icon: (<Visibility/>),
        },
        {
            label: 'Modifier',
            onClick: openUpdateDialog,
            icon: (<Edit/>),
        },
        {
            label: 'Modèle',
            onClick: openTemplateDialog,
            icon: (<ListAltIcon/>),
            isPremium: true,
        },
        {
            label: 'Supprimer',
            onClick: openDeleteDialog,
            icon: (<Delete/>),
        },
    ];

    useEffect(() => {
        setOpen(Boolean(anchorEl));
    }, [anchorEl]);

    const menu = (
        <IconMenu
            aria-label="More"
            aria-controls={menuListId}
            aria-haspopup="true"
            onClick={event => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget as any);
            }}
            size="large">
            <MoreVertIcon/>
        </IconMenu>
    );

    return (
        <Accordion
            expanded={listLineExpanded === `collection-${id}`}
            onChange={handleListLineExpanded(`collection-${id}`)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`collection-${id}`}
                id={`collection-${id}`}>
                <AvatarCustom
                    variant='rounded'
                    onClick={event => {
                        event.stopPropagation();
                    }}>
                    <Style/>
                </AvatarCustom>
                {(openUpdateDialog && openDeleteDialog && openTemplateDialog) ? menu : null}
                <Name>{name}</Name>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <ImageCollection onClick={showContent}>
                            {imageSrc}
                        </ImageCollection>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={9} xl={9}>
                        <Typography
                            variant="caption"
                            color="textSecondary">
                            {createdDate()}
                        </Typography>
                        {description && <ElemArea>
                            <ElemLabel
                                color="secondary"
                                display="inline">
                                Description :
                            </ElemLabel>
                            <ElemInfo
                                color="textSecondary"
                                display="inline">
                                {description}
                            </ElemInfo>
                        </ElemArea>}
                    </Grid>
                </Grid>
            </AccordionDetails>
            <Divider/>
            <AccordionActions>
                <AvatarIcon variant='rounded'>
                    <LocalOffer /> {totalItems}
                </AvatarIcon>
                <ButtonCustom
                    color="primary"
                    variant="outlined"
                    onClick={showContent}>
                    Voir
                </ButtonCustom>
            </AccordionActions>
            {open && openUpdateDialog && openDeleteDialog && openTemplateDialog && <MenuList
                idMenu={menuListId}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                menuContent={menuContent}/>}
        </Accordion>
    );
}

const IconMenu = styled(IconButton)(
    ({theme}) => ({
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }),
);

const AvatarCustom = styled(Avatar)(
    ({theme}) => ({
        color: theme.custom.avatarCollection.color,
        backgroundColor: theme.custom.avatarCollection.backgroundColor,
    }),
);

const Name = styled(Typography)(
    ({theme}) => ({
        marginTop: theme.spacing(1.5),
    }),
);

const ImageCollection = styled('div')(
    () => ({
        cursor: 'pointer',
    }),
);

const ElemArea = styled('div')(
    ({theme}) => ({
        marginTop: theme.spacing(1),
    }),
);

const ElemLabel = styled(Typography)(
    () => ({
        fontWeight: 'bold',
    }),
);

const ElemInfo = styled(Typography)(
    ({theme}) => ({
        paddingLeft: theme.spacing(1),
    }),
);

const AvatarIcon = styled(Avatar)(
    ({theme}) => ({
        width: 'fit-content',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: theme.custom.avatarCollection.color,
        backgroundColor: theme.custom.avatarCollection.backgroundColor,
    }),
);

const ButtonCustom = styled(Button)(
    () => ({
        margin: 'auto',
    }),
);

export default ListLine;