import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stepper,
    Step,
    StepLabel,
    Hidden,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    colors,
    styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
    Close as CloseIcon
} from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useStripe } from '@stripe/react-stripe-js';
import { isMobile } from 'react-device-detect';

import AccountData from '../Subscribe/AccountData';
import Summary from '../Subscribe/Summary';
import Pricing, { TypeSubscribe } from '../Subscribe/Pricing';
import {
    ACCOUNT_BY_ID_QUERY, AccountByIdData, AccountByIdVars,
    SUBSCRIBE_MUTATION, SubscribeData, SubscribeVars
} from './requests/SubscribeDialog';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface SubscribeDialogProps {
    readonly isOpen: boolean;
    readonly account: any;
    onCloseHandle: () => void;
}

const SubscribeDialog: any = (props: SubscribeDialogProps) => {
    const {
        isOpen,
        account,
        onCloseHandle
    } = props;

    const stripe = useStripe();
    const snackbar = useSnackbar();

    const [stepTitle, setStepTitle] = useState<string[]>([]);
    const [activeStep, setActiveStep] = useState(0);
    const [typeSubscribe, setTypeSubscribe] = useState<TypeSubscribe>(TypeSubscribe.YEARLY);

    const [accountSubscribe, setAccountSubscribe] = useState({
        firstName: '',
        lastName: '',
        dateBirth: '',
        address: {
            street: '',
            zipCode: '',
            city: '',
            country: ''
        }
    });

    const [
        subscribe,
    ] = useMutation<SubscribeData, SubscribeVars>(
        SUBSCRIBE_MUTATION,
        {
            update(cache, { data }) {
                if (data?.subscribe && data.subscribe.account) {
                    cache.writeQuery<AccountByIdData, AccountByIdVars>({
                        query: ACCOUNT_BY_ID_QUERY,
                        data: { accountById: data.subscribe.account }
                    });
                }
            },
            onCompleted(data) {
                if (data.subscribe.idSession) {
                    stripe?.redirectToCheckout({
                        sessionId: data.subscribe.idSession
                    });
                }
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                });
                onCloseHandle();
            }
        }
    );

    useEffect(() => {
        if (account) {
            setAccountSubscribe(account);
        }
    }, [account]);

    useEffect(() => {
        setStepTitle([
            `Type d'abonnement`,
            'Information du compte',
            'Récapitulatif'
        ]);
    }, []);

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseHandle}
            aria-labelledby="choice-dialog-subscribe-title"
            fullScreen={isMobile}
            TransitionComponent={isMobile ? Transition : undefined}
            fullWidth={!isMobile}
            maxWidth={isMobile ? undefined : 'md'}>
            {!isMobile && <DialogTitleCustom
                id="choice-dialog-subscribe-title">
                Démarrer votre abonnement
            </DialogTitleCustom>}
            {isMobile && <AppBarCustom>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onCloseHandle}
                        aria-label="Close"
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Title variant="h6">
                        Démarrer votre abonnement
                    </Title>
                </Toolbar>
            </AppBarCustom>}
            <SubscribeArea>
                <DialogContent>
                    <Hidden only='xs'>
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel>
                            {stepTitle.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Hidden>
                    <div>
                        {(activeStep === 0) ? (
                            <Pricing
                                type={typeSubscribe}
                                setType={setTypeSubscribe}
                                nextStep={() => setActiveStep(activeStep + 1)}/>
                        ) : (activeStep === 1) ? (
                            <AccountData
                                accountSubscribe={accountSubscribe}
                                setAccountSubscribe={setAccountSubscribe}
                                nextStep={() => setActiveStep(activeStep + 1)}/>
                        ) : (
                            <Summary
                                type={typeSubscribe}/>
                        )}
                    </div>
                </DialogContent>
                <DialogActionsCustom>
                    <ButtonCustom
                        variant="outlined"
                        onClick={() => {
                            if (activeStep > 0) {
                                setActiveStep(activeStep - 1);
                            } else {
                                onCloseHandle();
                            }
                        }}>
                        {(activeStep > 0) ? 'Précédent' : 'Annuler'}
                    </ButtonCustom>
                    {(activeStep !== 0) && (<ButtonCustom
                        variant="outlined"
                        color="primary"
                        disabled={(
                            !accountSubscribe.firstName.trim() ||
                            !accountSubscribe.lastName.trim() ||
                            !accountSubscribe.dateBirth.trim() ||
                            !accountSubscribe.address.street.trim() ||
                            !accountSubscribe.address.zipCode.trim() ||
                            !accountSubscribe.address.city.trim() ||
                            !accountSubscribe.address.country.trim()) && (activeStep === 1)}
                        onClick={() => {
                            if (activeStep < (stepTitle.length - 1)) {
                                setActiveStep(activeStep + 1);
                            } else {
                                subscribe({
                                    variables: {
                                        type: typeSubscribe,
                                        data: {
                                            firstName: accountSubscribe.firstName ? accountSubscribe.firstName.trim() || '' : '',
                                            lastName: accountSubscribe.lastName ? accountSubscribe.lastName.trim() || '' : '',
                                            dateBirth: accountSubscribe.dateBirth ? accountSubscribe.dateBirth.trim() || '' : '',
                                            address: {
                                                street: (accountSubscribe.address && accountSubscribe.address.street) ? accountSubscribe.address.street.trim() || '' : '',
                                                zipCode: (accountSubscribe.address && accountSubscribe.address.zipCode) ? accountSubscribe.address.zipCode.trim() || '' : '',
                                                city: (accountSubscribe.address && accountSubscribe.address.city) ? accountSubscribe.address.city.trim() || '' : '',
                                                country: (accountSubscribe.address && accountSubscribe.address.country) ? accountSubscribe.address.country.trim() || '' : ''
                                            }
                                        }
                                    }
                                })
                            }
                        }}>
                        {(activeStep < stepTitle.length - 1) ? 'Suivant' : 'Valider'}
                    </ButtonCustom>)}
                </DialogActionsCustom>
            </SubscribeArea>
        </Dialog>
    );
};

const DialogTitleCustom = styled(DialogTitle)(
    () => ({
        backgroundColor: colors.grey[50],
    }),
);

const AppBarCustom = styled(AppBar)(
    () => ({
        position: 'relative',
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(2),
        flex: 1,
    }),
);

const SubscribeArea = styled('div')(
    () => ({
        backgroundColor: colors.grey[50],
        height: '100%',
    }),
);

const DialogActionsCustom = styled(DialogActions)(
    ({theme}) => ({
        width: '100%',
        margin: 0,
        padding: theme.spacing(2),
    }),
);

const ButtonCustom = styled(Button)(
    ({theme}) => ({
        marginRight: theme.spacing(1),
    }),
);

export default SubscribeDialog;