import React, { useState, useEffect } from 'react';
import {
    Dialog,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    CircularProgress,
    colors,
    styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
    Close as CloseIcon
} from '@mui/icons-material';

import Template from '../Collection/Template';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface CollectionTemplateDialogProps {
    readonly open: boolean;
    readonly template: any[];
    readonly collectionId: string;
    readonly upsertLoading: boolean;
    onCloseHandle: () => void;
    upsertTemplateCollection: (value: any) => void;
}

const CollectionTemplateDialog: any = (props: CollectionTemplateDialogProps) => {
    const {
        open,
        onCloseHandle,
        template,
        collectionId,
        upsertTemplateCollection,
        upsertLoading
    } = props;

    const [templateLocal, setTemplateLocal] = useState([] as any[]);

    useEffect(() => {
        setTemplateLocal(template);
    }, [template]);

    const deleteFieldInItems: any = (index: number) => {
        setTemplateLocal(templateLocal.filter((val: string, i: number) => {
                return index !== i;
            })
        );
    };

    return (
        <Dialog fullScreen open={open} onClose={onCloseHandle} TransitionComponent={Transition}>
            <AppBarCustom>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onCloseHandle}
                        aria-label="Close"
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Title variant="h6">
                        Edition du modèle
                    </Title>
                    <Wrapper>
                        <Button
                            color="inherit"
                            onClick={() => upsertTemplateCollection({
                                variables: {
                                    id: collectionId,
                                    data: {
                                        template: templateLocal.map((data: any) => {
                                            return {
                                                label: data.label,
                                                type: data.type
                                            }
                                        })
                                    }
                                }
                            })}>
                            Sauvegarder
                        </Button>
                        {upsertLoading && <ButtonProgress size={24}/>}
                    </Wrapper>
                </Toolbar>
            </AppBarCustom>
            <Template
                templateLocal={templateLocal}
                setTemplateLocal={setTemplateLocal}
                deleteFieldInItems={deleteFieldInItems}
            />
        </Dialog>
    );
};

const AppBarCustom = styled(AppBar)(
    () => ({
        position: 'relative',
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(2),
        flex: 1,
    }),
);

const Wrapper = styled('div')(
    () => ({
        position: 'relative',
    }),
);

const ButtonProgress = styled(CircularProgress)(
    () => ({
        color: colors.green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }),
);

export default CollectionTemplateDialog;