import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    MenuItem,
    styled,
    Select
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { Status } from '../../containers/collection/requests/Items';

interface StatusSelectorProps {
    readonly status: Status;
    updateStatus: (value: Status) => {};
}

const StatusSelector: any = (props: StatusSelectorProps) => {
    const {
        status,
        updateStatus
    } = props;

    return (
        <CardCustom>
            <CardContent>
                <Title
                    color="textSecondary"
                    gutterBottom>
                    Etat d'obtention de l'objet
                </Title>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Select
                            value={status || Status.Possessed}
                            onChange={(e: SelectChangeEvent) => {
                                updateStatus(e.target.value as Status);
                            }}
                            fullWidth>
                            <MenuItem value={Status.Possessed}>Obtenu</MenuItem>
                            <MenuItem value={Status.Double}>En double</MenuItem>
                            <MenuItem value={Status.Wanted}>Recherché</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </CardContent>
        </CardCustom>
    );
}

const CardCustom = styled(Card)<{isModal?: boolean}>(
    ({theme, isModal}) => ({
        margin: 'auto',
        [theme.breakpoints.up('xs')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: '300px',
        },
        backgroundColor: isModal ? 'white' : theme.custom.cardItem.backgroundColor,
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        fontSize: 14,
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    }),
);

export default StatusSelector;