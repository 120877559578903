import React, { useContext, useReducer } from 'react';

type ModalSubscribe = {
    isOpen: boolean;
}

type SubscribeState = {
    isActive: boolean;
}

type ModalSearch = {
    isOpen: boolean;
    search: string;
}

type ModalSupport = {
    isOpen: boolean;
}

export interface GlobalState {
    modalSubscribe: ModalSubscribe;
    subscribeState: SubscribeState;
    modalSearch: ModalSearch;
    modalSupport: ModalSupport;
    totalCollections: number;
}

interface UpdateModalSubscribeAction {
    type: 'updateModalSubscribe';
    isOpen: boolean;
}

interface UpdateSubscribeStateAction {
    type: 'updateSubscribeState';
    isActive: boolean;
}

interface UpdateModalSearchAction {
    type: 'updateModalSearch';
    isOpen: boolean;
    search: string;
}

interface UpdateModalSupportAction {
    type: 'updateModalSupport';
    isOpen: boolean;
}

interface UpdateTotalCollectionsAction {
    type: 'updateTotalCollections';
    totalCollections: number;
}

export type GlobalAction =
    | UpdateModalSubscribeAction
    | UpdateSubscribeStateAction
    | UpdateModalSearchAction
    | UpdateModalSupportAction
    | UpdateTotalCollectionsAction;

export type GlobalDispatch = React.Dispatch<GlobalAction>;

const StateContext = React.createContext<GlobalState | null>(null);
const DispatchContext = React.createContext<GlobalDispatch | null>(null);

const initialState: GlobalState = {
    modalSubscribe: {
        isOpen: false,
    },
    subscribeState: {
        isActive: false,
    },
    modalSearch: {
        isOpen: false,
        search: ''
    },
    modalSupport: {
        isOpen: false,
    },
    totalCollections: 0
};

function reducer(state: GlobalState, action: GlobalAction): GlobalState {
    switch (action.type) {
        case "updateModalSubscribe":
            return {
                ...state,
                modalSubscribe: {
                    ...state.modalSubscribe,
                    isOpen: action.isOpen,
                },
            };
        case "updateSubscribeState":
            return {
                ...state,
                subscribeState: {
                    ...state.subscribeState,
                    isActive: action.isActive,
                },
            };
        case "updateModalSearch":
            return {
                ...state,
                modalSearch: {
                    ...state.modalSearch,
                    isOpen: action.isOpen,
                    search: action.search,
                },
            };
        case "updateModalSupport":
            return {
                ...state,
                modalSupport: {
                    ...state.modalSupport,
                    isOpen: action.isOpen
                }
            };
        case "updateTotalCollections":
            return {
                ...state,
                totalCollections: action.totalCollections
            }
        default:
            return state;
    }
}

export function GlobalProvider({ children }: any) {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <DispatchContext.Provider value={dispatch}>
            <StateContext.Provider value={state}>
                {children}
            </StateContext.Provider>
        </DispatchContext.Provider>
    );
}

export function useGlobalDispatch() {
    return useContext(DispatchContext)!!;
}

export function useGlobalState() {
    return useContext(StateContext)!!;
}