import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    Fab,
    Link,
    Alert,
    AppBar,
    Tab,
    styled,
    Stack,
    Pagination
} from '@mui/material';
import {
    Add as AddIcon,
    Home as HomeIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon
} from '@mui/icons-material';
import {
    TabList,
    TabContext
} from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Masonry from 'react-masonry-component';
import { isMobile } from 'react-device-detect';

import CollectionUpsertDialog from '../../components/Dialogs/CollectionUpsertDialog';
import CollectionDeleteDialog from '../../components/Dialogs/CollectionDeleteDialog';
import List from '../../components/Collection/List';
import CollectionTemplateDialog from '../../components/Dialogs/CollectionTemplateDialog';
import Aux from '../../hoc/Auxi';
import BreadcrumbsApp from '../../components/Breadcrumbs/BreadcrumbsApp';
import { getDateFromNow } from '../../utils/date-transform';
import SkeletonList from '../../components/Collection/SkeletonList';
import { useGlobalDispatch, useGlobalState } from '../../shared/global-context';
import {
    GET_COLLECTIONS_QUERY, GetCollectionsData, GetCollectionsVars,
    GET_COLLECTION_QUERY, GetCollectionData, GetCollectionVars,
    CREATE_COLLECTION_MUTATE, CreateCollectionData, CreateCollectionVars,
    UPDATE_COLLECTION_MUTATE, UpdateCollectionData, UpdateCollectionVars,
    DELETE_COLLECTION_MUTATE, DeleteCollectionData, DeleteCollectionVars,
    UPSERT_TEMPLATE_COLLECTION_MUTATE, UpsertTemplateCollectionData, UpsertTemplateCollectionVars,
    InfoSearchInput
} from './requests/Collections';
import FilterQuery from '../../components/Forms/FilterQuery';
import ListLine from '../../components/Collection/ListLine';
import BackTop from '../../components/Backtop/BackTop';

interface CollectionsProps {
    window?: () => Window;
    children: React.ReactElement;
}

const Collections: any = (props: CollectionsProps) => {
    const navigate = useNavigate();
    const globalDispatch = useGlobalDispatch();
    const globalState = useGlobalState();
    const snackbar = useSnackbar();

    const filterSelectContent = [
        {
            label: 'Nom commence par',
            id: 'nameStart'
        },
        {
            label: 'Nom contient',
            id: 'nameContain'
        },
        {
            label: 'Nom se termine par',
            id: 'nameEnd'
        }
    ];

    const filterSelectOrder = [
        {
            label: 'A...Z',
            id: 'nameAsc'
        },
        {
            label: 'Z...A',
            id: 'nameDesc'
        },
        {
            label: 'Plus récents',
            id: 'createdDateAsc'
        },
        {
            label: 'Plus Anciens',
            id: 'createdDateDesc'
        }
    ];

    const [filterResult, setFilterResult] = useState<InfoSearchInput[]>([
        {
            type: '',
            value: ''
        },
        {
            type: '',
            value: ''
        }
    ]);

    const [dialogOpen, setDialogOpen] = useState({
        create: false,
        update: false,
        delete: false,
        template: false
    });

    const [selectedCollection, setSelectedCollection] = useState({
        id: '',
        name: '',
        description: '',
        template: []
    });

    const [currentPage, setCurrentPage] = useState(0);
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);

    const [typeDisplay, setTypeDisplay] = useState('block');

    const [scrollTarget, setScrollTarget] = useState<Node | undefined>(undefined);

    const links = [
        {
            label: <HomeIconCustom/>,
            onClick: () => navigate('/')
        }
    ];

    const [listLineExpanded, setListLineExpanded] = useState<string | false>(false);

    const handleListLineExpanded: any = (value: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setListLineExpanded(isExpanded ? value : false);
    };

    const [
        upsertTemplateCollection,
        {loading: mutationUpsertTemplateCollectionLoading}
    ] = useMutation<UpsertTemplateCollectionData, UpsertTemplateCollectionVars>(
        UPSERT_TEMPLATE_COLLECTION_MUTATE,
        {
            update(cache, { data }) {
                const cacheCollection = cache.readQuery<GetCollectionData, GetCollectionVars>({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: selectedCollection.id
                    }
                });
                if (data?.upsertTemplateCollection && cacheCollection?.getCollection) {
                    cache.writeQuery<GetCollectionData, GetCollectionVars>({
                        query: GET_COLLECTION_QUERY,
                        variables: {
                            id: selectedCollection.id
                        },
                        data: {
                            getCollection: {
                                ...cacheCollection.getCollection,
                                template: data.upsertTemplateCollection.template
                            }
                        }
                    });
                }
            },
            onCompleted(data) {
                snackbar.enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    const [
        createCollection,
        {loading: mutationCreateCollectionLoading}
    ] = useMutation<CreateCollectionData, CreateCollectionVars>(
        CREATE_COLLECTION_MUTATE,
        {
            update(cache, { data }) {
                const cacheCollections = cache.readQuery<GetCollectionsData, GetCollectionsVars>({
                    query: GET_COLLECTIONS_QUERY
                });
                if (data?.createCollection && cacheCollections?.getCollections) {
                    cache.writeQuery<GetCollectionsData, GetCollectionsVars>({
                        query: GET_COLLECTIONS_QUERY,
                        data: { getCollections: [...([data.createCollection]), ...cacheCollections.getCollections] }
                    });
                }
            },
            onCompleted(data) {
                snackbar.enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
                setDialogOpen({
                    ...dialogOpen,
                    create: false
                });
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    const [
        updateCollection,
        {loading: mutationUpdateCollectionLoading}
    ] = useMutation<UpdateCollectionData, UpdateCollectionVars>(
        UPDATE_COLLECTION_MUTATE,
        {
            update(cache, {data}) {
                const cacheCollections = cache.readQuery<GetCollectionsData, GetCollectionsVars>({
                    query: GET_COLLECTIONS_QUERY
                });
                const cacheCollection = cache.readQuery<GetCollectionData, GetCollectionVars>({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: selectedCollection.id
                    }
                });
                if (data?.updateCollection) {
                    if (cacheCollections?.getCollections && cacheCollections.getCollections.length) {
                        const getCollectionsResult = cacheCollections.getCollections.map((collection) => {
                            if (collection.id === data.updateCollection.id) {
                                return data.updateCollection;
                            }
                            return collection;
                        });
                        cache.writeQuery<GetCollectionsData, GetCollectionsVars>({
                            query: GET_COLLECTIONS_QUERY,
                            data: { getCollections: getCollectionsResult }
                        });
                    }
                    if (cacheCollection?.getCollection) {
                        cache.writeQuery<GetCollectionData, GetCollectionVars>({
                            query: GET_COLLECTION_QUERY,
                            variables: {
                                id: selectedCollection.id
                            },
                            data: {
                                getCollection: {
                                    ...cacheCollection.getCollection,
                                    name: data.updateCollection.name
                                }
                            }
                        });
                    }
                }
            },
            onCompleted(data) {
                snackbar.enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
                setDialogOpen({
                    ...dialogOpen,
                    update: false
                });
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                })
            }
        }
    );

    const [
        deleteCollection,
        {loading: mutationDeleteCollectionLoading}
    ] = useMutation<DeleteCollectionData, DeleteCollectionVars>(
        DELETE_COLLECTION_MUTATE,
        {
            update(cache, { data }) {
                const cacheCollections = cache.readQuery<GetCollectionsData, GetCollectionsVars>({
                    query: GET_COLLECTIONS_QUERY
                });
                if (data?.deleteCollection && cacheCollections?.getCollections) {
                    cache.writeQuery<GetCollectionsData, GetCollectionsVars>({
                        query: GET_COLLECTIONS_QUERY,
                        data: {
                            getCollections: cacheCollections.getCollections.filter((collection: any) => collection.id !== data.deleteCollection.id)
                        }
                    });
                }
            },
            onCompleted(data) {
                snackbar.enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
                setDialogOpen({
                    ...dialogOpen,
                    delete: false
                });
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                })
            }
        }
    );

    const {
        loading: queryLoading,
        error: queryError,
        fetchMore: queryFetchMore,
        data: queryData } = useQuery<GetCollectionsData, GetCollectionsVars>(
        GET_COLLECTIONS_QUERY
    );

    /*eslint-disable */
    useEffect(() => {
        if ((filterResult[0].type).trim() || (filterResult[0].value).trim() || (filterResult[1].type).trim()) {
            const timeout = setTimeout(() => {
                queryFetchMore({
                    query: GET_COLLECTIONS_QUERY,
                    variables: {
                        infos: filterResult,
                        page: currentPage,
                        rowsPerPage: currentRowsPerPage
                    },
                    updateQuery: (_: any, { fetchMoreResult }: any) => {
                        return fetchMoreResult;
                    }
                });
            }, 1000);
            return () => clearTimeout(timeout);
        } else {
            queryFetchMore({
                query: GET_COLLECTIONS_QUERY,
                variables: {
                    page: currentPage,
                    rowsPerPage: currentRowsPerPage
                },
                updateQuery: (_: any, { fetchMoreResult }: any) => {
                    return fetchMoreResult;
                }
            });
        }
    }, [filterResult, currentPage, currentRowsPerPage]);

    const [nbCollection, setNbCollection] = useState(0);

    useEffect(() => {
        if (queryData && queryData.getCollections && queryData.getCollections.length > 0) {
            setNbCollection(queryData.getCollections.length);
        }
    }, [queryData]);

    const [queryMessage, setQueryMessage] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (queryLoading) {
            setQueryMessage(undefined);
        } else if (queryError) {
            console.log(`Error!: ${queryError}`);
            setQueryMessage(undefined);
        } else if (queryData && !queryData.getCollections) {
            setQueryMessage('Les collections n\'ont pas pus être récupérés');
        } else if (queryData && queryData.getCollections && queryData.getCollections.length === 0) {
            setQueryMessage(
                <div>
                    Il n'y a pas de collection,
                    <LinkCustom
                        href='#'
                        onClick={(e: React.SyntheticEvent) => {
                            e.preventDefault();
                            setDialogOpen({
                                ...dialogOpen,
                                create: true
                            })
                        }}
                        color='inherit'>
                        ajoutez une collection dès maintenant
                    </LinkCustom>
                </div>
            );
        } else {
            setQueryMessage(undefined);
        }
    }, [queryData, queryLoading, queryError]);
    /*eslint-enable */

    const tabAreAdd = (
        <TabAppBar elevation={0} position="relative" >
            <TabList
                indicatorColor="secondary"
                textColor="secondary"
                onChange={(evt: React.ChangeEvent<{}>, value: string) => {
                    if (!globalState.subscribeState.isActive && nbCollection > 1) {
                        globalDispatch({
                            type: "updateModalSubscribe",
                            isOpen: true
                        });
                    } else {
                        setDialogOpen({
                            ...dialogOpen,
                            create: true
                        });
                    }
                }}
                aria-label="Sous menu collections">
                <TabCustom
                    label="Ajouter"
                    value="add"
                    icon={<AddIcon/>}/>
            </TabList>
        </TabAppBar>
    );

    const addCollectionHandler = useCallback(() => {
        if (!globalState.subscribeState.isActive && nbCollection > 1) {
            globalDispatch({
                type: "updateModalSubscribe",
                isOpen: true
            });
        } else {
            setDialogOpen({
                ...dialogOpen,
                create: true
            });
        }
    }, [globalState, nbCollection, dialogOpen, globalDispatch]);

    return (
        <CollectionsArea
            id="back-to-top-anchor"
            ref={node => {
                if (node) {
                    setScrollTarget(node);
                }
            }}>
            <BreadcrumbsApp
                links={links}
                lastElem="Collections"/>
            <FilterQuery
                isCollection
                filterSelectContent={filterSelectContent}
                filterSelectOrder={filterSelectOrder}
                filterResult={filterResult}
                setFilterResult={setFilterResult}
                setTypeDisplay={setTypeDisplay}
                typeDisplay={typeDisplay}
                addHandler={addCollectionHandler}
                totalResult={globalState.totalCollections}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentRowsPerPage={currentRowsPerPage}
                setCurrentRowsPerPage={setCurrentRowsPerPage}
                resetFilter={() => {
                    setFilterResult([
                        {
                            type: '',
                            value: ''
                        },
                        {
                            ...filterResult[1]
                        }
                    ]);
                }}
                resetOrder={() => {
                    setFilterResult([
                        {
                            ...filterResult[0]
                        },
                        {
                            type: '',
                            value: ''
                        }
                    ]);
                }}/>
            <TabArea>
                <TabContext value="1">
                    {(typeDisplay === 'line' || isMobile) ? tabAreAdd : undefined}
                </TabContext>
            </TabArea>
            {queryMessage && (<Message variant='filled' severity='info'>
                {queryMessage}
            </Message>)}
            {(typeDisplay === 'block' && !isMobile) && <Masonry
                enableResizableChildren={true}>
                {!queryMessage && queryData && queryData.getCollections.map((collection: any) => (
                    <Aux key={collection.id}>
                        <List
                            name={collection.name}
                            description={collection.description || ''}
                            image={collection.lastThumbnailUri || ''}
                            totalItems={collection.totalItems}
                            createdDate={() => getDateFromNow(collection.createdAt)}
                            openUpdateDialog={() => {
                                setDialogOpen({
                                    ...dialogOpen,
                                    update: true
                                });
                                setSelectedCollection({
                                    id: collection.id,
                                    name: collection.name,
                                    description: collection.description,
                                    template: collection.template
                                });
                            }}
                            openDeleteDialog={() => {
                                setDialogOpen({
                                    ...dialogOpen,
                                    delete: true
                                });
                                setSelectedCollection({
                                    id: collection.id,
                                    name: collection.name,
                                    description: collection.description,
                                    template: collection.template
                                });
                            }}
                            openTemplateDialog={() => {
                                if (!globalState.subscribeState.isActive) {
                                    globalDispatch({
                                        type: "updateModalSubscribe",
                                        isOpen: true
                                    });
                                } else {
                                    setDialogOpen({
                                        ...dialogOpen,
                                        template: true
                                    });
                                    setSelectedCollection({
                                        id: collection.id,
                                        name: collection.name,
                                        description: collection.description,
                                        template: collection.template
                                    });
                                }
                            }}
                            showContent={() => navigate(`/collections/${collection.id}`)}/>
                    </Aux>
                ))}
                {queryLoading && (
                    <SkeletonList/>
                )}
            </Masonry>}
            {(typeDisplay === 'line' || isMobile) && <LineList>
                {!queryMessage && queryData && queryData.getCollections.map((collection: any) => (
                    <Aux key={collection.id}>
                        <ListLine
                            id={collection.id}
                            name={collection.name}
                            description={collection.description || ''}
                            image={collection.lastThumbnailUri || ''}
                            totalItems={collection.totalItems}
                            createdDate={() => getDateFromNow(collection.createdAt)}
                            listLineExpanded={listLineExpanded}
                            handleListLineExpanded={handleListLineExpanded}
                            openUpdateDialog={() => {
                                setDialogOpen({
                                    ...dialogOpen,
                                    update: true
                                });
                                setSelectedCollection({
                                    id: collection.id,
                                    name: collection.name,
                                    description: collection.description,
                                    template: collection.template
                                });
                            }}
                            openDeleteDialog={() => {
                                setDialogOpen({
                                    ...dialogOpen,
                                    delete: true
                                });
                                setSelectedCollection({
                                    id: collection.id,
                                    name: collection.name,
                                    description: collection.description,
                                    template: collection.template
                                });
                            }}
                            openTemplateDialog={() => {
                                if (!globalState.subscribeState.isActive) {
                                    globalDispatch({
                                        type: "updateModalSubscribe",
                                        isOpen: true
                                    });
                                } else {
                                    setDialogOpen({
                                        ...dialogOpen,
                                        template: true
                                    });
                                    setSelectedCollection({
                                        id: collection.id,
                                        name: collection.name,
                                        description: collection.description,
                                        template: collection.template
                                    });
                                }
                            }}
                            showContent={() => navigate(`/collections/${collection.id}`)}/>
                    </Aux>
                ))}
            </LineList>}
            <PaginationArea>
                <Stack>
                    <Pagination
                        variant="outlined"
                        color="primary"
                        onChange={(event, page) => {
                            setCurrentPage((page - 1));
                        }}
                        shape="rounded"
                        page={currentPage + 1}
                        count={Math.ceil((globalState.totalCollections / currentRowsPerPage))}/>
                </Stack>
            </PaginationArea>
            <CollectionTemplateDialog
                open={dialogOpen.template}
                onCloseHandle={() => setDialogOpen({
                    ...dialogOpen,
                    template: false
                })}
                template={selectedCollection.template}
                collectionId={selectedCollection.id}
                upsertTemplateCollection={upsertTemplateCollection}
                upsertLoading={mutationUpsertTemplateCollectionLoading}/>
            <CollectionUpsertDialog
                isOpen={dialogOpen.create}
                onCloseHandle={() => setDialogOpen({
                    ...dialogOpen,
                    create: false
                })}
                upsertCollection={createCollection}
                upsertLoading={mutationCreateCollectionLoading}/>
            <CollectionUpsertDialog
                isOpen={dialogOpen.update}
                onCloseHandle={() => setDialogOpen({
                    ...dialogOpen,
                    update: false
                })}
                upsertCollection={updateCollection}
                upsertLoading={mutationUpdateCollectionLoading}
                collection={selectedCollection}/>
            <CollectionDeleteDialog
                isOpen={dialogOpen.delete}
                onCloseHandle={() => setDialogOpen({
                    ...dialogOpen,
                    delete: false
                })}
                deleteCollection={deleteCollection}
                deleteLoading={mutationDeleteCollectionLoading}
                collection={selectedCollection}/>
            {scrollTarget && <BackTop element={scrollTarget}>
                <Fab color="primary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </BackTop>}
        </CollectionsArea>
    );
};

const HomeIconCustom = styled(HomeIcon)(
    ({theme}) => ({
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }),
);

const LinkCustom = styled(Link)(
    ({theme}) => ({
        marginLeft: theme.spacing(0.5),
    }),
);

const TabAppBar = styled(AppBar)(
    ({theme}) => ({
        backgroundColor: 'white',
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }),
);

const TabCustom = styled(Tab)(
    ({theme}) => ({
        borderRight: '1px solid white',
        '&:hover': {
            color: theme.custom.iconCollection.color,
        },
    }),
);

const CollectionsArea = styled('div')(
    ({theme}) => ({
        height: 'calc(100vh - 128px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: theme.spacing(2),
    }),
);

const TabArea = styled('div')(
    () => ({
        '& > div': {
            padding: '0',
        },
    }),
);

const Message = styled(Alert)(
    ({theme}) => ({
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }),
);

const LineList = styled('div')(
    () => ({

    }),
);

const PaginationArea = styled('div')(
    ({theme}) => ({
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(2),
        width: 'fit-content',
    }),
);

export default Collections;