import React, { useEffect, useState } from 'react';
import {
    Dialog,
    Slide,
    DialogTitle,
    AppBar,
    Toolbar,
    Typography,
    DialogContent,
    DialogContentText,
    Grid,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    IconButton,
    colors,
    styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
    Close as CloseIcon
} from '@mui/icons-material'
import { isMobile } from 'react-device-detect';

import Aux from '../../hoc/Auxi';
import { Config } from '../../utils/config';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface TermsDialogProps {
    readonly isOpen: boolean;
    readonly acceptTermOfUseLoading: boolean;
    onCloseHandle: () => void;
    acceptTermOfUse: () => void;
}

const TermsDialog: any = (props: TermsDialogProps) => {
    const {
        isOpen,
        onCloseHandle,
        acceptTermOfUseLoading,
        acceptTermOfUse
    } = props;

    const [isChecked, setIsChecked] = useState(false);

    /*eslint-disable */
    useEffect(() => {
        if (isChecked) {
            acceptTermOfUse();
        }
    }, [isChecked]);
    /*eslint-enable */

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseHandle}
            fullScreen={isMobile}
            TransitionComponent={isMobile ? Transition : undefined}
            aria-labelledby="form-dialog-terms-title">
            {!isMobile && <DialogTitleCustom
                id="form-dialog-terms-title">
                Validation CGU
            </DialogTitleCustom>}
            {isMobile && <AppBarCustom>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onCloseHandle}
                        aria-label="Close"
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Title variant="h6">
                      Validation CGU
                    </Title>
                </Toolbar>
            </AppBarCustom>}
            <ValidArea>
                <DialogContent>
                    <DialogContentText>
                        Bienvenue sur votre plateforme de gestion de collection Collectoogo.
                    </DialogContentText>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {!acceptTermOfUseLoading && <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={event => setIsChecked(event.target.checked)}
                                        color="primary"/>
                                }
                                label={(
                                    <Aux>
                                        Pour continuer vous devez accepter les <Link rel="noreferrer" href={Config.termsUri} target='_blank'>CGU</Link>
                                    </Aux>
                                )}/>}
                            {acceptTermOfUseLoading && <CircularProgress/>}
                        </Grid>
                    </Grid>
                </DialogContent>
            </ValidArea>
        </Dialog>
    );
}

const DialogTitleCustom = styled(DialogTitle)(
    () => ({
        backgroundColor: colors.grey[50],
    }),
);

const AppBarCustom = styled(AppBar)(
    () => ({
        position: 'relative',
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(2),
        flex: 1,
    }),
);

const ValidArea = styled('div')(
    () => ({
        backgroundColor: colors.grey[50],
        height: '100%',
    }),
);

const Link = styled('a')(
    () => ({
        color: 'rgba(0, 0, 0, 0.87)',
    }),
);

export default TermsDialog;