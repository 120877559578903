import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Skeleton,
    styled
} from '@mui/material';

import Aux from '../../hoc/Auxi';

interface SkeletonListProps {

}

const SkeletonList: any = (props: SkeletonListProps) => {
    return (
        <Grid>
            <CardCustom>
                <CardHeader
                    avatar={<Skeleton variant="circular" width={40} height={40} />}
                    title={<Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />}
                    subheader={<Skeleton height={10} width="40%" />}/>
                <Media variant="rectangular"/>
                <CardContent>
                    <Aux>
                        <Skeleton height={10} style={{ marginBottom: 6 }} />
                        <Skeleton height={10} width="80%" />
                    </Aux>
                </CardContent>
                <CardActions>
                    <Skeleton variant="circular" width={40} height={40} />
                </CardActions>
            </CardCustom>
        </Grid>
    );
};

const Grid = styled('div')(
    ({theme}) => ({
        padding: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '33%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '25%',
        },
    }),
);

const CardCustom = styled(Card)(
    () => ({

    }),
);

const Media = styled(Skeleton)(
    () => ({
        height: 174,
    }),
);

export default SkeletonList;