import React from 'react';
import {
    Breadcrumbs,
    Link,
    Typography,
    Skeleton,
    Divider,
    styled
} from '@mui/material';
import {
    NavigateNext as NavigateNextIcon
} from '@mui/icons-material';

import Aux from '../../hoc/Auxi';

interface BreadcrumbsAppProps {
    readonly links: any[];
    readonly lastElem: string;
}

const BreadcrumbsApp: any = (props: BreadcrumbsAppProps) => {
    const {
        links,
        lastElem
    } = props;

    return (
        <Aux>
            <div>
                <Typography variant="h5" color="inherit">{lastElem}</Typography>
            </div>
            <BreadcrumbsCustom
                separator={
                    <NavigateNextIcon fontSize="small"/>
                }
                aria-label="Breadcrumb">
                {links.map((elem: any, index: number) => (
                    <LinkCustom
                        key={index}
                        color="inherit"
                        onClick={elem.onClick}
                        component="button">
                        {elem.label || (
                            <Aux>
                                <SkeletonCustom variant="circular" width={8} height={8} />
                                <SkeletonCustom variant="circular" width={8} height={8} />
                                <SkeletonCustom variant="circular" width={8} height={8} />
                            </Aux>
                        )}
                    </LinkCustom>
                ))}
                <TypographyCustom
                    color="textPrimary">
                    {lastElem || (
                        <Aux>
                            <SkeletonCustom variant="circular" width={8} height={8} />
                            <SkeletonCustom variant="circular" width={8} height={8} />
                            <SkeletonCustom variant="circular" width={8} height={8} />
                        </Aux>
                    )}
                </TypographyCustom>
            </BreadcrumbsCustom>
            <DividerCustom/>
        </Aux>
    );
};

const BreadcrumbsCustom = styled(Breadcrumbs)(
    ({theme}) => ({
        marginBottom: theme.spacing(2),
    }),
);

const LinkCustom: any = styled(Link)(
    () => ({
        display: 'flex',
        fontSize: '16px',
    }),
);

const SkeletonCustom = styled(Skeleton)(
    ({theme}) => ({
        margin: theme.spacing(0.3),
    }),
);

const TypographyCustom = styled(Typography)(
    () => ({
        display: 'flex',
        fontSize: '16px',
    }),
);

const DividerCustom = styled(Divider)(
    ({theme}) => ({
        marginBottom: theme.spacing(4),
    }),
);

export default BreadcrumbsApp;