import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    Skeleton,
    styled
} from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';

import { getDateDisplay } from '../../utils/date-transform';
import { useGlobalDispatch } from '../../shared/global-context';
import { ACCOUNT_BY_ID_QUERY, AccountByIdData, AccountByIdVars } from './requests/Home';
import BreadcrumbsApp from '../../components/Breadcrumbs/BreadcrumbsApp';

interface HomeProps {

}

const Home: any = (props: HomeProps) => {
    const navigate = useNavigate();
    const globalDispatch = useGlobalDispatch();

    const {loading: queryLoading, error: queryError, data: queryData} = useQuery<AccountByIdData, AccountByIdVars>(ACCOUNT_BY_ID_QUERY);

    const [queryMessage, setQueryMessage] = useState<any | undefined>(undefined);
    const [name, setName] = useState(' ');
    const [nameInfo, setNameInfo] = useState('');
    const [subscribeInfo, setSubscribeInfo] = useState('');
    const [nbCollectionsInfo, setNbCollectionsInfo] = useState('');
    const [nbItemsInfo, setNbItemsInfo] = useState('');
    const [limitCollectionsInfo, setLimitCollectionInfo] = useState('');

    const links = [
        {
            label: <HomeIconCustom/>,
            onClick: () => navigate('/')
        }
    ];

    useEffect(() => {
        if (queryLoading) {
            setQueryMessage(undefined);
        } else if (queryError) {
            console.log(`Error!: ${queryError}`);
            setQueryMessage(undefined);
        } else if (!queryData?.accountById) {
            setQueryMessage(undefined);
        } else {
            setQueryMessage(undefined);
        }
    }, [queryData, queryLoading, queryError]);

    useEffect(() => {
        if (queryData?.accountById?.firstName && queryData?.accountById?.lastName) {
            setName(queryData.accountById.firstName + ' ' + queryData.accountById.lastName);
            setNameInfo('content de vous revoir');
        } else {
            setNameInfo(`Vous n'avez pas encore complété vos données`);
        }
        if (queryData?.accountById?.subscriptionData?.isActive && queryData?.accountById?.subscriptionData?.currentPeriodEnd) {
            setSubscribeInfo(`Votre abonnement est valable jusqu'au ${
                getDateDisplay(queryData.accountById.subscriptionData.currentPeriodEnd)
            }`);
        } else {
            setSubscribeInfo(`Abonnez-vous dès maintenant pour profiter pleinement de Collectoogo`);
        }
        if (queryData?.accountById?.totalCollections) {
            setNbCollectionsInfo(`${queryData.accountById.totalCollections} collection(s)`);
        } else {
            setNbCollectionsInfo('Aucune collection');
        }
        if (queryData?.accountById?.totalItems) {
            setNbItemsInfo(`${queryData.accountById.totalItems} objet(s)`)
        } else {
            setNbItemsInfo('Aucun objet');
        }
        if (!queryData?.accountById?.totalCollections) {
            setLimitCollectionInfo('Créer ma première collection dès maintenant');
        } else if (queryData?.accountById?.totalCollections > 0 && !queryData?.accountById?.subscriptionData?.isActive) {
            setLimitCollectionInfo('Sans abonnement, vous ne pouvez plus créer de collection');
        } else {
            setLimitCollectionInfo('Gérer mes collections');
        }
    }, [queryData]);

    return (
        <HomeArea>
            <BreadcrumbsApp
                links={links}
                lastElem="Tableau de bords"/>
            {queryMessage && !queryLoading && (<div>
                {queryMessage}
            </div>)}
            <Cards container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <CardCustom>
                        <Content>
                            {!queryMessage && !queryLoading && <TextCenter variant='h5' component='h2'>
                                Bonjour<br/>
                                {name}<br/>
                                {nameInfo}
                            </TextCenter>}
                            {queryLoading && <TextCenter>
                                <SkeletonCustom height={20} width="80%" />
                                <SkeletonCustom height={20} width="80%" />
                                <SkeletonCustom height={20} width="80%" />
                            </TextCenter>}
                        </Content>
                        <CardActions>
                            {!queryMessage && !queryLoading && <ButtonCenter
                                onClick={() => {navigate('/account')}}
                                variant='outlined'>
                                Mon compte
                            </ButtonCenter>}
                            {queryLoading && <SkeletonCustom
                                variant="rectangular"
                                width={150}
                                height={30} />}
                        </CardActions>
                    </CardCustom>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <CardCustom>
                        <Content>
                            {!queryMessage && !queryLoading && <TextCenter variant='h5' component='h2'>
                                {subscribeInfo}
                            </TextCenter>}
                            {queryLoading && <TextCenter>
                                <SkeletonCustom height={20} width="80%" />
                                <SkeletonCustom height={20} width="80%" />
                            </TextCenter>}
                        </Content>
                        <CardActions>
                            {!queryMessage && !queryLoading && <ButtonCenter
                                onClick={() => {
                                    if (queryData?.accountById?.subscriptionData?.isActive) {
                                        navigate('/account');
                                    } else {
                                        globalDispatch({
                                            type: 'updateModalSubscribe',
                                            isOpen: true
                                        });
                                    }
                                }}
                                variant='outlined'>
                                {queryData?.accountById?.subscriptionData?.isActive ? 'Mon abonnement' : `Je m'abonne`}
                            </ButtonCenter>}
                            {queryLoading && <SkeletonCustom
                                variant="rectangular"
                                width={150}
                                height={30} />}
                        </CardActions>
                    </CardCustom>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <CardCustom>
                        <ContentNoAction>
                            {!queryMessage && !queryLoading && <TextCenter variant='h5' component='h2'>
                                Votre compte possède<br/>
                                {nbCollectionsInfo}<br/>
                                {nbItemsInfo}
                            </TextCenter>}
                            {queryLoading && <TextCenter>
                                <SkeletonCustom height={20} width="80%" />
                                <SkeletonCustom height={20} width="80%" />
                                <SkeletonCustom height={20} width="80%" />
                            </TextCenter>}
                        </ContentNoAction>
                    </CardCustom>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <CardCustom>
                        <Content>
                            {!queryMessage && !queryLoading && <TextCenter variant='h5' component='h2'>
                                {limitCollectionsInfo}
                            </TextCenter>}
                            {queryLoading && <TextCenter>
                                <SkeletonCustom height={20} width="80%" />
                            </TextCenter>}
                        </Content>
                        <CardActions>
                            {!queryMessage && !queryLoading && <ButtonCenter
                                onClick={() => {
                                    if ((queryData?.accountById?.totalCollections || 0) > 0 && !queryData?.accountById?.subscriptionData?.isActive) {
                                        globalDispatch({
                                            type: 'updateModalSubscribe',
                                            isOpen: true
                                        });
                                    } else {
                                        navigate('/collections');
                                    }
                                }}
                                variant='outlined'>
                                {((queryData?.accountById?.totalCollections || 0) > 0 && !queryData?.accountById?.subscriptionData?.isActive) ?
                                    `Je m'abonne` :
                                    'Mes collections'}
                            </ButtonCenter>}
                            {queryLoading && <SkeletonCustom
                                variant="rectangular"
                                width={150}
                                height={30} />}
                        </CardActions>
                    </CardCustom>
                </Grid>
            </Cards>
        </HomeArea>
    );
};

const HomeArea = styled('div')(
    ({theme}) => ({
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        height: 'calc(100vh - 88px)',
        overflowY: 'auto',
        overflowX: 'hidden',
    }),
);

const Cards = styled(Grid)(
    ({theme}) => ({
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    }),
);

const CardCustom = styled(Card)(
    () => ({
        height: '300px',
    }),
);

const Content = styled(CardContent)(
    () => ({
        height: 'calc(100% - 52px)',
        display: 'flex',
        alignItems: 'center',
    }),
);

const ContentNoAction = styled(CardContent)(
    () => ({
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    }),
);

const TextCenter: any = styled(Typography)(
    () => ({
        textAlign: 'center',
        width: '100%',
    }),
);

const SkeletonCustom = styled(Skeleton)(
    () => ({
        margin: 'auto',
    }),
);

const ButtonCenter = styled(Button)(
    () => ({
        margin: 'auto',
    }),
);

const HomeIconCustom = styled(HomeIcon)(
    ({theme}) => ({
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }),
);

export default Home;