import React from 'react';
import {
    Backdrop,
    CircularProgress,
    styled
} from '@mui/material';

import Auth from '../../services/auth/Auth';

interface LoginProps {
    auth: Auth;
    isSignup?: boolean;
}

const Login = (props: LoginProps) => {
    const {
        auth,
        isSignup
    } = props;

    auth.login(isSignup);

    return (
        <BackdropCustom open={true}>
            <CircularProgress color="primary" />
        </BackdropCustom>
    );
};

const BackdropCustom = styled(Backdrop)(
    ({theme}) => ({
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }),
);

export default Login;