import React, { useEffect, useState } from 'react';
import {
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Button,
    CircularProgress,
    Grid,
    colors,
    styled
} from '@mui/material';

interface CollectionUpsertProps {
    readonly upsertLoading: boolean;
    readonly collection?: any;
    onCloseHandle: () => void;
    upsertCollection: (values: any) => void;
}

const CollectionUpsert: any = (props: CollectionUpsertProps) => {
    const {
        onCloseHandle,
        upsertLoading,
        upsertCollection,
        collection
    } = props;

    const [elemForm, setElemForm] = useState({
        name: '',
        description: ''
    });

    const [elemEmpty, setElemEmpty] = useState({
        name: false
    });

    useEffect(() => {
        if (collection) {
            setElemForm({
                name: collection.name,
                description: collection.description
            });
        }
    }, [collection]);

    useEffect(() => {
        setElemEmpty({
            name: (elemForm.name ? elemForm.name.trim() === '' : true)
        });
    }, [elemForm]);

    const upsertHandle = () => {
        upsertCollection({
            variables: {
                id: (collection ? collection.id : null),
                data: {
                    name: elemForm.name ? elemForm.name.trim() || null : null,
                    description: elemForm.description ? elemForm.description.trim() || null : null
                }
            }
        });
    };

    return (
        <Container
            noValidate
            autoComplete="off">
            <DialogContent>
                <DialogContentText>
                    {(collection
                        ? 'Pour modifier une collection, veuillez modifier les champs.'
                        : 'Pour créer une nouvelle collection, veuillez saisir un nom.')}
                </DialogContentText>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TextFieldCustom
                            label="Nom"
                            autoFocus={true}
                            value={elemForm.name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setElemForm({...elemForm, name: e.target.value})}
                            onKeyPress={(e: React.KeyboardEvent) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    upsertHandle();
                                }
                            }}
                            helperText={elemEmpty.name && 'Le nom est obligatoire'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                            error={elemEmpty.name}
                            fullWidth
                            margin="normal"
                            variant="outlined"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldCustom
                            label="Description"
                            multiline
                            rows={4}
                            value={elemForm.description || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setElemForm({...elemForm, description: e.target.value})}
                            onKeyPress={(e: React.KeyboardEvent) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    upsertHandle();
                                }
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            margin="normal"
                            variant="outlined"/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActionsCustom>
                <ButtonCustom
                    variant="outlined"
                    onClick={onCloseHandle}>
                    Annuler
                </ButtonCustom>
                <Wrapper>
                    <ButtonCustom
                        variant="outlined"
                        color="primary"
                        disabled={!(!elemEmpty.name) || upsertLoading}
                        onClick={upsertHandle}>
                        {(collection
                            ? 'Modifier'
                            : 'Créer')}
                    </ButtonCustom>
                    {upsertLoading && <ButtonProgress size={24}/>}
                </Wrapper>
            </DialogActionsCustom>
        </Container>
    );
};

const Container = styled('form')(
    () => ({
        display: 'flex',
        flexWrap: 'wrap',
    }),
);

const TextFieldCustom = styled(TextField)(
    () => ({

    }),
);

const DialogActionsCustom = styled(DialogActions)(
    ({theme}) => ({
        width: '100%',
        margin: 0,
        padding: theme.spacing(2),
    }),
);

const ButtonCustom = styled(Button)(
    ({theme}) => ({
        marginRight: theme.spacing(1),
    }),
);

const Wrapper = styled('div')(
    () => ({
        position: 'relative',
    }),
);

const ButtonProgress = styled(CircularProgress)(
    () => ({
        color: colors.green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }),
);

export default CollectionUpsert;