import { gql } from '@apollo/client';

export interface InfoSearchInput {
    type: string;
    value: string;
    cursor?: string;
}

export interface GetCollectionsVars {
    cursor?: string;
    page?: number;
    rowsPerPage?: number;
    infos?: InfoSearchInput[];
}

export interface AddInput {
    label: string;
    type: number;
}

export interface GetCollectionsData {
    getCollections: {
        id: string;
        createdAt: Date;
        name: string;
        description?: string;
        template: AddInput[];
        totalItems?: number;
        lastThumbnailUri?: string;
    }[];
}

export const GET_COLLECTIONS_QUERY = gql`
    query GetCollections($cursor: String, $page: Int, $rowsPerPage: Int, $infos: [InfoSearchInput!]) {
        getCollections(cursor: $cursor, page: $page, rowsPerPage: $rowsPerPage, infos: $infos) {
            id
            createdAt
            name
            description
            template {
                label
                type
            }
            totalItems
            lastThumbnailUri
        }
    }
`;

export interface GetCollectionVars {
    id: string
}

export interface GetCollectionData {
    getCollection: {
        id: string;
        name: string;
        template: AddInput[];
    };
}

export const GET_COLLECTION_QUERY = gql`
    query GetCollection($id: ID!) {
        getCollection(id: $id) {
            id
            name
            template {
                label
                type
            }
        }
    }
`;

export interface UpdateCollectionInput {
    name: string;
    description?: string;
}

export interface CreateCollectionVars {
    data: UpdateCollectionInput;
}

export interface CreateCollectionData {
    createCollection: {
        id: string;
        createdAt: Date;
        name: string;
        description?: string;
        template: AddInput[];
        totalItems?: number;
        lastThumbnailUri?: string;
    };
}

export const CREATE_COLLECTION_MUTATE = gql`
    mutation CreateCollection($data: UpdateCollectionInput!) {
        createCollection(data: $data) {
            id
            createdAt
            name
            description
            template {
                label
                type
            }
            totalItems
            lastThumbnailUri
        }
    }
`;

export interface UpdateCollectionVars {
    id: string;
    data: UpdateCollectionInput;
}

export interface UpdateCollectionData {
    updateCollection: {
        id: string;
        createdAt: Date;
        name: string;
        description?: string;
        template: AddInput[];
        totalItems?: number;
        lastThumbnailUri?: string;
    };
}

export const UPDATE_COLLECTION_MUTATE = gql`
    mutation UpdateCollection($id: ID!, $data: UpdateCollectionInput!) {
        updateCollection(id: $id, data: $data) {
            id
            createdAt
            name
            description
            template {
                label
                type
            }
            totalItems
            lastThumbnailUri
        }
    }
`;

export interface DeleteCollectionVars {
    id: string;
}

export interface DeleteCollectionData {
    deleteCollection: {
        id: string;
    };
}

export const DELETE_COLLECTION_MUTATE = gql`
    mutation DeleteCollection($id: ID!) {
        deleteCollection(id: $id) {
            id
        }
    }
`;

export interface AddInputInput {
    label: string;
    type: number;
    value?: string;
}

export interface UpsertTemplateCollectionInput {
    template?: AddInputInput[];
}

export interface UpsertTemplateCollectionVars {
    id: string;
    data: UpsertTemplateCollectionInput;
}

export interface UpsertTemplateCollectionData {
    upsertTemplateCollection: {
        id: string;
        template: AddInput[];
    };
}

export const UPSERT_TEMPLATE_COLLECTION_MUTATE = gql`
    mutation UpsertTemplateCollection($id: ID!, $data: UpsertTemplateCollectionInput!) {
        upsertTemplateCollection(id: $id, data: $data) {
            id
            template {
                label
                type
            }
        }
    }
`;