import React from 'react';
import {
    Menu,
    MenuItem,
    ListItemIcon,
    Typography,
    useTheme
} from '@mui/material';
import {
    Lock
} from '@mui/icons-material';

import { useGlobalState } from '../../shared/global-context';

interface MenuListProps {
    readonly idMenu: string;
    readonly anchorEl: any;
    readonly open: boolean;
    readonly menuContent: any[];
    isItem?: boolean;
    onClose: () => void;
}

const MenuList: any = (props: MenuListProps) => {
    const {
        idMenu,
        anchorEl,
        open,
        onClose,
        menuContent,
        isItem
    } = props;
    const theme = useTheme();

    const menuHandle: any = (onClick: () => void) => {
        onClick();
        onClose();
    };

    const globalState = useGlobalState();

    return (
        <Menu
            id={idMenu}
            MenuListProps={{
                style: {
                    backgroundColor: isItem ? theme.custom.cardItem.backgroundColor : undefined
                }
            }}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={onClose}
            disableAutoFocusItem={true}>
            {menuContent.map((elem: any) =>
                <MenuItem
                    key={elem.label}
                    onClick={() => {menuHandle(elem.onClick)}}
                    selected={(elem.isPremium && !globalState.subscribeState.isActive)}>
                    <ListItemIcon>
                        {(elem.isPremium && !globalState.subscribeState.isActive) ? (<Lock/>) : elem.icon}
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {elem.label}
                    </Typography>
                </MenuItem>)}
        </Menu>
    );
};

export default MenuList;