import { gql } from '@apollo/client';

export interface AccountByIdVars {
    id?: string;
}

export interface SubscriptionData {
    currentPeriodStart?: Date;
    currentPeriodEnd?: Date;
    upcomingPeriodStart?: Date;
    upcomingPeriodEnd?: Date;
    upcomingTotal?: number;
    label?: string;
    isActive?: boolean;
}

export interface Address {
    street: string;
    zipCode: string;
    city: string;
    country: string;
}

export interface AccountByIdData {
    accountById: {
        id: string;
        firstName?: string;
        lastName?: string;
        dateBirth?: string;
        termAccepted?: boolean;
        totalCollections?: number;
        address?: Address;
        subscriptionData?: SubscriptionData;
    };
}

export const ACCOUNT_BY_ID_QUERY = gql`
    query AccountById($id: ID) {
        accountById(id: $id) {
            id
            firstName
            lastName
            dateBirth
            termAccepted
            totalCollections
            address {
                street
                zipCode
                city
                country
            }
            subscriptionData {
                currentPeriodStart
                currentPeriodEnd
                upcomingPeriodStart
                upcomingPeriodEnd
                upcomingTotal
                label
                isActive
            }
        }
    }
`;

export interface SubscribeAutoBetaVars {

}

export interface SubscribeAutoBetaData {
    subscribeAutoBeta: {
        id: string;
        firstName?: string;
        lastName?: string;
        dateBirth?: string;
        address?: Address;
        subscriptionData?: SubscriptionData;
    }
}

export const SUBSCRIBE_AUTO_BETA_MUTATION = gql`
    mutation SubscribeAutoBeta {
        subscribeAutoBeta {
            id
            firstName
            lastName
            dateBirth
            termAccepted
            address {
                street
                zipCode
                city
                country
            }
            subscriptionData {
                currentPeriodStart
                currentPeriodEnd
                upcomingPeriodStart
                upcomingPeriodEnd
                upcomingTotal
                label
                isActive
            }
        }
    }
`;

export interface AcceptTermOfUseVars {

}

export interface AcceptTermOfUseData {
    acceptTermOfUse: {
        id: string;
        firstName?: string;
        lastName?: string;
        dateBirth?: string;
        address?: Address;
        subscriptionData?: SubscriptionData;
    }
}

export const ACCEPT_TERM_OF_USE_MUTATION = gql`
    mutation AcceptTermOfUse {
        acceptTermOfUse {
            id
            firstName
            lastName
            dateBirth
            termAccepted
            address {
                street
                zipCode
                city
                country
            }
            subscriptionData {
                currentPeriodStart
                currentPeriodEnd
                upcomingPeriodStart
                upcomingPeriodEnd
                upcomingTotal
                label
                isActive
            }
        }
    }
`;

export interface SendSupportEmailVars {
    object: string;
    message: string;
}

export interface SendSupportEmailData {
    sendSupportEmail: boolean;
}

export const SEND_SUPPORT_EMAIL_MUTATION = gql`
    mutation SendSupportEmail($object: String!, $message: String!) {
        sendSupportEmail(object: $object, message: $message)
    }
`;