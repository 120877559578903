import React, { useEffect, useState } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    CardActionArea,
    Typography,
    IconButton,
    CardActions,
    Avatar,
    colors,
    styled
} from '@mui/material';
import {
    MoreVert as MoreVertIcon,
    Edit,
    Delete,
    Visibility,
    LocalOffer,
    Style,
    ListAlt as ListAltIcon
} from '@mui/icons-material';

import MenuList from '../Menu/MenuList';
import Image from './Image';

interface ListProps {
    readonly name: string;
    readonly description: string;
    readonly totalItems: string;
    readonly image: string;
    createdDate: () => string;
    openUpdateDialog?: () => void;
    openDeleteDialog?: () => void;
    openTemplateDialog?: () => void;
    showContent: () => void;
}

const List: any = (props: ListProps) => {
    const {
        name,
        description,
        image,
        createdDate,
        openUpdateDialog,
        openDeleteDialog,
        openTemplateDialog,
        showContent,
        totalItems
    } = props;

    const [anchorEl, setAnchorEl] = useState(null),
        [open, setOpen] = useState(false);

    const [imageSrc, setImageSrc] = useState<React.ReactNode | undefined>(undefined);

    useEffect(() => {
        setImageSrc(
            <Image name={name} image={image}/>
        );
    }, [image, name])

    const menuListId: string = 'menu-collection-list';

    const menuContent: any[] = [
        {
            label: 'Voir',
            onClick: showContent,
            icon: (<Visibility/>),
        },
        {
            label: 'Modifier',
            onClick: openUpdateDialog,
            icon: (<Edit/>),
        },
        {
            label: 'Modèle',
            onClick: openTemplateDialog,
            icon: (<ListAltIcon/>),
            isPremium: true,
        },
        {
            label: 'Supprimer',
            onClick: openDeleteDialog,
            icon: (<Delete/>),
        },
    ];

    useEffect(() => {
        setOpen(Boolean(anchorEl));
    }, [anchorEl]);

    const menu = (
        <IconButton
            aria-label="More"
            aria-controls={menuListId}
            aria-haspopup="true"
            onClick={event => setAnchorEl(event.currentTarget as any)}
            size="large">
            <MoreVertIcon/>
        </IconButton>
    );

    return (
        <Grid>
            <CardCustom>
                <CardHeader
                    avatar={
                        <AvatarCustom variant='rounded'>
                            <Style/>
                        </AvatarCustom>
                    }
                    title={name}
                    subheader={createdDate()}
                    action={(openUpdateDialog && openDeleteDialog && openTemplateDialog) ? menu : null}/>
                <CardActionAreaCustom onClick={showContent}>
                    {imageSrc}
                    {description && <CardContent>
                        <Typography component="p">
                            {description}
                        </Typography>
                    </CardContent>}
                </CardActionAreaCustom>
                <CardActions>
                    <AvatarIcon variant='rounded'>
                        <LocalOffer /> {totalItems}
                    </AvatarIcon>
                </CardActions>
            </CardCustom>
            {open && openUpdateDialog && openDeleteDialog && openTemplateDialog && <MenuList
                idMenu={menuListId}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                menuContent={menuContent}/>}
        </Grid>
    );
};

const Grid = styled('div')(
    ({theme}) => ({
        padding: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '33%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '25%',
        },
    }),
);

const CardCustom = styled(Card)(
    () => ({
        border: `1px solid ${colors.cyan[200]}`,
    }),
);

const AvatarCustom = styled(Avatar)(
    ({theme}) => ({
        color: theme.custom.avatarCollection.color,
        backgroundColor: theme.custom.avatarCollection.backgroundColor,
    }),
);

const CardActionAreaCustom = styled(CardActionArea)(
    () => ({
        backgroundColor: colors.cyan[50],
    }),
);

const AvatarIcon = styled(Avatar)(
    ({theme}) => ({
        width: 'fit-content',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: theme.custom.avatarCollection.color,
        backgroundColor: theme.custom.avatarCollection.backgroundColor,
    }),
);

export default List;