import moment, { MomentInput } from 'moment';
import 'moment/locale/fr';

const createMoment = (input: MomentInput) => moment(input).locale('fr');

export const getDateFromNow: any = (date: number) => {
    return createMoment(date).fromNow();
};

export const getDateDisplay: any = (date: number) => {
    return createMoment(date).format('LL');
};