import React, {useEffect, useState} from 'react';
import {
    CircularProgress,
    styled
} from '@mui/material';
import {
    Image as ImageIcon
} from '@mui/icons-material';
import { useImage } from 'react-image';

import Aux from '../../hoc/Auxi';

interface ImageProps {
    readonly image: string;
    readonly name: string;
    readonly isItem?: boolean;
}

const Image: any = (props: ImageProps) => {
    const {
        image,
        name,
        isItem
    } = props;

    const [srcImgSaved, setSrcImgSaved] = useState<string | undefined>(undefined);

    const { src: srcImg, isLoading: isLoadingImg, error: errorImg } = useImage({
        srcList: image,
        useSuspense: false
    });

    useEffect(() => {
        if (srcImg) {
            setSrcImgSaved(srcImg);
        }
    }, [srcImg])

    return (
        <Aux>
            {srcImgSaved && <ImgCustom alt={name} src={srcImgSaved}/>}
            {!srcImgSaved && isLoadingImg && <ImageLoadState/>}
            {!srcImgSaved && errorImg && <ImageIconCustom isItem={isItem} fontSize='large' color='disabled' />}
        </Aux>
    );
}

const ImgCustom = styled('img')(
    () => ({
        width: '100%',
    }),
);

const ImageLoadState = styled(CircularProgress)(
    ({theme}) => ({
        margin: 'auto',
        width: '100%',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
    }),
);

const ImageIconCustom = styled(ImageIcon)<{isItem?: boolean}>(
    ({theme, isItem}) => ({
        margin: 'auto',
        width: '100%',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        color: isItem ? theme.custom.iconItem.color : theme.custom.iconCollection.color,
    }),
);

export default Image;