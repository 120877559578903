import React from 'react';
import {
    Divider,
    List,
    ListItem,
    colors,
    styled,
    Toolbar
} from '@mui/material';
import {
    Star,
    StarBorder
} from '@mui/icons-material';

import Item from './Item';
import { useGlobalState } from '../../../shared/global-context';

interface ItemListProps {
    readonly menuContent: any[];
}

const ItemList: any = (props: ItemListProps) => {
    const {
        menuContent
    } = props;

    const globalState = useGlobalState();

    return (
        <div>
            <Toolbar/>
            <Divider/>
            <List>
                {false && <ListItem>
                    {globalState.subscribeState.isActive && <StarCustom
                        fontSize="large"
                        style={{ color: colors.yellow["600"] }}/>}
                    {!globalState.subscribeState.isActive && <StarBorderCustom
                      fontSize="large"
                      style={{ color: colors.brown["500"] }}/>}
                </ListItem>}
                <ListItem>
                    {globalState.subscribeState.isActive &&
                        <LogoStatus width={120} src="/image/logo-collectoogo-premium.svg" alt="logo premium"/>}
                    {!globalState.subscribeState.isActive &&
                        <LogoStatus width={120} src="/image/logo-collectoogo-free.svg" alt="logo free"/>}
                </ListItem>
                {menuContent.map((elem: any) =>
                    <Item
                        key={elem.label}
                        label={elem.label}
                        linkTo={elem?.linkTo}
                        onClick={elem?.onClick}
                        icon={elem.icon}/>)}
            </List>
        </div>
    );
};

const StarCustom = styled(Star)(
    () => ({
        margin: 'auto',
    }),
);

const StarBorderCustom = styled(StarBorder)(
    () => ({
        margin: 'auto',
    }),
);

const LogoStatus = styled('img')(
    () => ({
        margin: 'auto',
    }),
);

export default ItemList;