import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Skeleton,
    styled
} from '@mui/material';

interface SkeletonAddFieldProps {

}

const SkeletonAddField: any = (props: SkeletonAddFieldProps) => {
    return (
        <CardCustom>
            <CardContent>
                <Skeleton height={20} width="100%" style={{ marginBottom: 6 }} />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Skeleton variant="rectangular" width="100%" height={40} />
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton variant="rectangular" width="100%" height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </CardCustom>
    );
}

const CardCustom = styled(Card)(
    ({theme}) => ({
        margin: 'auto',
        backgroundColor: theme.custom.cardItem.backgroundColor,
        [theme.breakpoints.up('xs')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: '300px',
        },
    }),
);

export default SkeletonAddField;