import React from 'react';
import {
    Card,
    CardContent,
    Button,
    Typography,
    CircularProgress,
    colors,
    styled
} from '@mui/material';
import {
    Lock
} from '@mui/icons-material';

import { useGlobalState } from '../../shared/global-context';

interface SaveTemplateProps {
    readonly upsertLoading: boolean;
    upsertTemplateCollection: () => void;
}

const SaveTemplate: any = (props: SaveTemplateProps) => {
    const {
        upsertLoading,
        upsertTemplateCollection
    } = props;

    const globalState = useGlobalState();

    return (
        <CardCustom>
            <CardContent>
                <Title
                    color="textSecondary"
                    gutterBottom>
                    Conserver le modèle pour la collection
                </Title>
                <Wrapper>
                    <ButtonCustom
                        variant={globalState.subscribeState.isActive ? 'outlined' : 'contained'}
                        fullWidth
                        onClick={upsertTemplateCollection}
                        startIcon={globalState.subscribeState.isActive ? undefined : <Lock/>}>
                        Conserver
                    </ButtonCustom>
                    {upsertLoading && <ButtonProgress size={24}/>}
                </Wrapper>
            </CardContent>
        </CardCustom>
    );
};

const CardCustom = styled(Card)(
    ({theme}) => ({
        margin: 'auto',
        backgroundColor: theme.custom.cardItem.backgroundColor,
        [theme.breakpoints.up('xs')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: '300px',
        },
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        fontSize: 14,
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    }),
);

const Wrapper = styled('div')(
    () => ({
        position: 'relative',
    }),
);

const ButtonCustom = styled(Button)(
    () => ({

    }),
);

const ButtonProgress = styled(CircularProgress)(
    () => ({
        color: colors.green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }),
);

export default SaveTemplate;