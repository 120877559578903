import React, { useEffect, useState } from 'react';
import {
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Button,
    CircularProgress,
    Grid,
    colors,
    styled
} from '@mui/material';

interface SupportRequestProps {
    readonly supportLoading: boolean;
    onCloseHandle: () => void;
    supportSend: (values: any) => void;
}

const SupportRequest: any = (props: SupportRequestProps) => {
    const {
        onCloseHandle,
        supportLoading,
        supportSend
    } = props;

    const [elemForm, setElemForm] = useState({
        object: '',
        message: ''
    });

    const [elemEmpty, setElemEmpty] = useState({
        object: false,
        message: false
    });

    useEffect(() => {
        setElemEmpty({
            object: (elemForm.object ? elemForm.object.trim() === '' : true),
            message: (elemForm.message ? elemForm.message.trim() === '' : true)
        });
    }, [elemForm]);

    const supportSendHandle = () => {
        supportSend({
            variables: {
                object: elemForm.object ? elemForm.object.trim() || null : null,
                message: elemForm.message ? elemForm.message.trim() || null : null
            }
        });
    }

    return (
        <Container
            noValidate
            autoComplete="off">
            <DialogContent>
                <DialogContentText>
                    Envoyer votre demande au support technique
                </DialogContentText>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TextFieldCustom
                            label="Objet de votre demande"
                            autoFocus={true}
                            value={elemForm.object}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setElemForm({...elemForm, object: e.target.value})}
                            onKeyPress={(e: React.KeyboardEvent) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    supportSendHandle();
                                }
                            }}
                            helperText={elemEmpty.object && `L'objet est obligatoire`}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                            error={elemEmpty.object}
                            fullWidth
                            margin="normal"
                            variant="outlined"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldCustom
                            label="Votre message"
                            multiline
                            rows={4}
                            value={elemForm.message || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setElemForm({...elemForm, message: e.target.value})}
                            onKeyPress={(e: React.KeyboardEvent) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    supportSendHandle();
                                }
                            }}
                            helperText={elemEmpty.object && `Le message est obligatoire`}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                            error={elemEmpty.message}
                            fullWidth
                            margin="normal"
                            variant="outlined"/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActionsCustom>
                <ButtonCustom
                    variant="outlined"
                    onClick={onCloseHandle}>
                    Annuler
                </ButtonCustom>
                <Wrapper>
                    <ButtonCustom
                        variant="outlined"
                        color="primary"
                        disabled={!(!elemEmpty.object && !elemEmpty.message) || supportLoading}
                        onClick={supportSendHandle}>
                        Envoyer
                    </ButtonCustom>
                    {supportLoading && <ButtonProgress size={24}/>}
                </Wrapper>
            </DialogActionsCustom>
        </Container>
    );
}

const Container = styled('form')(
    () => ({
        display: 'flex',
        flexWrap: 'wrap',
    }),
);

const TextFieldCustom = styled(TextField)(
    () => ({

    }),
);

const DialogActionsCustom = styled(DialogActions)(
    ({theme}) => ({
        width: '100%',
        margin: 0,
        padding: theme.spacing(2),
    }),
);

const ButtonCustom = styled(Button)(
    ({theme}) => ({
        marginRight: theme.spacing(1),
    }),
);

const Wrapper = styled('div')(
    () => ({
        position: 'relative',
    }),
);

const ButtonProgress = styled(CircularProgress)(
    () => ({
        color: colors.green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }),
);

export default SupportRequest;