import React, { useEffect, useState } from 'react';
import {
    Grid,
    IconButton,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Menu,
    MenuItem,
    Button,
    CircularProgress,
    colors,
    styled
} from '@mui/material';
import {
    MoreVert as MoreVertIcon
} from '@mui/icons-material';

import AddField, { items } from './AddField';
import ItemNameUpsertDialog from '../Dialogs/ItemNameUpsertDialog';
import Aux from '../../hoc/Auxi';

interface TemplateProps {
    readonly templateLocal: any[];
    readonly upsertLoading?: boolean;
    deleteFieldInItems: (value: number) => void;
    setTemplateLocal: (value: any[]) => void;
    upsertTemplateCollection?: () => void;
}

const Template: any = (props: TemplateProps) => {
    const {
        deleteFieldInItems,
        templateLocal,
        setTemplateLocal,
        upsertTemplateCollection,
        upsertLoading
    } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [typeSelected, setTypeSelected] = useState(undefined),
        [fieldSelected, setFieldSelected] = useState<number | undefined>(undefined),
        [valueSelected, setValueSelected] = useState(''),
        [dialogOpen, setDialogOpen] = useState({
            create: false,
            update: false
        });

    const handleClickAddInput = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseTemplate = () => {
        setAnchorEl(null);
    };

    const menuTemplateContent = [
        {
            label: 'Modifier',
            onClick: (index: number) => {
                setFieldSelected(index);
                handleCloseTemplate();
            }
        },
        {
            label: 'Supprimer',
            onClick: (index: number) => {
                deleteFieldInItems(index);
                handleCloseTemplate();
            }
        }
    ];

    const addFieldInItems: any = (name: string) => {
        setDialogOpen({
            ...dialogOpen,
            create: false
        });
        setTemplateLocal(templateLocal.concat({
                label: name,
                type: typeSelected
            })
        );
        setTypeSelected(undefined);
    };

    const updateFieldInItems: any = (name: string) => {
        setDialogOpen({
            ...dialogOpen,
            update: false
        });
        const addInputResult: any[] = templateLocal;
        addInputResult[fieldSelected as any].label = name;
        setTemplateLocal(addInputResult);
        setValueSelected('');
        setFieldSelected(undefined);
    };

    /*eslint-disable */
    useEffect(() => {
        if (typeof typeSelected !== "undefined") {
            setDialogOpen({
                ...dialogOpen,
                create: true
            });
        }
    }, [typeSelected]);

    useEffect(() => {
        if (typeof fieldSelected !== "undefined") {
            setDialogOpen({
                ...dialogOpen,
                update: true
            });
            setValueSelected(templateLocal[fieldSelected as any].label)
        }
    }, [fieldSelected]);
    /*eslint-enable */

    return (
        <Aux>
            <MainGrid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                    <AddField setTypeSelected={setTypeSelected} isModal/>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={10} xl={10}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableContainerCustom component={Paper}>
                                <TableCustom aria-label="template table">
                                    {upsertTemplateCollection && <caption>
                                        <Caption>
                                            <Wrapper>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={upsertTemplateCollection}>
                                                    Sauvegarder
                                                </Button>
                                                {upsertLoading && <ButtonProgress size={24}/>}
                                            </Wrapper>
                                        </Caption>
                                    </caption>}
                                    <TableHead>
                                        <RowHeader>
                                            <TableCell>Actions</TableCell>
                                            <TableCell>Emplacement</TableCell>
                                            <TableCell>Type de champs</TableCell>
                                            <TableCell>Nom du champs</TableCell>
                                        </RowHeader>
                                    </TableHead>
                                    <TableBody>
                                        <TableRowCustom>
                                            <TableCell> </TableCell>
                                            <TableCell>1</TableCell>
                                            <TableCell>Texte</TableCell>
                                            <TableCell>Nom</TableCell>
                                        </TableRowCustom>
                                        <TableRowCustom>
                                            <TableCell> </TableCell>
                                            <TableCell>2</TableCell>
                                            <TableCell>Zone de texte</TableCell>
                                            <TableCell>Description</TableCell>
                                        </TableRowCustom>
                                        <TableRowCustom>
                                            <TableCell> </TableCell>
                                            <TableCell>3</TableCell>
                                            <TableCell>Zone de tags</TableCell>
                                            <TableCell>Tags</TableCell>
                                        </TableRowCustom>
                                        {templateLocal.map((elem: any, index: number) => {
                                            return (
                                                <AddFieldRow key={index}>
                                                    <TableCell>
                                                        <AddFieldAction
                                                            onClick={handleClickAddInput}
                                                            aria-controls="MenuTemplate"
                                                            aria-haspopup="true"
                                                            size="large">
                                                            <MoreVertIcon/>
                                                        </AddFieldAction>
                                                        <Menu
                                                            id="MenuTemplate"
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleCloseTemplate}>
                                                            {menuTemplateContent.map((menuItem: any) => {
                                                                return (
                                                                    <MenuItem onClick={() => menuItem.onClick(index)}>
                                                                        {menuItem.label}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Menu>
                                                    </TableCell>
                                                    <TableCell>{index + 4}</TableCell>
                                                    <TableCell>{items[elem.type].label}</TableCell>
                                                    <TableCell>{elem.label}</TableCell>
                                                </AddFieldRow>
                                            );
                                        })}
                                    </TableBody>
                                </TableCustom>
                            </TableContainerCustom>
                        </Grid>
                    </Grid>
                </Grid>
            </MainGrid>
            <ItemNameUpsertDialog
                isOpen={dialogOpen.create}
                onCloseHandle={() => {
                    setDialogOpen({
                        ...dialogOpen,
                        create: false
                    });
                    setTypeSelected(undefined);
                }}
                upsertItemName={addFieldInItems}/>
            <ItemNameUpsertDialog
                isOpen={dialogOpen.update}
                onCloseHandle={() => {
                    setDialogOpen({
                        ...dialogOpen,
                        update: false
                    });
                    setValueSelected('');
                    setFieldSelected(undefined);
                }}
                name={valueSelected}
                upsertItemName={updateFieldInItems}/>
        </Aux>
    );
};

const MainGrid = styled(Grid)(
    ({theme}) => ({
        padding: theme.spacing(3),
        backgroundColor: 'white',
        height: '100%',
    }),
);

const TableContainerCustom: any = styled(TableContainer)(
    () => ({
        backgroundColor: 'white',
        border: `1px solid ${colors.teal[200]}`,
    }),
);

const TableCustom = styled(Table)(
    () => ({

    }),
);

const Caption = styled('div')(
    () => ({
        textAlign: 'center',
    }),
);

const Wrapper = styled('div')(
    () => ({
        position: 'relative',
    }),
);

const ButtonProgress = styled(CircularProgress)(
    () => ({
        color: colors.green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }),
);

const RowHeader = styled(TableRow)(
    () => ({
        backgroundColor: colors.teal[100],
    }),
);

const TableRowCustom = styled(TableRow)(
    () => ({
        backgroundColor: colors.teal[50],
    }),
);

const AddFieldRow = styled(TableRow)(
    () => ({
        backgroundColor: 'white',
    }),
);

const AddFieldAction = styled(IconButton)(
    () => ({

    }),
);

export default Template;