import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Collapse,
    colors,
    IconButton,
    styled,
    Typography
} from '@mui/material';
import {
    Delete,
    Edit,
    ExpandMore as ExpandMoreIcon,
    FileCopy,
    LocalOffer,
    MoreVert as MoreVertIcon,
    Minimize
} from '@mui/icons-material';

import Aux from '../../hoc/Auxi';
import MenuList from '../Menu/MenuList';
import Image from './Image';
import {Status} from '../../containers/collection/requests/Items';

interface ListItemProps {
    readonly name: string;
    readonly status: Status;
    readonly description: string;
    readonly image: string;
    readonly tags?: any[] | undefined;
    readonly hasAddInput: boolean;
    readonly addInput: any[];
    createdDate: () => string;
    openDeleteDialog?: () => void;
    editContent: () => void;
    duplicateContent?: () => void;
}

const ListItem: any = (props: ListItemProps) => {
    const {
        name,
        status,
        description,
        tags,
        hasAddInput,
        addInput,
        image,
        createdDate,
        openDeleteDialog,
        editContent,
        duplicateContent
    } = props;

    const [anchorEl, setAnchorEl] = useState(null),
        [open, setOpen] = useState(false),
        [expanded, setExpanded] = useState(false);

    const [imageSrc, setImageSrc] = useState<React.ReactNode | undefined>(undefined);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const menuListId: string = 'menu-item-list';

    const menuContent: any[] = [
        {
            label: 'Modifier',
            onClick: editContent,
            icon: (<Edit/>),
        },
        {
            label: 'Dupliquer',
            onClick: duplicateContent,
            icon: (<FileCopy/>),
        },
        {
            label: 'Supprimer',
            onClick: openDeleteDialog,
            icon: (<Delete/>),
        }
    ];

    useEffect(() => {
        setImageSrc(
            <Image name={name} image={image} isItem/>
        );
    }, [image, name])

    useEffect(() => {
        setOpen(Boolean(anchorEl));
    }, [anchorEl]);

    const menu = (
        <Aux>
            <IconButton
                aria-label="More"
                aria-controls={menuListId}
                aria-haspopup="true"
                onClick={event => setAnchorEl(event.currentTarget as any)}
                size="large">
                <MoreVertIcon/>
            </IconButton>
            { open && openDeleteDialog && duplicateContent && <MenuList
                idMenu={menuListId}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                menuContent={menuContent}
                isItem/>}
        </Aux>
    );

    return (
        <Grid>
            <CardCustom isWanted={status === Status.Wanted}>
                <CardHeader
                    avatar={
                        <AvatarCustom isDouble={status === Status.Double} variant='rounded'>
                            {status === Status.Double && (
                                <Aux>
                                    x2<LocalOffer fontSize="small"/>
                                </Aux>
                            )}
                            {status === Status.Wanted && (<Minimize/>)}
                            {(status !== Status.Double && status !== Status.Wanted) && (<LocalOffer/>)}
                        </AvatarCustom>
                    }
                    title={name}
                    subheader={createdDate()}
                    action={(openDeleteDialog && duplicateContent) ? menu : null}/>
                <CardActionAreaCustom onClick={editContent}>
                    {imageSrc}
                    {(description || (tags && tags.length)) ? (<CardContent>
                        {description && <ElemArea>
                            <ElemLabel
                                color="secondary"
                                display="inline">
                                Description :
                            </ElemLabel>
                            <ElemInfo
                                color="textSecondary"
                                display="inline">
                                {description}
                            </ElemInfo>
                        </ElemArea>}
                        {(tags && tags.length) ? (<ElemArea>
                            <ElemLabel
                                color="secondary"
                                display="inline">
                                Tags :
                            </ElemLabel>
                            <TagArea
                                display="inline"
                                component="div">
                                {tags.map((tag: any, index: number) => (
                                    <TagInfo
                                        key={index}
                                        variant={'outlined'}
                                        label={tag.name || 'ko'}
                                        size="small"/>
                                ))}
                            </TagArea>
                        </ElemArea>) : null}
                    </CardContent>) : null}
                </CardActionAreaCustom>
                {hasAddInput && <CardActions disableSpacing>
                    <IconButtonCustom
                        expanded={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="voir plus"
                        size="large">
                        <ExpandMoreIcon/>
                    </IconButtonCustom>
                </CardActions>}
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        {addInput && addInput.map((inputElem: any, index: number) => (
                            <ElemArea key={index}>
                                <ElemLabel
                                    color="secondary"
                                    display="inline">
                                    {inputElem.label} :
                                </ElemLabel>
                                <ElemInfo
                                    color="textSecondary"
                                    display="inline">
                                    {inputElem.value}
                                </ElemInfo>
                            </ElemArea>
                        ))}
                    </CardContent>
                </Collapse>
            </CardCustom>
        </Grid>
    );
};

const Grid = styled('div')(
    ({theme}) => ({
        padding: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '33%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '25%',
        },
    }),
);

const CardCustom = styled(Card)<{isWanted: boolean}>(
    ({theme, isWanted}) => ({
        height: '100%',
        backgroundColor: isWanted ? colors.grey[300] : theme.custom.cardItem.backgroundColor,
        border: `1px solid ${isWanted ? colors.grey[400] : colors.teal[200]}`,
        opacity: isWanted ? 0.7 : undefined,
    }),
);

const AvatarCustom = styled(Avatar)<{isDouble: boolean}>(
    ({theme, isDouble}) => ({
        color: isDouble ? colors.amber[500] : theme.custom.avatarItem.color,
        backgroundColor: theme.custom.avatarItem.backgroundColor,
        fontSize: '14px',
    }),
);

const CardActionAreaCustom = styled(CardActionArea)(
    () => ({
        backgroundColor: colors.teal[50],
    }),
);

const ElemArea = styled('div')(
    ({theme}) => ({
        marginTop: theme.spacing(1),
    }),
);

const ElemLabel = styled(Typography)(
    () => ({
        fontWeight: 'bold',
    }),
);

const ElemInfo = styled(Typography)(
    ({theme}) => ({
        paddingLeft: theme.spacing(1),
    }),
);

const TagArea: any = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(1),
    }),
);

const TagInfo = styled(Chip)(
    ({theme}) => ({
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    }),
);

const IconButtonCustom = styled(IconButton)<{expanded: boolean}>(
    ({theme, expanded}) => ({
        transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
        margin: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }),
);

export default ListItem;