import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    CircularProgress,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    colors,
    styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
    Close as CloseIcon
} from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ItemDeleteDialogProps {
    readonly isOpen: boolean;
    readonly deleteLoading: boolean;
    readonly item: any;
    readonly collectionId: string;
    onCloseHandle: () => void;
    deleteItem: (values: any) => void;
}

const ItemDeleteDialog: any = (props: ItemDeleteDialogProps) => {
    const {
        isOpen,
        deleteLoading,
        item,
        collectionId,
        onCloseHandle,
        deleteItem
    } = props;

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseHandle}
            fullScreen={isMobile}
            TransitionComponent={isMobile ? Transition : undefined}
            aria-labelledby="form-dialog-item-delete-title">
            {!isMobile && <DialogTitleCustom
                id="form-dialog-item-delete-title">
                Suppression d'un objet
            </DialogTitleCustom>}
            {isMobile && <AppBarCustom>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onCloseHandle}
                        aria-label="Close"
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Title variant="h6">
                        Suppression d'un objet
                    </Title>
                </Toolbar>
            </AppBarCustom>}
            <DeleteArea>
                <DialogContent>
                    <DialogContentText>
                        Êtes-vous sûr de vouloir supprimer l'objet <b>{item.name}</b> ?
                    </DialogContentText>
                </DialogContent>
                <DialogActionsCustom>
                    <ButtonCustom
                        variant="outlined"
                        onClick={onCloseHandle}>
                        Annuler
                    </ButtonCustom>
                    <Wrapper>
                        <ButtonCustom
                            variant="outlined"
                            color="primary"
                            onClick={() => deleteItem({
                                variables: {
                                    collectionId: collectionId,
                                    id: item.id
                                }
                            })}>
                            Supprimer
                        </ButtonCustom>
                        {deleteLoading && <ButtonProgress size={24}/>}
                    </Wrapper>
                </DialogActionsCustom>
            </DeleteArea>
        </Dialog>
    );
};

const DialogTitleCustom = styled(DialogTitle)(
    () => ({
        backgroundColor: colors.grey[50],
    }),
);

const AppBarCustom = styled(AppBar)(
    () => ({
        position: 'relative',
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(2),
        flex: 1,
    }),
);

const DeleteArea = styled('div')(
    () => ({
        backgroundColor: colors.grey[50],
        height: '100%'
    }),
);

const DialogActionsCustom = styled(DialogActions)(
    ({theme}) => ({
        width: '100%',
        margin: 0,
        padding: theme.spacing(2),
    }),
);

const ButtonCustom = styled(Button)(
    ({theme}) => ({
        marginRight: theme.spacing(1),
    }),
);

const Wrapper = styled('div')(
    () => ({
        position: 'relative',
    }),
);

const ButtonProgress = styled(CircularProgress)(
    () => ({
        color: colors.green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }),
);

export default ItemDeleteDialog;