import { gql } from '@apollo/client';

export const enum Status {
    Possessed = 'POSSESSED',
    Wanted = 'WANTED',
    Double = 'DOUBLE'
}

export interface InfoSearchInput {
    type: string;
    value: string;
    cursor?: string;
}

export interface GetCollectionVars {
    id: string;
    cursor?: string;
    page?: number;
    rowsPerPage?: number;
    infos?: InfoSearchInput[];
}

export interface Tags {
    name?: string;
}

export interface AddInput {
    label: string;
    type: number;
    value?: string;
}

export interface GetCollectionData {
    getCollection: {
        id: string;
        name: string;
        template: AddInput[];
        totalItems?: number;
        itemsBySearch?: {
            id: string;
            createdAt: Date;
            name: string;
            status: Status;
            description?: string;
            thumbnailUri?: string;
            tags: Tags[];
            hasAddInput?: boolean;
            addInput: AddInput[];
        }[];
    };
}

export const GET_COLLECTION_QUERY = gql`
    query GetCollection($id: ID!, $cursor: String, $page: Int, $rowsPerPage: Int, $infos: [InfoSearchInput!]) {
        getCollection(id: $id) {
            name
            template {
                label
                type
            }
            totalItems
            itemsBySearch(cursor: $cursor, page: $page, rowsPerPage: $rowsPerPage, infos: $infos) {
                id
                createdAt
                name
                status
                description
                thumbnailUri
                tags {
                    name
                }
                hasAddInput
                addInput {
                    label
                    type
                    value
                }
            }
        }
    }
`;

export interface GetCollectionsVars {
    cursor?: string;
    infos?: InfoSearchInput[];
}

export interface GetCollectionsData {
    getCollections: {
        id: string;
        createdAt: Date;
        name: string;
        description?: string;
        template: AddInput[];
        totalItems?: number;
    }[];
}

export const GET_COLLECTIONS_QUERY = gql`
    query GetCollections($cursor: String, $infos: [InfoSearchInput!]) {
        getCollections(cursor: $cursor, infos: $infos) {
            id
            createdAt
            name
            description
            template {
                label
                type
            }
            totalItems
        }
    }
`;

export interface DeleteItemVars {
    id: string;
    collectionId: string;
}

export interface DeleteItemData {
    deleteItem: {
        id: string;
    };
}

export const DELETE_ITEM_MUTATE = gql`
    mutation DeleteItem($collectionId: ID!, $id: ID!) {
        deleteItem(collectionId: $collectionId, id: $id) {
            id
        }
    }
`;

export interface DuplicateItemVars {
    id: string;
    collectionId: string;
}

export interface DuplicateItemData {
    duplicateItem: {
        id: string;
        createdAt: Date;
        name: string;
        status: Status;
        description?: string;
        thumbnailUri?: string;
        tags: Tags[];
        hasAddInput?: boolean;
        addInput: AddInput[];
    };
}

export const DUPLICATE_ITEM_MUTATE = gql`
    mutation DuplicateItem($collectionId: ID!, $id: ID!) {
        duplicateItem(collectionId: $collectionId, id: $id) {
            id
            createdAt
            name
            status
            description
            thumbnailUri
            tags {
                name
            }
            hasAddInput
            addInput {
                label
                type
                value
            }
        }
    }
`;

export interface AddInputInput {
    label: string;
    type: number;
    value?: string;
}

export interface UpsertTemplateCollectionInput {
    template?: AddInputInput[];
}

export interface UpsertTemplateCollectionVars {
    id: string;
    data: UpsertTemplateCollectionInput;
}

export interface UpsertTemplateCollectionData {
    upsertTemplateCollection: {
        id: string;
        template: AddInput[];
    };
}

export const UPSERT_TEMPLATE_COLLECTION_MUTATE = gql`
    mutation UpsertTemplateCollection($id: ID!, $data: UpsertTemplateCollectionInput!) {
        upsertTemplateCollection(id: $id, data: $data) {
            id
            template {
                label
                type
            }
        }
    }
`;

export interface CreateMultipleItemVars {
    collectionId: string;
    data: {
        name: string;
        status: Status;
    }[];
}

export interface CreateMultipleItemData {
    createMultipleItem: {
        id: string;
        createdAt: Date;
        name: string;
        status: Status;
        description?: string;
        thumbnailUri?: string;
        tags: Tags[];
        hasAddInput?: boolean;
        addInput: AddInput[];
    }[];
}

export const CREATE_MULTIPLE_ITEM_MUTATE = gql`
    mutation CreateMultipleItem($collectionId: ID!, $data: [CreateMultipleInput]!) {
        createMultipleItem(collectionId: $collectionId, data: $data) {
            id
            createdAt
            name
            status
            description
            thumbnailUri
            tags {
                name
            }
            hasAddInput
            addInput {
                label
                type
                value
            }
        }
    }
`;

