import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

import Auth from './services/auth/Auth';
import Menu from './components/Menu/Menu';
import Aux from './hoc/Auxi';
import { useGlobalDispatch, useGlobalState } from './shared/global-context';
import SubscribeDialog from './components/Dialogs/SubscribeDialog';
import SearchDialog from './components/Dialogs/SearchDialog';
import BetaDialog from './components/Dialogs/BetaDialog';
import SupportDialog from './components/Dialogs/SupportDialog';
import TermsDialog from './components/Dialogs/TermsDialog';
import {
    ACCOUNT_BY_ID_QUERY, AccountByIdData, AccountByIdVars,
    SUBSCRIBE_AUTO_BETA_MUTATION, SubscribeAutoBetaData, SubscribeAutoBetaVars,
    ACCEPT_TERM_OF_USE_MUTATION, AcceptTermOfUseData, AcceptTermOfUseVars,
    SEND_SUPPORT_EMAIL_MUTATION, SendSupportEmailData, SendSupportEmailVars
} from './requests/App';

interface AppProps {
    readonly auth: Auth;
    readonly isAuth: boolean;
    setIsAuth: (isAuth: boolean) => void;
}

const logoutHandle: any = (props: AppProps) => {
    props.auth.logout();
};

const App = (props: AppProps) => {
    const globalDispatch = useGlobalDispatch();
    const globalState = useGlobalState();
    const snackbar = useSnackbar();
    const location = useLocation();

    const [isOpenDialogBeta, setIsOpenDialogBeta] = useState(false);
    const [isOpenDialogTerms, setIsOpenDialogTerms] = useState(false);

    const [getAccount, { data: queryData, refetch: refetchData }] = useLazyQuery<AccountByIdData, AccountByIdVars>(ACCOUNT_BY_ID_QUERY);

    const [
        subscribeAutoBeta
    ] = useMutation<SubscribeAutoBetaData, SubscribeAutoBetaVars>(
        SUBSCRIBE_AUTO_BETA_MUTATION,
        {
            update(cache, { data }) {
                if (data?.subscribeAutoBeta) {
                    cache.writeQuery<AccountByIdData, AccountByIdVars>({
                        query: ACCOUNT_BY_ID_QUERY,
                        data: { accountById: data.subscribeAutoBeta }
                    });
                    if (data.subscribeAutoBeta.subscriptionData && data.subscribeAutoBeta.subscriptionData.isActive) {
                        globalDispatch({
                            type: 'updateSubscribeState',
                            isActive: data.subscribeAutoBeta.subscriptionData.isActive
                        });
                    }
                }
            },
            onCompleted(data) {
                setIsOpenDialogBeta(true);
                setIsOpenDialogTerms(false);
            }
        }
    );

    const [
        acceptTermOfUse,
        {loading: acceptTermOfUseLoading}
    ] = useMutation<AcceptTermOfUseData, AcceptTermOfUseVars>(
        ACCEPT_TERM_OF_USE_MUTATION,
        {
            update(cache, { data }) {
                if (data?.acceptTermOfUse) {
                    cache.writeQuery<AccountByIdData, AccountByIdVars>({
                        query: ACCOUNT_BY_ID_QUERY,
                        data: { accountById: data.acceptTermOfUse }
                    });
                }
            },
            onCompleted(data) {
                setIsOpenDialogBeta(false);
                setIsOpenDialogTerms(false);
            }
        }
    );

    const [
        sendSupportEmail,
        {loading: sendSupportEmailLoading}
    ] = useMutation<SendSupportEmailData, SendSupportEmailVars>(
        SEND_SUPPORT_EMAIL_MUTATION,
        {
            update(cache, { data }) {
                if (data?.sendSupportEmail) {
                    snackbar.enqueueSnackbar('Votre message a bien été transmis', {
                        variant: 'success'
                    });
                } else {
                    snackbar.enqueueSnackbar(`Votre message n'a pas été transmis`, {
                        variant: 'error'
                    });
                }
            },
            onCompleted(data) {
                globalDispatch({
                    type: 'updateModalSupport',
                    isOpen: false
                });
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    /*eslint-disable */
    useEffect(() => {
        if (props.auth.isAuthenticated()) {
            if (!props.isAuth) {
                props.setIsAuth(true);
            }
            getAccount();
        } else {
            if (props.isAuth) {
                props.auth.renewSession();
            } else {
                if (!location.pathname.includes('signin') && !location.pathname.includes('signup') && !location.pathname.includes('callback')) {
                    props.auth.logout(true);
                }
            }
            props.setIsAuth(false);
        }
    }, [props]);

    useEffect(() => {
        if (queryData && queryData.accountById && queryData.accountById.subscriptionData) {
            globalDispatch({
                type: 'updateSubscribeState',
                isActive: queryData.accountById.subscriptionData.isActive || false
            });
            globalDispatch({
                type: 'updateTotalCollections',
                totalCollections: queryData.accountById.totalCollections || 0
            });
            if (!queryData.accountById.subscriptionData.isActive) {
                subscribeAutoBeta();
            } else {
                if (!queryData.accountById.termAccepted && !isOpenDialogBeta) {
                    setIsOpenDialogTerms(true);
                }
            }
        }
        if (queryData && (!queryData.accountById || !queryData.accountById.subscriptionData)) {
            setTimeout(() => {
                refetchData && refetchData();
            }, 1000)
        }
    }, [queryData]);
    /*eslint-enable */

    return (
        <Aux>
            <Menu
                logoutHandle={logoutHandle}
                {...props}>
            </Menu>
            {props.auth.isAuthenticated() && <SubscribeDialog
                isOpen={globalState.modalSubscribe.isOpen}
                onCloseHandle={() => {
                    globalDispatch({
                        type: 'updateModalSubscribe',
                        isOpen: false
                    });
                }}
                account={queryData ? queryData.accountById : null}/>}
            {props.auth.isAuthenticated() && <SearchDialog
                isOpen={globalState.modalSearch.isOpen}
                onCloseHandle={() => {
                    globalDispatch({
                        type: 'updateModalSearch',
                        isOpen: false,
                        search: ''
                    });
                }}/>}
            {props.auth.isAuthenticated() && <SupportDialog
                isOpen={globalState.modalSupport.isOpen}
                supportLoading={sendSupportEmailLoading}
                supportSend={sendSupportEmail}
                onCloseHandle={() => {
                    globalDispatch({
                        type: 'updateModalSupport',
                        isOpen: false
                    });
                }}/>}
            {props.auth.isAuthenticated() && <BetaDialog
                isOpen={isOpenDialogBeta}
                onCloseHandle={() => {

                }}
                acceptTermOfUseLoading={acceptTermOfUseLoading}
                acceptTermOfUse={acceptTermOfUse}/>}
            {props.auth.isAuthenticated() && <TermsDialog
                isOpen={isOpenDialogTerms}
                onCloseHandle={() => {

                }}
                acceptTermOfUseLoading={acceptTermOfUseLoading}
                acceptTermOfUse={acceptTermOfUse}/>}
        </Aux>
    );
};

export default App;
