import React from 'react';
import {
    Grid,
    Skeleton,
    styled
} from '@mui/material';

interface SkeletonItemUpsertProps {

}

const SkeletonItemUpsert: any = (props: SkeletonItemUpsertProps) => {
    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" width="100%" height={200} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <InputArea item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Skeleton height={40} width="100%" />
                        </InputArea>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <InputArea item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Skeleton height={40} width="100%" />
                        </InputArea>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <InputArea item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Skeleton height={40} width="100%" />
                        </InputArea>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

const Container = styled('div')(
    () => ({
        display: 'flex',
        flexWrap: 'wrap',
    }),
);

const InputArea = styled(Grid)(
    () => ({
        marginLeft: 'auto',
        marginRight: 'auto',
    }),
);

export default SkeletonItemUpsert;