import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import { ApolloClient, InMemoryCache, HttpLink, concat, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error'
import Cookies from 'js-cookie';
import { Elements as StripeProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
    ThemeProvider,
    Theme,
    StyledEngineProvider,
    createTheme,
} from '@mui/material/styles';
import { cyan, teal } from '@mui/material/colors';

import './index.css';
import MakeMainRoutes from './routes';
import * as serviceWorker from './serviceWorker';
import Auth from './services/auth/Auth';
import { GlobalProvider } from './shared/global-context';
import { Config } from './utils/config';

const auth: Auth = new Auth();

const stripePromise = loadStripe(Config.stripePublicKey);

const httpLink = new HttpLink({
    uri: Config.apiUri
});

const authLink = setContext((_, { headers }) => {
    const token = Cookies.get('idToken');
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    }
});

const logoutLink = onError(({graphQLErrors}) => {
    console.log(graphQLErrors);
    if (graphQLErrors &&
        graphQLErrors.length > 0) {
        if (graphQLErrors[0].extensions &&
            graphQLErrors[0].extensions.code === 'UNAUTHENTICATED') {
            auth.logout(true);
        }
        if (graphQLErrors[0].message === 'Not Authorised!') {
            if (!auth.isAuthenticated()) {
                auth.logout(true);
            }
        }
    }
});

const client = new ApolloClient({
    link: concat(logoutLink, concat(authLink, httpLink)),
    cache: new InMemoryCache()
});

const apollo = (
    <ApolloProvider client={client}>
        <MakeMainRoutes/>
    </ApolloProvider>
);

const stripe = (
    <StripeProvider stripe={stripePromise}>
        {apollo}
    </StripeProvider>
);

const global = (
    <GlobalProvider>
        {stripe}
    </GlobalProvider>
);

declare module '@mui/material/styles' {
    interface Theme {
        config: {
            color: {
                primary: {
                    dark: string;
                    light: string;
                };
                secondary: {
                    dark: string;
                    light: string;
                };
                without: string;
            }
        }
        custom: {
            avatarCollection: {
                color: string;
                backgroundColor: string;
            };
            avatarItem: {
                color: string;
                backgroundColor: string;
            };
            iconCollection: {
                color: string;
            };
            iconItem: {
                color: string;
            };
            cardItem: {
                backgroundColor: string;
            };
            addButton: {
                backgroundColor: string,
            };
            listItem: {
                color: string,
            };
        };
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        config?: {
            color?: {
                primary?: {
                    dark?: string;
                    light?: string;
                };
                secondary?: {
                    dark?: string;
                    light?: string;
                };
                without?: string;
            };
        };
        custom?: {
            avatarCollection?: {
                color?: string;
                backgroundColor?: string;
            };
            avatarItem?: {
                color?: string;
                backgroundColor?: string;
            };
            iconCollection?: {
                color?: string;
            };
            iconItem?: {
                color?: string;
            };
            cardItem?: {
                backgroundColor?: string;
            };
            addButton?: {
                backgroundColor?: string,
            };
            listItem?: {
                color?: string,
            };
        };
    }
}

export let themeCustom = createTheme({
    config: {
        color: {
            primary: {
                dark: cyan["800"],
                light: cyan["100"],
            },
            secondary: {
                dark: teal["800"],
                light: teal["100"],
            },
            without: "#FFF"
        },
    },
});

themeCustom = createTheme(themeCustom, {
    palette: {
        primary: {
            main: themeCustom.config.color.primary.dark,
        },
        secondary: {
            main: themeCustom.config.color.secondary.dark,
        },
        background: {
            paper: themeCustom.config.color.primary.light,
            default: themeCustom.config.color.without,
        },
    },
    custom: {
        avatarCollection: {
            color: themeCustom.config.color.without,
            backgroundColor: themeCustom.config.color.primary.dark,
        },
        avatarItem: {
            color: themeCustom.config.color.without,
            backgroundColor: themeCustom.config.color.secondary.dark,
        },
        iconCollection: {
            color: themeCustom.config.color.primary.dark,
        },
        iconItem: {
            color: themeCustom.config.color.secondary.dark,
        },
        cardItem: {
            backgroundColor: themeCustom.config.color.secondary.light,
        },
        addButton: {
            backgroundColor: themeCustom.config.color.secondary.dark,
        },
        listItem: {
            color: themeCustom.config.color.primary.dark,
        },
    },
} as Theme);

const theme = (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeCustom}>
            {global}
        </ThemeProvider>
    </StyledEngineProvider>
);

const snackbar = (
    <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}>
        {theme}
    </SnackbarProvider>
);

ReactDOM.render(snackbar, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
