import React from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    styled
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Aux from '../../../hoc/Auxi';

interface ItemProps {
    readonly label: string;
    readonly linkTo?: string;
    readonly icon: any;
    onClick?: () => void;
}

const Item: any = (props: ItemProps) => {
    const {
        label,
        linkTo,
        icon,
        onClick} = props;

    const navigate = useNavigate();

    return (
        <Aux>
            {onClick && <ListItem
                button
                onClick={() => {
                    if (linkTo) {
                        navigate(linkTo);
                    }
                    onClick();
                }}>
                <ListItemIconCustom>{icon}</ListItemIconCustom>
                <ListItemText primary={label}/>
            </ListItem>}
        </Aux>
    );
};

const ListItemIconCustom = styled(ListItemIcon)(
    ({theme}) => ({
        color: theme.custom.listItem.color,
    }),
);

export default Item;