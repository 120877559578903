import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
    DialogActions,
    Button,
    TextField,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    colors,
    styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
    Close as CloseIcon
} from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ItemNameUpsertDialogProps {
    readonly isOpen: boolean;
    readonly name?: string;
    onCloseHandle: () => void;
    upsertItemName: (value: string) => void;
}

const ItemNameUpsertDialog: any = (props: ItemNameUpsertDialogProps) => {
    const {
        isOpen,
        name,
        onCloseHandle,
        upsertItemName
    } = props;

    const [nameInput, setNameInput] = useState(''),
        [nameEmpty, setNameEmpty] = useState(false);

    useEffect(() => {
        if (name) {
            setNameInput(name);
        }
    }, [name]);

    useEffect(() => {
        setNameEmpty(nameInput ? nameInput.trim() === '' : true);
    }, [nameInput]);

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseHandle}
            fullScreen={isMobile}
            TransitionComponent={isMobile ? Transition : undefined}
            aria-labelledby="form-dialog-item-name-upsert-title">
            {!isMobile && <DialogTitleCustom
                id="form-dialog-item-name-upsert-title">
                Choix nom du champs
            </DialogTitleCustom>}
            {isMobile && <AppBarCustom>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onCloseHandle}
                        aria-label="Close"
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Title variant="h6">
                        Choix nom du champs
                    </Title>
                </Toolbar>
            </AppBarCustom>}
            <UpsertArea>
                <DialogContent>
                    <DialogContentText>
                        {name
                            ? 'Pour modifier le nom du champs, veuillez modifier le nom de celui-ci'
                            : 'Pour ajouter un nouveaux champs, veuillez saisir le nom du champs.'}
                    </DialogContentText>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <TextFieldCustom
                                label="Nom"
                                autoFocus={true}
                                value={nameInput}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setNameInput(e.target.value)}
                                onKeyPress={(e: React.KeyboardEvent) => {
                                    if (e.key === 'Enter') {
                                        upsertItemName(nameInput.trim());
                                        setNameInput('');
                                    }
                                }}
                                helperText={nameEmpty && 'Le nom est obligatoire'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                                error={nameEmpty}
                                fullWidth
                                margin="normal"
                                variant="outlined"/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActionsCustom>
                    <ButtonCustom
                        variant="outlined"
                        onClick={onCloseHandle}>
                        Annuler
                    </ButtonCustom>
                    <ButtonCustom
                        variant="outlined"
                        color="primary"
                        disabled={nameEmpty}
                        onClick={() => {
                            upsertItemName(nameInput.trim());
                            setNameInput('');
                        }}>
                        {name ? 'Modifier' : 'Ajouter'}
                    </ButtonCustom>
                </DialogActionsCustom>
            </UpsertArea>
        </Dialog>
    );
};

const DialogTitleCustom = styled(DialogTitle)(
    () => ({
        backgroundColor: colors.grey[50],
    }),
);

const AppBarCustom = styled(AppBar)(
    () => ({
        position: 'relative',
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(2),
        flex: 1,
    }),
);

const UpsertArea = styled('div')(
    () => ({
        backgroundColor: colors.grey[50],
        height: '100%',
    }),
);

const TextFieldCustom = styled(TextField)(
    () => ({

    }),
);

const DialogActionsCustom = styled(DialogActions)(
    ({theme}) => ({
        width: '100%',
        margin: 0,
        padding: theme.spacing(2),
    }),
);

const ButtonCustom = styled(Button)(
    ({theme}) => ({
        marginRight: theme.spacing(1),
    }),
);

export default ItemNameUpsertDialog;