import React, { useEffect, useState } from 'react';
import {
    Dialog,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Divider,
    Chip,
    Paper,
    Button,
    Link,
    Skeleton,
    colors,
    styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
    Close as CloseIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import Highlighter from 'react-highlight-words';

import SearchQuery from '../Forms/SearchQuery';
import { getDateFromNow } from '../../utils/date-transform';
import { useGlobalState } from '../../shared/global-context';
import { SEARCH_QUERY, SearchData, SearchVars } from './requests/SearchDialog';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HighLight = ({ children }: any) => (
    <span style={{
        backgroundColor: "lightgray"
    }}>
        {children}
    </span>
);

interface SearchDialogProps {
    readonly isOpen: boolean;
    onCloseHandle: () => void;
}

const SearchDialog: any = (props: SearchDialogProps) => {
    const {
        isOpen,
        onCloseHandle
    } = props;

    const searchSelect = [
        {
            label: 'Tous',
            id: 'all'
        },
        /*{
            label: 'Collections',
            id: 'collections'
        },
        {
            label: 'Objets',
            id: 'items'
        }*/
    ];

    const [searchInfo, setSearchInfo] = useState([
        {
            type: 'all',
            value: ''
        }
    ]);

    const [cursor, setCursor] = useState<number|null>();
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);

    // @ts-ignore
    const handleScroll: any = ({ currentTarget }, onLoadMore: () => {}) => {
        if (currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight - 200) {
            if (cursor !== 0 && !loadMoreLoading) {
                onLoadMore();
                setLoadMoreLoading(true);
            }
        }
    }

    const [
        getSearchResult,
        {
            loading: queryLoading,
            error: queryError,
            fetchMore: queryFetchMore,
            data: queryData
        }
    ] = useLazyQuery<SearchData, SearchVars>(SEARCH_QUERY);

    const [queryMessage, setQueryMessage] = useState<any | undefined>(undefined);
    const globalState = useGlobalState();

    const navigate = useNavigate();

    const [authorizedFetchMore, setAuthorizedFetchMore] = useState(false);

    /*eslint-disable */
    useEffect(() => {
        if (isOpen) {
            const timeout = setTimeout(() => {
                if (searchInfo[0].value.length > 2) {
                    setAuthorizedFetchMore(true);
                    setCursor(null);
                    getSearchResult({
                        variables: {
                            infos: searchInfo
                        }
                    });
                } else if (authorizedFetchMore && searchInfo[0].value.length === 0) {
                    setCursor(null);
                    getSearchResult();
                }
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [searchInfo]);

    useEffect(() => {
        if (queryData && queryData.search && queryData.search.length > 0) {
            if (!cursor && queryData.search.length >= 17) {
                setCursor(queryData.search.length);
            }
        }
    }, [queryData]);

    useEffect(() => {
        if (queryLoading) {
            setQueryMessage(undefined);
        } else if (queryError) {
            setQueryMessage(`Error!: ${queryError}`);
        } else {
            if (queryData && queryData.search && queryData.search.length >= 17) {
                setCursor(queryData.search.length);
            } else {
                setCursor(0);
            }
            setQueryMessage(undefined);
        }
    }, [queryData, queryLoading, queryError]);

    /*eslint-disable */
    useEffect(() => {
        if (globalState.modalSearch.search.length >= 3) {
            setSearchInfo([
                {
                    ...searchInfo[0],
                    value: globalState.modalSearch.search
                }
            ])
        }
    }, [globalState.modalSearch.search]);
    /*eslint-enable */

    return (
        <Dialog
            fullScreen
            open={isOpen}
            onClose={onCloseHandle}
            TransitionComponent={Transition}>
            <AppBarCustom>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onCloseHandle}
                        aria-label="Close"
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Title variant="h6">
                        Recherche
                    </Title>
                </Toolbar>
            </AppBarCustom>
            <SearchArea>
                <SearchQuery
                    searchSelect={searchSelect}
                    searchInfo={searchInfo}
                    setSearchInfo={setSearchInfo}
                    isFocus={globalState.modalSearch.isOpen}
                    resetSearch={() => {
                        getSearchResult();
                        setSearchInfo([
                            {
                                ...searchInfo[0],
                                value: ''
                            }
                        ]);
                    }}/>
                {queryMessage && (<Search>
                    {queryMessage}
                </Search>)}
                <Search onScroll={(e: any) => {
                    if (cursor) {
                        handleScroll(e, () => queryFetchMore && queryFetchMore({
                            query: SEARCH_QUERY,
                            variables: (searchInfo[0].value.length > 2) ? { infos: searchInfo.map((elem) => {
                                return {...elem, cursor: cursor.toString()}
                            })} : { infos: undefined },
                            updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
                                setLoadMoreLoading(false);
                                if (fetchMoreResult && fetchMoreResult.search) {
                                    const previousEntry = previousResult.search;
                                    const newCollections = fetchMoreResult.search;
                                    if (newCollections.length >= 17) {
                                        setCursor((cursor || 0) + newCollections.length);
                                        return {
                                            search: [...previousEntry, ...newCollections]
                                        };
                                    } else if (newCollections.length > 0) {
                                        setCursor(0);
                                        return {
                                            search: [...previousEntry, ...newCollections]
                                        };
                                    } else {
                                        setCursor(0);
                                        return previousResult;
                                    }
                                } else {
                                    setCursor(0);
                                    return previousResult;
                                }
                            }
                        }));
                    }
                }}>
                    {queryData && queryData.search && queryData.search.length && queryData.search.map((elem: any) => {
                        if (queryMessage) {
                            return null;
                        }

                        return (
                            <PaperRoot key={elem.collectionId+elem.itemId}>
                                <div>
                                    <MainLabel
                                        display="inline">
                                        <Link
                                            onClick={() => {
                                                navigate(`/collections/${elem.collectionId}`);
                                                onCloseHandle();
                                            }}
                                            underline="none"
                                            color="primary">
                                            Collection :
                                        </Link>
                                    </MainLabel>
                                    <MainName
                                        display="inline">
                                        <Link
                                            onClick={() => {
                                                navigate(`/collections/${elem.collectionId}`);
                                                onCloseHandle();
                                            }}
                                            underline="none"
                                            color="textSecondary">
                                            <Highlighter
                                                searchWords={[searchInfo[0].value]}
                                                autoEscape={true}
                                                highlightTag={HighLight}
                                                textToHighlight={elem.collectionName}/>
                                        </Link>
                                    </MainName>
                                    <MainCreated
                                        color="textSecondary"
                                        display="block">
                                        Créé {getDateFromNow(elem.collectionCreatedAt)}
                                    </MainCreated>
                                </div>
                                {elem.collectionDescription && <ElemArea>
                                    <ElemLabel
                                        color="primary"
                                        display="inline">
                                        Description :
                                    </ElemLabel>
                                    <ElemInfo
                                        color="textSecondary"
                                        display="inline">
                                        <Highlighter
                                            searchWords={[searchInfo[0].value]}
                                            autoEscape={true}
                                            highlightTag={HighLight}
                                            textToHighlight={elem.collectionDescription}/>
                                    </ElemInfo>
                                </ElemArea>}
                                {elem.itemId && <ItemArea>
                                    <Divider variant="fullWidth" />
                                    <ElemArea>
                                        <MainLabel
                                            display="inline">
                                            <Link
                                                onClick={() => {
                                                    navigate(`/collections/${elem.collectionId}/${elem.itemId}`);
                                                    onCloseHandle();
                                                }}
                                                underline="none"
                                                color="secondary">
                                                Objet :
                                            </Link>
                                        </MainLabel>
                                        <MainName
                                            display="inline">
                                            <Link
                                                onClick={() => {
                                                    navigate(`/collections/${elem.collectionId}/${elem.itemId}`);
                                                    onCloseHandle();
                                                }}
                                                underline="none"
                                                color="textSecondary">
                                                <Highlighter
                                                    searchWords={[searchInfo[0].value]}
                                                    autoEscape={true}
                                                    highlightTag={HighLight}
                                                    textToHighlight={elem.itemName}/>
                                            </Link>
                                        </MainName>
                                        <MainCreated
                                            color="textSecondary"
                                            display="block">
                                            Créé {getDateFromNow(elem.itemCreatedAt)}
                                        </MainCreated>
                                    </ElemArea>
                                    {elem.itemDescription && <ElemArea>
                                        <ElemLabel
                                            color="secondary"
                                            display="inline">
                                            Description :
                                        </ElemLabel>
                                        <ElemInfo
                                            color="textSecondary"
                                            display="inline">
                                            <Highlighter
                                                searchWords={[searchInfo[0].value]}
                                                autoEscape={true}
                                                highlightTag={HighLight}
                                                textToHighlight={elem.itemDescription}/>
                                        </ElemInfo>
                                    </ElemArea>}
                                    {elem.tags && <ElemArea>
                                        <ElemLabel
                                            color="secondary"
                                            display="inline">
                                            Tags :
                                        </ElemLabel>
                                        <TagArea
                                            display="inline"
                                            component="div">
                                            {elem.tags.map((tag: string, index: number) => (
                                                <TagInfo
                                                    key={index}
                                                    variant={(tag === searchInfo[0].value ) ? undefined : 'outlined'}
                                                    label={tag}
                                                    size="small"/>
                                            ))}
                                        </TagArea>
                                    </ElemArea>}
                                    {elem.addInput && elem.addInput.map((inputElem: any, index: number) => (
                                        <ElemArea key={index}>
                                            <ElemLabel
                                                color="secondary"
                                                display="inline">
                                                {inputElem.label} :
                                            </ElemLabel>
                                            <ElemInfo
                                                color="textSecondary"
                                                display="inline">
                                                <Highlighter
                                                    searchWords={[searchInfo[0].value]}
                                                    autoEscape={true}
                                                    highlightTag={HighLight}
                                                    textToHighlight={inputElem.value}/>
                                            </ElemInfo>
                                        </ElemArea>
                                    ))}
                                </ItemArea>}
                                <ButtonArea>
                                    <ButtonCustom
                                        variant='outlined'
                                        color='primary'
                                        onClick={() => {
                                            navigate(`/collections/${elem.collectionId}`);
                                            onCloseHandle();
                                        }}>
                                        Voir la collection
                                    </ButtonCustom>
                                    <ButtonCustom
                                        variant='outlined'
                                        color='secondary'
                                        onClick={() => {
                                            navigate(`/collections/${elem.collectionId}/${elem.itemId}`);
                                            onCloseHandle();
                                        }}>
                                        Voir l'objet
                                    </ButtonCustom>
                                </ButtonArea>
                            </PaperRoot>
                        );
                    })}
                    {(queryLoading || loadMoreLoading) && (
                        <PaperRoot>
                            <div>
                                <Typography>
                                    <Skeleton height={25} width="100%" />
                                </Typography>
                                <Typography>
                                    <Skeleton height={15} width="80%" />
                                </Typography>
                            </div>
                            <ElemArea>
                                <Typography>
                                    <Skeleton height={20} width="100%" />
                                </Typography>
                            </ElemArea>
                            <ItemArea>
                                <Divider variant="fullWidth" />
                                <ElemArea>
                                    <Typography>
                                        <Skeleton height={25} width="100%" />
                                    </Typography>
                                    <Typography>
                                        <Skeleton height={15} width="80%" />
                                    </Typography>
                                </ElemArea>
                                <ElemArea>
                                    <Typography>
                                        <Skeleton height={20} width="100%" />
                                    </Typography>
                                </ElemArea>
                                <ElemArea>
                                    <Typography>
                                        <Skeleton height={20} width="100%" />
                                    </Typography>
                                </ElemArea>
                                <ElemArea>
                                    <Typography>
                                        <Skeleton height={20} width="100%" />
                                    </Typography>
                                </ElemArea>
                            </ItemArea>
                            <SkeletonButtonArea>
                                <Typography display="inline">
                                    <SkeletonButton variant="rectangular" width={100} height={30} />
                                </Typography>
                                <Typography display="inline">
                                    <SkeletonButton variant="rectangular" width={100} height={30} />
                                </Typography>
                            </SkeletonButtonArea>
                        </PaperRoot>
                    )}
                </Search>
            </SearchArea>
        </Dialog>
    );
}

const AppBarCustom = styled(AppBar)(
    () => ({
        position: 'relative',
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(2),
        flex: 1,
    }),
);

const SearchArea = styled('div')(
    ({theme}) => ({
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        backgroundColor: colors.grey[50],
    }),
);

const Search = styled('div')(
    ({theme}) => ({
        height: 'calc(100vh - 152px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: theme.spacing(2),
    }),
);

const PaperRoot = styled(Paper)(
    ({theme}) => ({
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#fff',

        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '50%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '50%',
        },
    }),
);

const MainLabel = styled(Typography)(
    () => ({
        fontSize: 'large',
        fontWeight: 'bold',
        cursor: 'pointer',
    }),
);

const MainName = styled(Typography)(
    ({theme}) => ({
        fontSize: 'large',
        fontWeight: 'bold',
        paddingLeft: theme.spacing(1),
        cursor: 'pointer',
    }),
);

const MainCreated = styled(Typography)(
    () => ({
        fontSize: 'small',
    }),
);

const ElemArea = styled('div')(
    ({theme}) => ({
        marginTop: theme.spacing(1),
    }),
);

const ElemLabel = styled(Typography)(
    () => ({
        fontWeight: 'bold',
    }),
);

const ElemInfo = styled(Typography)(
    ({theme}) => ({
        paddingLeft: theme.spacing(1),
    }),
);

const ItemArea = styled('div')(
    ({theme}) => ({
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(4),
    }),
);

const TagArea: any = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(1),
    }),
);

const TagInfo = styled(Chip)(
    ({theme}) => ({
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    }),
);

const ButtonArea = styled('div')(
    ({theme}) => ({
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
        },
        [theme.breakpoints.up('lg')]: {
            textAlign: 'right',
        },
        [theme.breakpoints.up('xl')]: {
            textAlign: 'right',
        },
    }),
);

const ButtonCustom = styled(Button)(
    ({theme}) => ({
        margin: theme.spacing(1),
    }),
);

const SkeletonButtonArea = styled('div')(
    () => ({
        textAlign: 'right',
    }),
);

const SkeletonButton = styled(Skeleton)(
    ({theme}) => ({
        display: 'inline-block',
        margin: theme.spacing(0.5),
    }),
);

export default SearchDialog;