import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

import BreadcrumbsApp from '../../components/Breadcrumbs/BreadcrumbsApp';

interface ExchangesProps {

}

const Exchanges : any = (props: ExchangesProps) => {
    const navigate = useNavigate();

    /*eslint-disable */
    const [dialogOpen, setDialogOpen] = useState({
        create: false,
        update: false
    });
    /*eslint-enable */

    const links = [
        {
            label: <HomeIconCustom/>,
            onClick: () => navigate('/')
        }
    ];

    return (
        <ExchangeArea>
            <BreadcrumbsApp
                links={links}
                lastElem="Echanges"/>
            <div>
                test
            </div>
        </ExchangeArea>
    );
};

const ExchangeArea = styled('div')(
    ({theme}) => ({
        height: 'calc(100vh - 128px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: theme.spacing(2),
    }),
);

const HomeIconCustom = styled(HomeIcon)(
    ({theme}) => ({
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }),
);

export default Exchanges;