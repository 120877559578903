import React, { useEffect, useState } from 'react';
import {
    Grid,
    TextField,
    IconButton,
    Fab,
    CircularProgress,
    Badge,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
    Typography,
    Rating,
    Menu,
    MenuItem,
    Alert,
    colors,
    styled,
    useTheme,
    Autocomplete,
    Chip
} from '@mui/material';
import {
    Check,
    Clear,
    Lock,
    MoreVert as MoreVertIcon
} from '@mui/icons-material';
import { DropzoneArea } from 'react-mui-dropzone';

import Aux from '../../hoc/Auxi';
import { InputType } from '../Collection/AddField';
import { useGlobalDispatch, useGlobalState } from '../../shared/global-context';

interface ItemUpsertProps {
    readonly upsertLoading: boolean;
    readonly item: any;
    readonly collectionId: string;
    readonly getCollection?: any;
    readonly nameExist?: boolean;
    upsertItem: (value: any) => void;
    setItem: (value: any) => void;
    deleteFieldInItems: (value: number) => void;
    setFieldSelected: (value: number) => void;
    setAddMore?: (value: boolean) => void;
    cancelUpsert?: () => void;
    setFile: (file: File) => void;
}

const ItemUpsert: any = (props: ItemUpsertProps) => {
    const {
        upsertLoading,
        item,
        collectionId,
        upsertItem,
        setItem,
        deleteFieldInItems,
        setFieldSelected,
        getCollection,
        setAddMore,
        cancelUpsert,
        setFile,
        nameExist
    } = props;
    const theme = useTheme();

    const [elemEmpty, setElemEmpty] = useState({
        name: false
    });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [addInputMenuIndex, setAddInputMenuIndex] = useState(0);

    const handleClickAddInput = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAddInput = () => {
        setAnchorEl(null);
    };

    const menuAddInputContent = [
        {
            label: 'Modifier',
            onClick: (index: number) => {
                setFieldSelected(index);
                handleCloseAddInput();
            }
        },
        {
            label: 'Supprimer',
            onClick: (index: number) => {
                deleteFieldInItems(index);
                handleCloseAddInput();
            }
        }
    ];

    const globalDispatch = useGlobalDispatch();
    const globalState = useGlobalState();

    useEffect(() => {
        setElemEmpty({
            name: (item.name ? item.name.trim() === '' : true)
        });
    }, [item]);

    /*eslint-disable */
    useEffect(() => {
        if (getCollection) {
            setItem({
                ...item,
                addInput: getCollection.template.map((elem: any) => {
                    return {
                        type: elem.type,
                        label: elem.label,
                        value: ''
                    };
                })
            });
        }
    }, [getCollection]);
    /*eslint-enable */

    const saveHandle = () => {
        if (!item || !item.id) {
            setAddMore && setAddMore(true);
        } else {
            setAddMore && setAddMore(false);
        }
        upsertItem({
            variables: {
                collectionId: collectionId,
                id: item ? item.id : null,
                data: {
                    name: item.name ? item.name.trim() || null : null,
                    status: item.status,
                    description: item.description ? item.description.trim() || null : null,
                    tags: (item.tags.length > 0) ? item.tags.map((elem: string) => {
                        return { name: elem }
                    }) : null,
                    addInput: (item.addInput.length > 0) ? item.addInput.map((elem: any) => {
                        return {
                            label: elem.label ? elem.label.trim() || null : null,
                            type: elem.type,
                            value: elem.value ? `${elem.value}`.trim() || null : null
                        };
                    }) : null
                }
            }
        });
    };

    return (
        <Aux>
            <Container
                noValidate
                autoComplete="off">
                <Grid container spacing={1}>
                    <ImgArea item xs={12}>
                        {!item.thumbnailUri && <DropzoneAreaCustom
                            acceptedFiles={['image/*']}
                            dropzoneText="Déposez votre image ici ou cliquez ici (< 5Mo)"
                            maxFileSize={5000000}
                            filesLimit={1}
                            showAlerts={false}
                            showPreviews={true}
                            showPreviewsInDropzone={false}
                            useChipsForPreview={false}
                            previewText=""
                            previewGridProps={{
                                item: {
                                    xs: 12,
                                    style: {
                                        [theme.breakpoints.up('xs')]: {
                                            maxWidth: 'calc(100% - 8px)',
                                        },
                                        [theme.breakpoints.up('sm')]: {
                                            maxWidth: 'calc(100% - 8px)',
                                        },
                                        [theme.breakpoints.up('md')]: {
                                            maxWidth: 'calc(100% - 8px)',
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            maxWidth: 'calc(100% - 8px)',
                                        },
                                        [theme.breakpoints.up('xl')]: {
                                            maxWidth: 'calc(100% - 8px)',
                                        },
                                        margin: 'auto',
                                        padding: '32px 0 !important',
                                        '& img': {
                                            height: 'auto !important',
                                            maxWidth: '400px',
                                            borderRadius: 5,
                                        },
                                    } as any
                                },
                                container: {
                                    spacing: 0
                                },
                            }}
                            onChange={async (files) => {
                                if (files.length) {
                                    setFile(files[0]);
                                }
                            }}/>}
                        {item.thumbnailUri && <DropzoneAreaCustom
                          acceptedFiles={['image/*']}
                          dropzoneText="Déposez votre image ici ou cliquez ici (< 5Mo)"
                          maxFileSize={5000000}
                          filesLimit={1}
                          showAlerts={false}
                          showPreviews={true}
                          showPreviewsInDropzone={false}
                          useChipsForPreview={false}
                          initialFiles={item.thumbnailUri ? [item.thumbnailUri] : undefined}
                          previewText=""
                          previewGridProps={{
                              item: {
                                  xs: 12,
                                  style: {
                                      [theme.breakpoints.up('xs')]: {
                                          maxWidth: 'calc(100% - 8px)',
                                      },
                                      [theme.breakpoints.up('sm')]: {
                                          maxWidth: 'calc(100% - 8px)',
                                      },
                                      [theme.breakpoints.up('md')]: {
                                          maxWidth: 'calc(100% - 8px)',
                                      },
                                      [theme.breakpoints.up('lg')]: {
                                          maxWidth: 'calc(100% - 8px)',
                                      },
                                      [theme.breakpoints.up('xl')]: {
                                          maxWidth: 'calc(100% - 8px)',
                                      },
                                      margin: 'auto',
                                      padding: '32px 0 !important',
                                      '& img': {
                                          height: 'auto !important',
                                          maxWidth: '400px',
                                          borderRadius: 5,
                                      },
                                  } as any
                              },
                              container: {
                                  spacing: 0
                              }
                          }}
                          onChange={async (files) => {
                              if (files.length) {
                                  setFile(files[0]);
                              }
                          }}/>}
                    </ImgArea>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <InputArea item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <TextFieldCustom
                                    label="Nom"
                                    autoFocus={true}
                                    value={item.name}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setItem({...item, name: e.target.value})}
                                    onKeyPress={(e: React.KeyboardEvent) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            saveHandle();
                                        }
                                    }}
                                    helperText={elemEmpty.name && 'Le nom est obligatoire'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                    error={elemEmpty.name}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"/>
                                {nameExist && <Alert variant="outlined" severity="info">
                                    Le nom <b>{item.name}</b> existe déjà dans la collection
                                </Alert>}
                            </InputArea>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <InputArea item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <TextFieldCustom
                                    label="Description"
                                    multiline
                                    rows={4}
                                    value={item.description || ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setItem({...item, description: e.target.value})}
                                    onKeyPress={(e: React.KeyboardEvent) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            saveHandle();
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"/>
                            </InputArea>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <InputArea item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    defaultValue={item.tags}
                                    value={item.tags}
                                    options={[]}
                                    fullWidth
                                    onChange={(event, value, reason, details) => {
                                        if (reason === 'createOption') {
                                            if (!globalState.subscribeState.isActive) {
                                                if (!item.tags.length) {
                                                    setItem({...item, tags: item.tags.concat(value)});
                                                }
                                            } else {
                                                setItem({...item, tags: item.tags.concat(value)});
                                            }
                                        } else if (reason === 'removeOption') {
                                            /*setItem({...item, tags: item.tags.filter((val:any, i:any) => {
                                                return index !== i;
                                            })});*/
                                        }
                                    }}
                                    renderTags={(value: readonly string[], getTagProps) =>
                                        value.map((option: string, index: number) => (
                                            <Chip
                                                variant="outlined"
                                                label={option}
                                                {...getTagProps({ index })}/>
                                        ))}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Tags"
                                            helperText="Touche entrer pour valider"/>
                                    )}/>
                            </InputArea>
                        </Grid>
                    </Grid>
                    {item.addInput.map((elem: any, index: number) => {
                        return (
                            <Grid key={index} item xs={12}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={2} sm={2} md={2} lg={3} xl={3}>
                                        <AddFieldAction
                                            onClick={(e) => {
                                                if (!globalState.subscribeState.isActive) {
                                                    globalDispatch({
                                                        type: "updateModalSubscribe",
                                                        isOpen: true
                                                    });
                                                } else {
                                                    setAddInputMenuIndex(index);
                                                    handleClickAddInput(e);
                                                }
                                            }}
                                            style={{
                                                backgroundColor:
                                                    globalState.subscribeState.isActive ?
                                                        undefined :
                                                        colors.grey.A100
                                            }}
                                            aria-controls="MenuAddInput"
                                            aria-haspopup="true"
                                            size="large">
                                            <Badge
                                                overlap="circular"
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                invisible={globalState.subscribeState.isActive}
                                                badgeContent={<FabBadge/>}>
                                                <MoreVertIcon/>
                                            </Badge>
                                        </AddFieldAction>
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={10} lg={6} xl={6}>
                                        {(elem.type === InputType.Rating) && <RateBox
                                            component="fieldset">
                                            <RateLabel
                                                component="legend">
                                                {elem.label || 'test'}
                                            </RateLabel>
                                            <Rating
                                                name={'rate'+index}
                                                value={Number(elem.value) || 0}
                                                onChange={(event, newValue) => {
                                                    if (globalState.subscribeState.isActive) {
                                                        setItem({...item, addInput: item.addInput.map((e: any, i: number) => {
                                                            if (i === index) {
                                                                return {
                                                                    ...e,
                                                                    value: newValue
                                                                };
                                                            }
                                                            return e;
                                                        })});
                                                    }
                                                }}
                                            />
                                        </RateBox>}
                                        {(elem.type === InputType.YesNo) && <FormControlCustom
                                            component="fieldset">
                                            <FormLabel component="legend">{elem.label || 'test'}</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label={'yn'+index}
                                                name={'yn'+index}
                                                value={elem.value || 'N'}
                                                onChange={(elemInput: React.ChangeEvent<HTMLInputElement>) => {
                                                    if (globalState.subscribeState.isActive) {
                                                        setItem({...item, addInput: item.addInput.map((e: any, i: number) => {
                                                            if (i === index) {
                                                                return {
                                                                    ...e,
                                                                    value: elemInput.target.value
                                                                };
                                                            }
                                                            return e;
                                                        })});
                                                    }
                                                }}>
                                                <FormControlLabelCustom
                                                    value="N"
                                                    control={<Radio color="primary"/>}
                                                    label="Non"
                                                    labelPlacement="start"/>
                                                <FormControlLabelCustom
                                                  value="Y"
                                                  control={<Radio color="primary"/>}
                                                  label="Oui"/>
                                            </RadioGroup>
                                        </FormControlCustom>}
                                        {(elem.type !== InputType.YesNo &&
                                            elem.type !== InputType.Rating) && <TextFieldCustom
                                            name={'tf'+index}
                                            label={elem.label || 'test'}
                                            type={(elem.type === InputType.Number) ? 'number' : undefined}
                                            multiline={elem.type === InputType.TextArea}
                                            rows={(elem.type === InputType.TextArea) ? 4 : undefined}
                                            value={elem.value || ''}
                                            onChange={(elemInput: React.ChangeEvent<HTMLInputElement>) => {
                                                if (globalState.subscribeState.isActive) {
                                                    setItem({...item, addInput: item.addInput.map((e: any, i: number) => {
                                                        if (i === index) {
                                                            const result: any = e;
                                                            result.value = elemInput.target.value;
                                                            return result;
                                                        }
                                                        return e;
                                                    })});
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: !globalState.subscribeState.isActive
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"/>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Menu
                    id="MenuAddInput"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseAddInput}>
                    {menuAddInputContent.map((menuItem: any) => {
                        return (
                            <MenuItem onClick={() => menuItem.onClick(addInputMenuIndex)}>
                                {menuItem.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </Container>
            {cancelUpsert && (
                <FabTop
                    color="default"
                    onClick={cancelUpsert}>
                    <Clear/>
                </FabTop>
            )}
            <Wrapper>
                <FabBottom
                    color="primary"
                    onClick={saveHandle}
                    disabled={elemEmpty.name || upsertLoading}>
                    <Check/>
                </FabBottom>
                {upsertLoading && <FabProgress size={68}/>}
            </Wrapper>
        </Aux>
    );
};

const Container = styled('form')(
    () => ({
        display: 'flex',
        flexWrap: 'wrap',
    }),
);

const ImgArea = styled(Grid)(
    () => ({
        textAlign: 'center',
    }),
);

const DropzoneAreaCustom = styled(DropzoneArea)(
    ({theme}) => ({
        backgroundColor: theme.custom.cardItem.backgroundColor,
    }),
);

const InputArea = styled(Grid)(
    () => ({
        marginLeft: 'auto',
        marginRight: 'auto',
    }),
);

const TextFieldCustom = styled(TextField)(
    () => ({

    }),
);

const AddFieldAction = styled(IconButton)(
    ({theme}) => ({
        [theme.breakpoints.up('xs')]: {
            float: 'left',
        },
        [theme.breakpoints.up('sm')]: {
            float: 'left',
        },
        [theme.breakpoints.up('md')]: {
            float: 'left',
        },
        [theme.breakpoints.up('lg')]: {
            float: 'right',
        },
        [theme.breakpoints.up('xl')]: {
            float: 'right',
        },
    }),
);

const FabBadge = styled(Lock)(
    () => ({
        marginTop: '-30px',
    }),
);

const RateBox = styled(Box)(
    () => ({
        marginLeft: '0',
        marginRight: '0',
        marginTop: '15px',
        marginBottom: '0',
        border: 'none',
        paddingLeft: '0',
        paddingRight: '0',
    }),
);

const RateLabel: any = styled(Typography)(
    () => ({
        lineHeight: 1,
        color: 'rgba(0, 0, 0, 0.54)',
    }),
);

const FormControlCustom: any = styled(FormControl)(
    () => ({
        marginTop: '15px',
    }),
);

const FormControlLabelCustom = styled(FormControlLabel)(
    () => ({
        margin: '0',
    }),
);

const FabTop = styled(Fab)(
    ({theme}) => ({
        position: 'absolute',
        bottom: theme.spacing(10),
        right: theme.spacing(2),
    }),
);

const Wrapper = styled('div')(
    ({theme}) => ({
        position: 'absolute',
        margin: theme.spacing(1),
        bottom: theme.spacing(0.5),
        right: theme.spacing(1),
    }),
);

const FabBottom = styled(Fab)(
    ({theme}) => ({
        backgroundColor: theme.custom.addButton.backgroundColor,
    }),
);

const FabProgress = styled(CircularProgress)(
    () => ({
        color: colors.green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    }),
);

export default ItemUpsert;