import React from 'react';
import {
    Hidden,
    Drawer,
    styled,
} from '@mui/material';

import ItemList from './Item/ItemList';

const drawerWidth = 240;

interface MenuLeftProps {
    readonly menuLeftOpen: boolean;
    readonly menuContent: any[];
    menuLeftToggleHandle: () => void;
}

const MenuLeft: any = (props: MenuLeftProps) => {
    const {
        menuLeftOpen,
        menuContent,
        menuLeftToggleHandle} = props;

    return (
        <NavDrawer>
            <Hidden smUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={menuLeftOpen}
                    onClose={menuLeftToggleHandle}
                    PaperProps={{
                        style: {
                            width: drawerWidth,
                            backgroundColor: "white",
                        }
                    }}>
                        <ItemList
                            menuContent={menuContent}/>
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    PaperProps={{
                        style: {
                            width: drawerWidth,
                            backgroundColor: "white",
                        }
                    }}
                    variant="permanent"
                    open>
                        <ItemList
                            menuContent={menuContent}/>
                </Drawer>
            </Hidden>
        </NavDrawer>
    );
};

const NavDrawer = styled('nav')(
    ({theme}) => ({
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    }),
);

export default MenuLeft;