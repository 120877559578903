import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    CircularProgress,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    colors,
    styled,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    TextField,
    Select,
    MenuItem
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { SelectChangeEvent } from '@mui/material/Select'
import {
    Close as CloseIcon
} from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

import { Status } from '../../containers/collection/requests/Items';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ItemAddMultipleDialogProps {
    readonly isOpen: boolean;
    readonly addMultipleLoading: boolean;
    readonly collectionId: string;
    onCloseHandle: () => void;
    addMultipleItem: (values: any) => void;
}

const ItemAddMultipleDialog: any = (props: ItemAddMultipleDialogProps) => {
    const {
        isOpen,
        addMultipleLoading,
        onCloseHandle,
        addMultipleItem,
        collectionId
    } = props;

    const [itemsLocal, setItemsLocal] = useState<{name: string, status: Status}[]>([
        {
            name: '',
            status: Status.Possessed
        }
    ]);

    const updateStatusItemsLocal = (index: number, value: Status) => {
        let tmpItemsLocal = itemsLocal;
        if (tmpItemsLocal[index]) {
            tmpItemsLocal[index].status = value;
            setItemsLocal([...tmpItemsLocal]);
        }
    };

    const updateNameItemsLocal = (index: number, value: string) => {
        let tmpItemsLocal = itemsLocal;
        if (tmpItemsLocal[index]) {
            tmpItemsLocal[index].name = value;
            if (value) {
                if (tmpItemsLocal.length === (index + 1)) {
                    const anchor = document.getElementById(
                        'go-to-bottom-anchor',
                    );
                    setItemsLocal([
                        ...tmpItemsLocal,
                        {
                            name: '',
                            status: Status.Possessed
                        }
                    ]);
                    if (anchor) {
                        setTimeout(() => {
                            anchor.scrollTo({
                                top: anchor.scrollHeight,
                                behavior: "smooth"
                            });
                        }, 400);
                    }
                } else {
                    setItemsLocal([...tmpItemsLocal]);
                }
            } else if (tmpItemsLocal.length === (index + 2)){
                tmpItemsLocal.splice(-1);
                setItemsLocal([...tmpItemsLocal]);
            } else {
                setItemsLocal([...tmpItemsLocal]);
            }
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseHandle}
            fullScreen={isMobile}
            TransitionComponent={isMobile ? Transition : undefined}
            aria-labelledby="form-dialog-item-delete-title">
            {!isMobile && <DialogTitleCustom
                id="form-dialog-item-add-multiple-title">
                Ajout multiple d'objet
            </DialogTitleCustom>}
            {isMobile && <AppBarCustom>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {
                            onCloseHandle();
                            setTimeout(() => {
                                setItemsLocal([
                                    {
                                        name: '',
                                        status: Status.Possessed
                                    }
                                ]);
                            }, 200);
                        }}
                        aria-label="Close"
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Title variant="h6">
                        Ajout multiple d'objet
                    </Title>
                </Toolbar>
            </AppBarCustom>}
            <AddMultipleArea>
                <DialogContent>
                    <DialogContentText>
                        Saisir votre liste d'objet à créer
                    </DialogContentText>
                    <TableContainerCustom
                        id="go-to-bottom-anchor"
                        component={Paper}>
                        <TableCustom aria-label="add multiple table">
                            <TableHead>
                                <RowHeader>
                                    <TableCell>#</TableCell>
                                    <TableCell>Nom</TableCell>
                                    <TableCell>Obtention</TableCell>
                                </RowHeader>
                            </TableHead>
                            <TableBody>
                                {itemsLocal.map((elem: any, index: number) => {
                                    return (
                                        <AddFieldRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={elem.name || ''}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        updateNameItemsLocal(index, e.target.value);
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"/>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    value={elem.status || Status.Possessed}
                                                    onChange={(e: SelectChangeEvent) => {
                                                        updateStatusItemsLocal(index, e.target.value as Status)
                                                    }}>
                                                    <MenuItem value={Status.Possessed}>Obtenu</MenuItem>
                                                    <MenuItem value={Status.Double}>En double</MenuItem>
                                                    <MenuItem value={Status.Wanted}>Recherché</MenuItem>
                                                </Select>
                                            </TableCell>
                                        </AddFieldRow>
                                    );
                                })}
                            </TableBody>
                        </TableCustom>
                    </TableContainerCustom>
                </DialogContent>
                <DialogActionsCustom>
                    <ButtonCustom
                        variant="outlined"
                        onClick={() => {
                            onCloseHandle();
                            setTimeout(() => {
                                setItemsLocal([
                                    {
                                        name: '',
                                        status: Status.Possessed
                                    }
                                ]);
                            }, 200);
                        }}>
                        Annuler
                    </ButtonCustom>
                    <Wrapper>
                        <ButtonCustom
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                /*eslint-disable */
                                addMultipleItem({
                                    variables: {
                                        collectionId: collectionId,
                                        data: itemsLocal.map(({name, status}) => {
                                            if (name) {
                                                return {
                                                    name: name,
                                                    status: status
                                                };
                                            }
                                        })
                                    }
                                });
                                /*eslint-enable */
                                setTimeout(() => {
                                    setItemsLocal([
                                        {
                                            name: '',
                                            status: Status.Possessed
                                        }
                                    ]);
                                }, 3000);
                            }}>
                            Ajouter
                        </ButtonCustom>
                        {addMultipleLoading && <ButtonProgress size={24}/>}
                    </Wrapper>
                </DialogActionsCustom>
            </AddMultipleArea>
        </Dialog>
    );
};

const DialogTitleCustom = styled(DialogTitle)(
    () => ({
        backgroundColor: colors.grey[50],
    }),
);

const AppBarCustom = styled(AppBar)(
    () => ({
        position: 'relative',
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(2),
        flex: 1,
    }),
);

const AddMultipleArea = styled('div')(
    () => ({
        backgroundColor: colors.grey[50],
        height: '100%'
    }),
);

const DialogActionsCustom = styled(DialogActions)(
    ({theme}) => ({
        width: '100%',
        margin: 0,
        padding: theme.spacing(2),
    }),
);

const ButtonCustom = styled(Button)(
    ({theme}) => ({
        marginRight: theme.spacing(1),
    }),
);

const Wrapper = styled('div')(
    () => ({
        position: 'relative',
    }),
);

const ButtonProgress = styled(CircularProgress)(
    () => ({
        color: colors.green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }),
);

const TableContainerCustom: any = styled(TableContainer)(
    () => ({
        backgroundColor: 'white',
        border: `1px solid ${colors.teal[200]}`,
        maxHeight: '400px',
        overflow: 'auto',
    }),
);

const TableCustom = styled(Table)(
    () => ({

    }),
);

const RowHeader = styled(TableRow)(
    () => ({
        backgroundColor: colors.teal[100],
    }),
);

const AddFieldRow = styled(TableRow)(
    () => ({
        backgroundColor: 'white',
    }),
);

export default ItemAddMultipleDialog;