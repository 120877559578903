import React from 'react';
import {
    useScrollTrigger,
    Zoom,
    styled
} from '@mui/material';

interface BackTopProps {
    readonly children: React.ReactElement;
    readonly element: Node;
}

const BackTop: any = (props: BackTopProps) => {
    const {
        children,
        element
    } = props;

    const trigger = useScrollTrigger({
        target: element,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = document.getElementById(
            'back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    };

    return (
        <Zoom in={trigger}>
            <Root onClick={handleClick} role="presentation">
                {children}
            </Root>
        </Zoom>
    );
}

const Root = styled('div')(
    ({theme}) => ({
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    }),
);

export default BackTop;