import React, { useEffect, useState } from 'react';
import {
    Slide,
    Dialog,
    DialogTitle,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    DialogContent,
    DialogContentText,
    Grid,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
    colors,
    styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
    Close as CloseIcon
} from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface AccountDeleteDialogProps {
    readonly isOpen: boolean;
    readonly deleteLoading: boolean;
    onCloseHandle: () => void;
    deleteAccount: () => void;
}

const AccountDeleteDialog: any = (props: AccountDeleteDialogProps) => {
    const {
        isOpen,
        onCloseHandle,
        deleteAccount,
        deleteLoading
    } = props;

    const [validWordInput, setValidWordInput] = useState(''),
        [validWordEmpty, setValidWordEmpty] = useState(false);

    useEffect(() => {
        setValidWordEmpty(validWordInput ? validWordInput.trim() !== 'Je confirme vouloir supprimer mon compte' : true);
    }, [validWordInput]);

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseHandle}
            fullScreen={isMobile}
            TransitionComponent={isMobile ? Transition : undefined}
            aria-labelledby="form-dialog-account-delete-title">
            {!isMobile && <DialogTitleCustom
                id="form-dialog-account-delete-title">
                Confirmation suppression compte
            </DialogTitleCustom>}
            {isMobile && <AppBarCustom>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onCloseHandle}
                        aria-label="Close"
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Title variant="h6">
                        Confirmation suppression compte
                    </Title>
                </Toolbar>
            </AppBarCustom>}
            <DeleteArea>
                <DialogContent>
                    <DialogContentText>
                        Veuillez saisir le texte suivant : <NoCopy>Je confirme vouloir supprimer mon compte</NoCopy>
                    </DialogContentText>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <TextFieldCustom
                                label="Confirmation"
                                autoFocus={true}
                                value={validWordInput}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setValidWordInput(e.target.value)}
                                helperText={validWordEmpty && 'La phrase de validation ne correspond pas'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                                error={validWordEmpty}
                                fullWidth
                                margin="normal"
                                variant="outlined"/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActionsCustom>
                    <ButtonCustom
                        variant="outlined"
                        onClick={onCloseHandle}>
                        Annuler
                    </ButtonCustom>
                    <Wrapper>
                        <ButtonCustom
                            variant="outlined"
                            color="primary"
                            disabled={!(!validWordEmpty) || deleteLoading}
                            onClick={() => {
                                deleteAccount();
                                setValidWordInput('');
                            }}>
                            Supprimer
                        </ButtonCustom>
                        {deleteLoading && <ButtonProgress size={24}/>}
                    </Wrapper>
                </DialogActionsCustom>
            </DeleteArea>
        </Dialog>
    );
}

const DialogTitleCustom = styled(DialogTitle)(
    () => ({
        backgroundColor: colors.grey[50],
    }),
);

const AppBarCustom = styled(AppBar)(
    () => ({
        position: 'relative',
    }),
);

const Title = styled(Typography)(
    ({theme}) => ({
        marginLeft: theme.spacing(2),
        flex: 1,
    }),
);

const DeleteArea = styled('div')(
    () => ({
        backgroundColor: colors.grey[50],
        height: '100%',
    }),
);

const NoCopy = styled('b')(
    () => ({
        userSelect: 'none',
    }),
);

const TextFieldCustom = styled(TextField)(
    () => ({

    }),
);

const DialogActionsCustom = styled(DialogActions)(
    ({theme}) => ({
        width: '100%',
        margin: 0,
        padding: theme.spacing(2),
    }),
);

const ButtonCustom = styled(Button)(
    ({theme}) => ({
        marginRight: theme.spacing(1),
    }),
);

const Wrapper = styled('div')(
    () => ({
        position: 'relative',
    }),
);

const ButtonProgress = styled(CircularProgress)(
    () => ({
        color: colors.green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }),
);

export default AccountDeleteDialog;