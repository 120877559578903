import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery, useMutation, useApolloClient } from '@apollo/client';
import {
    Paper,
    Grid,
    styled
} from '@mui/material';
import {
    Home as HomeIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';

import ItemUpsert from '../../components/Forms/ItemUpsert';
import AddField from '../../components/Collection/AddField';
import ItemNameUpsertDialog from '../../components/Dialogs/ItemNameUpsertDialog';
import Aux from '../../hoc/Auxi';
import SaveTemplate from '../../components/Collection/SaveTemplate';
import BreadcrumbsApp from '../../components/Breadcrumbs/BreadcrumbsApp';
import { useGlobalDispatch, useGlobalState } from '../../shared/global-context';
import SkeletonAddField from '../../components/Collection/SkeletonAddField';
import SkeletonSaveTemplate from '../../components/Collection/SkeletonSaveTemplate';
import SkeletonItemUpsert from '../../components/Forms/SkeletonItemUpsert';
import StatusSelector from '../../components/Collection/StatusSelector';
import {
    GET_COLLECTION_QUERY, GetCollectionData, GetCollectionVars,
    GET_COLLECTIONS_QUERY, GetCollectionsData, GetCollectionsVars,
    CREATE_ITEM_MUTATE, CreateItemData, CreateItemVars,
    UPSERT_TEMPLATE_COLLECTION_MUTATE, UpsertTemplateCollectionData, UpsertTemplateCollectionVars,
    ADD_IMAGE_ITEM_MUTATE, AddImageItemData, AddImageItemVars,
    NAME_EXIST_IN_COLLECTION_QUERY, NameExistInCollectionVars, NameExistInCollectionData
} from './requests/Add';
import { Status } from './requests/Items';

interface AddProps {

}

const Add: any = (props: AddProps) => {
    const navigate = useNavigate();
    const params = useParams();
    const globalDispatch = useGlobalDispatch();
    const globalState = useGlobalState();
    const apolloClient = useApolloClient();
    const snackbar = useSnackbar();

    const [file, setFile] = useState<File | null>(null);

    const [item, setItem] = useState({
        name: '',
        status: Status.Possessed,
        description: '',
        tags: [] as string[],
        addInput: [] as any[]
    });

    const [addMore, setAddMore] = useState(false);

    const [typeSelected, setTypeSelected] = useState(undefined),
        [fieldSelected, setFieldSelected] = useState(undefined),
        [valueSelected, setValueSelected] = useState(''),
        [dialogOpen, setDialogOpen] = useState({
            create: false,
            update: false
        });

    const [collectionName, setCollectionName] = useState('');

    const links = [
        {
            label: <HomeIconCustom/>,
            onClick: () => navigate('/')
        },
        {
            label: 'Collections',
            onClick: () => navigate('/collections')
        },
        {
            label: collectionName,
            onClick: () => navigate(`/collections/${params.id as string}`)
        }
    ];

    /*eslint-disable */
    useEffect(() => {
        if (typeof typeSelected !== "undefined") {
            setDialogOpen({
                ...dialogOpen,
                create: true
            });
        }
    }, [typeSelected]);

    useEffect(() => {
        if (typeof fieldSelected !== "undefined") {
            setDialogOpen({
                ...dialogOpen,
                update: true
            });
            setValueSelected(item.addInput[fieldSelected as any].label);
        }
    }, [fieldSelected]);
    /*eslint-enable */

    const addFieldInItems: any = (name: string) => {
        if (!globalState.subscribeState.isActive) {
            globalDispatch({
                type: "updateModalSubscribe",
                isOpen: true
            });
        } else {
            setDialogOpen({
                ...dialogOpen,
                create: false
            });
            setItem({
                ...item,
                addInput: item.addInput.concat({
                    label: name,
                    type: typeSelected,
                    value: ''
                })
            });
        }
        setTypeSelected(undefined);
        setTimeout(() => {
            const element = document.getElementById('addArea');
            if (element) {
                element.scrollTop = element.scrollHeight - element.clientHeight;
            }
        }, 500);
    };

    const updateFieldInItems: any = (name: string) => {
        setDialogOpen({
            ...dialogOpen,
            update: false
        });
        const addInputResult: any[] = item.addInput;
        addInputResult[fieldSelected as any].label = name;
        setItem({
            ...item,
            addInput: addInputResult
        });
        setValueSelected('');
        setFieldSelected(undefined);
    };

    const deleteFieldInItems: any = (index: number) => {
        setItem({
            ...item,
            addInput: item.addInput.filter((val: string, i: number) => {
                return index !== i;
            })
        });
    };

    const [
        upsertTemplateCollection,
        {loading: mutationUpsertTemplateCollectionLoading}
    ] = useMutation<UpsertTemplateCollectionData, UpsertTemplateCollectionVars>(
        UPSERT_TEMPLATE_COLLECTION_MUTATE,
        {
            update(cache, { data }) {
                const cacheCollection = cache.readQuery<GetCollectionData, GetCollectionVars>({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: params.id as string
                    }
                });
                if (data?.upsertTemplateCollection && cacheCollection?.getCollection) {
                    cache.writeQuery<GetCollectionData, GetCollectionVars>({
                        query: GET_COLLECTION_QUERY,
                        variables: {
                            id: params.id as string
                        },
                        data: {
                            getCollection: {
                                ...cacheCollection.getCollection,
                                template: data.upsertTemplateCollection.template
                            }
                        }
                    });
                }
            },
            onCompleted(data) {
                snackbar.enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    const [
        addImageItem
    ] = useMutation<AddImageItemData, AddImageItemVars>(
        ADD_IMAGE_ITEM_MUTATE,
        {
            update(cache, { data }) {
                const cacheCollection = cache.readQuery<GetCollectionData, GetCollectionVars>({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: params.id as string
                    }
                });
                const cacheCollections = cache.readQuery<GetCollectionsData, GetCollectionsVars>({
                    query: GET_COLLECTIONS_QUERY
                });
                if (data?.addImageItem) {
                    if (cacheCollection?.getCollection && cacheCollection.getCollection.itemsBySearch) {
                        const itemsBySearchResult = cacheCollection.getCollection.itemsBySearch.map((item, index) => {
                            if (index === 0) {
                                return {
                                    ...item,
                                    thumbnailUri: data.addImageItem.get
                                };
                            }
                            return item;
                        });
                        cache.writeQuery<GetCollectionData, GetCollectionVars>({
                            query: GET_COLLECTION_QUERY,
                            variables: {
                                id: params.id as string
                            },
                            data: {
                                getCollection: {
                                    ...cacheCollection.getCollection,
                                    itemsBySearch: itemsBySearchResult
                                }
                            }
                        });
                    }
                    if (cacheCollections?.getCollections) {
                        const getCollectionsResult = cacheCollections.getCollections.map((collection) => {
                            if (collection.id === params.id as string) {
                                return {
                                    ...collection,
                                    lastThumbnailUri: data.addImageItem.get
                                }
                            }
                            return collection;
                        });
                        cache.writeQuery<GetCollectionsData, GetCollectionsVars>({
                            query: GET_COLLECTIONS_QUERY,
                            data: {
                                getCollections: getCollectionsResult
                            }
                        });
                    }
                }
            },
            async onCompleted(data) {
                if (file && data.addImageItem.put) {
                    const fileCompressed = await imageCompression(file, {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 1024
                    });
                    fetch(data.addImageItem.put, {
                        method: 'PUT',
                        headers: {
                            "Content-Type": file.type
                        },
                        body: fileCompressed
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    )

    const [
        createItem,
        {loading: mutationLoading}
    ] = useMutation<CreateItemData, CreateItemVars>(
        CREATE_ITEM_MUTATE,
        {
            update(cache, { data }) {
                const cacheCollection = cache.readQuery<GetCollectionData, GetCollectionVars>({
                    query: GET_COLLECTION_QUERY,
                    variables: {
                        id: params.id as string
                    }
                });
                const cacheCollections = apolloClient.readQuery<GetCollectionsData, GetCollectionsVars>({
                    query: GET_COLLECTIONS_QUERY
                });
                if (data?.createItem) {
                    if (cacheCollection?.getCollection && cacheCollection.getCollection.itemsBySearch) {
                        cache.writeQuery<GetCollectionData, GetCollectionVars>({
                            query: GET_COLLECTION_QUERY,
                            variables: {
                                id: params.id as string
                            },
                            data: { getCollection: {
                                    ...cacheCollection.getCollection,
                                    itemsBySearch: [...([data.createItem]), ...cacheCollection.getCollection.itemsBySearch],
                            }}
                        });
                    }
                    if (cacheCollections?.getCollections) {
                        apolloClient.writeQuery<GetCollectionsData, GetCollectionsVars>({
                            query: GET_COLLECTIONS_QUERY,
                            data: { getCollections:
                                cacheCollections.getCollections.map((elem) => {
                                    if (elem.id === params.id as string) {
                                        return {
                                            ...elem,
                                            totalItems: (elem.totalItems || 0) + 1
                                        }
                                    }
                                    return elem;
                                })
                            }
                        });
                    }
                }
            },
            async onCompleted(data) {
                snackbar.enqueueSnackbar('Sauvegarde réussie', {
                    variant: 'success'
                });
                if (file) {
                    await addImageItem({
                        variables: {
                            collectionId: params.id as string,
                            id: data.createItem.id
                        }
                    });
                }
                if (!addMore) {
                    navigate(`/collections/${params.id as string}`);
                } else {
                    const itemTemplate: any[] = item.addInput.map((elem: any) => {
                        return {
                            ...elem,
                            value: ''
                        }
                    });
                    setItem({
                        name: '',
                        status: Status.Possessed,
                        description: '',
                        tags: [] as string[],
                        addInput: itemTemplate
                    });
                }
            },
            onError(error) {
                snackbar.enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    const [nameExist, setNameExist] = useState(false);

    const [
        nameExistInCollection
    ] = useLazyQuery<NameExistInCollectionData, NameExistInCollectionVars>(
        NAME_EXIST_IN_COLLECTION_QUERY,
        {
            async onCompleted(data) {
                setNameExist(data.nameExistInCollection);
            }
        }
    );

    const {loading: queryLoading, error: queryError, data: queryData}: any = useQuery<GetCollectionData, GetCollectionVars>(
        GET_COLLECTION_QUERY,
        {
            variables: {
                id: params.id as string
            }
        });

    const [queryMessage, setQueryMessage] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (queryLoading) {
            setQueryMessage(undefined);
        } else if (queryError) {
            console.log(`Error!: ${queryError}`);
            setQueryMessage(undefined);
        } else if (!queryData.getCollection) {
            setQueryMessage('La collection n\'a pas pu être récupéré')
        } else {
            setCollectionName(queryData.getCollection.name);
            setQueryMessage(undefined);
        }
    }, [queryData, queryLoading, queryError]);

    /*eslint-disable */
    useEffect(() => {
        setNameExist(false);
        const timeout = setTimeout(() => {
            nameExistInCollection({
                variables: {
                    id: params.id as string,
                    name: item.name
                }
            });
        }, 1000);
        return () => clearTimeout(timeout);
    }, [item.name]);
    /*eslint-enable */

    return (
        <Aux>
            <BreadcrumbsApp
                links={links}
                lastElem='Nouveau'/>
            {queryMessage && !queryLoading && (<Grid container spacing={2}>
                <Grid item xs={12}>
                    {queryMessage}
                </Grid>
            </Grid>)}
            <GridCustom id="addArea" container spacing={2}>
                <Grid item xs={12} md={4} lg={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {!queryMessage && !queryLoading && <AddField setTypeSelected={setTypeSelected}/>}
                            {queryLoading && <SkeletonAddField/>}
                        </Grid>
                        <Grid item xs={12}>
                            {!queryMessage && !queryLoading && <SaveTemplate
                                upsertTemplateCollection={() => {
                                    if (!globalState.subscribeState.isActive) {
                                        globalDispatch({
                                            type: "updateModalSubscribe",
                                            isOpen: true
                                        });
                                    } else {
                                        upsertTemplateCollection({
                                            variables: {
                                                id: params.id as string,
                                                data: {
                                                    template: item.addInput.map((data: any) => {
                                                        return {
                                                            label: data.label,
                                                            type: data.type
                                                        }
                                                    })
                                                }
                                            }
                                        });
                                    }
                                }}
                                upsertLoading={mutationUpsertTemplateCollectionLoading}/>}
                            {queryLoading && <SkeletonSaveTemplate/>}
                        </Grid>
                        <Grid item xs={12}>
                            {!queryMessage && !queryLoading && <StatusSelector
                                status={item.status}
                                updateStatus={(value: Status) => {
                                    setItem({
                                        ...item,
                                        status: value
                                    })
                                }}/>}
                            {queryLoading && <SkeletonAddField/>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8} lg={10}>
                    <PaperRoot elevation={1}>
                        {!queryMessage && !queryLoading && <ItemUpsert
                            item={item}
                            nameExist={nameExist}
                            collectionId={params.id as string}
                            setItem={setItem}
                            deleteFieldInItems={deleteFieldInItems}
                            setFieldSelected={setFieldSelected}
                            upsertItem={createItem}
                            upsertLoading={mutationLoading}
                            getCollection={queryData.getCollection}
                            setAddMore={setAddMore}
                            cancelUpsert={() => navigate(`/collections/${params.id as string}`)}
                            setFile={setFile}/>}
                        {queryLoading && <SkeletonItemUpsert />}
                    </PaperRoot>
                    <ItemNameUpsertDialog
                        isOpen={dialogOpen.create}
                        onCloseHandle={() => {
                            setDialogOpen({
                                ...dialogOpen,
                                create: false
                            });
                            setTypeSelected(undefined);
                        }}
                        upsertItemName={addFieldInItems}/>
                    <ItemNameUpsertDialog
                        isOpen={dialogOpen.update}
                        onCloseHandle={() => {
                            setDialogOpen({
                                ...dialogOpen,
                                update: false
                            });
                            setValueSelected('');
                            setFieldSelected(undefined);
                        }}
                        name={valueSelected}
                        upsertItemName={updateFieldInItems}/>
                </Grid>
            </GridCustom>
        </Aux>
    );
};

const HomeIconCustom = styled(HomeIcon)(
    ({theme}) => ({
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }),
);

const GridCustom = styled(Grid)(
    () => ({
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
    }),
);

const PaperRoot = styled(Paper)(
    ({theme}) => ({
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.custom.cardItem.backgroundColor,
    }),
);

export default Add;